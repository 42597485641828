@import "../../scss/index.scss";

.navLogo {
  position: relative;
  z-index: 2000;

  @media screen and (max-width: $small) {
    display: none;
  }

  .collapse {
    .collapseIn {
      position: absolute;
      @include center-vertically;
      height: 5rem;
      right: 0;
      cursor: pointer;
    }
    .collapseOut {
      position: absolute;
      @include center-vertically;
      height: 5rem;
      right: -1.5rem;
      cursor: pointer;
    }

    @media screen and (max-width: $large) {
      display: none;
    }
  }

  .logoContainer {
    z-index: 20;
  }

  .logo {
    grid-row: 1/2;
    grid-column: 1/2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $purple-dark-1;
    background-color: $purple-dark-2;
    color: $purple-light-3;
    font-size: 2.2rem;
    font-weight: 500;

    svg {
      height: 100%;
      width: 100%;
      padding: 0 22%;
      fill: $purple-medium-1;
    }
  }
}
