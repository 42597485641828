@import "../../../scss/index.scss";

.segment {
  height: 4rem;
  border: 1px solid #7158ad;
  --background: transparent;

  ion-segment-button {
    --color: #7158ad;
    --color-checked: #7158ad;
    font-size: 1.5rem;
    --indicator-color: #e9e3f2;
    --indicator-box-shadow: none;
    --border-radius: 0;
    --border-width: 1px;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    &:nth-child(-n + 2) {
      border-right: 1px solid #7158ad;
    }
  }
}
