@import "../../../../../scss/app.scss";

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: white;
  height: 60%;
  width: 100%;
  z-index: 500;
  border-top-left-radius: $radius-lg;
  border-top-right-radius: $radius-lg;
  border: 1px solid $grey-light-1;
  box-shadow: 5px 0px 8px 8px rgba($color: #000000, $alpha: 0.1);

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $grey-light-2;
    transition: all 0.3s linear;

    .handleContainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 1rem 0;
      .handle {
        height: 4px;
        width: 6rem;
        background-color: $grey-light-2;
        border-radius: $radius-lg;
        cursor: grab;
      }
    }

    .tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      padding: 0 1.5rem;
      transition: all 0.1s linear;
      .active {
        background-color: $green-light;
        color: $green-dark;
        border-radius: $radius-sm;
        padding: 0.25rem 0.5rem;
        margin-right: 1rem;
      }

      .inactive {
        background-color: $orange-light;
        color: $orange-dark;
        border-radius: $radius-sm;
        padding: 0.25rem 0.5rem;
        margin-right: 1rem;
      }
      .date {
        color: $grey-dark-1;
        text-align: center;
      }
      .onMarket {
        color: $grey-dark-1;
        text-align: center;

        @media screen and (max-width: 350px) {
          display: none;
        }

        // &::before {
        //   content: "|";
        //   padding: 0.5rem;
        // }
      }
    }
    .price {
      display: flex;
      flex-direction: column;

      .current {
        padding: 0 1.5rem;
        font-size: 3.6rem;
        color: $grey-dark-2;
        font-weight: 600;
      }

      .diff {
        padding: 0 1.5rem;
        ion-icon {
          font-size: 1.2rem;
        }
        div {
          display: flex;
          align-items: center;
          .asking,
          .increased,
          .decreased {
            display: flex;
            align-items: center;
          }

          .increased {
            color: $green-dark;
          }

          .decreased {
            color: $orange-dark;
          }

          .listPrice {
            font-size: 1.2rem;
            color: $grey-dark-1;
            &::before {
              font-size: 1.2rem;
              color: $grey-light-2;
              content: "|";
              padding: 0 0.5rem;
            }
          }

          .value {
            font-size: 1.2rem;
            margin-left: 0.2rem;
          }

          .text {
            font-size: 1.2rem;
            color: $grey-dark-1;
            margin-left: 0.3rem;
          }
        }
      }
    }
    .priceExpanded {
      flex-direction: column;
      align-items: flex-start;

      .diff {
        padding: 0 1.5rem;
      }
    }
    .icons {
      position: absolute;
      top: 0;
      right: 2rem;
      @include center-vertically;
      display: flex;
      align-items: center;
    }
  }

  .content {
    height: 100%;
    overflow-y: scroll;
    background-color: white;
    padding: 1rem 1rem 0;
  }
}
