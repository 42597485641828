@import "../../../scss/index.scss";
@import "../../../scss/variables.scss";

.setting {
  display: grid;
  grid-template-rows: repeat(4, 11.2rem);
  // width: 52rem;
  background-color: white;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 4rem;
  box-shadow: 1px 1px 5px $purple-light-2;
  border: 1px solid $purple-light-2;
  border-radius: 25px;

  @media only screen and (max-width: $x-medium) {
    // width: 42rem;
    margin: 0 auto;
    margin-top: 4rem;
  }

  @media screen and (max-width: $large) {
    // width: 82rem;
  }

  .border {
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $grey-light-2;
    margin-bottom: 2rem;
  }

  .firstColumTitle {
    grid-template-rows: 4rem 4rem !important;

    .header {
      font-size: 2.6rem;
      font-weight: 600;
      color: $grey-dark-2;
    }
    .subheader {
      font-size: 1.5rem;
      font-weight: normal;
      color: $grey-dark-2;
    }
  }

  .btns {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 2rem;
    align-items: end;

    .logout {
      justify-self: end;

      ion-icon {
        display: grid;
        align-self: center;
        margin-top: 0;
      }
    }
  }
}

.popover {
  font-size: 1.2rem;
  --box-shadow: none;
  --backdrop-opacity: 0.1;
  p {
    padding: 1rem;
  }
}
