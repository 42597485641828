@import "../../scss/index.scss";

.header {
  display: grid;
  grid-template-columns: 70% 20% 10%;
  margin: 2rem;
  position: relative;
  cursor: pointer;

  .title {
    display: flex;
    font-size: 2.6rem;
    font-weight: 600;

    span {
      display: flex;
      align-self: center;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-self: center;
      width: 3.5rem;
      height: 3.5rem;
      margin-right: 1.5rem;
      color: $grey-dark-2 !important;
    }

    .customIcon {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-right: 1.5rem;
      width: 3rem;
      height: 3rem;
      color: $grey-dark-2 !important;
    }

    .tourSchedule {
      margin-left: 1rem;
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;
    }

    .tourStatus {
      position: absolute;
      top: 50%;
      right: 8rem;
      transform: translateY(-50%);
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;
      padding: 0.25rem 1rem;
      border-radius: $radius-sm;
      width: 10rem;
      display: flex;
      justify-content: center;
      text-transform: capitalize;
    }
    .ready {
      background-color: $green-light;
      color: $green-dark;
    }

    .draft {
      background-color: $yellow-medium;
      color: $yellow-dark;
    }

    .pending {
      color: $yellow-dark;
      background-color: $yellow-light;
    }

    .cancelled {
      background-color: $orange-light;
      color: $orange-dark;
    }

    .completed {
      background-color: $purple-light-2;
      color: $purple-medium-2;
    }

    .archived {
      background-color: $grey-medium-1;
      color: $grey-light-1;
    }
  }

  .tag {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 0 1rem;
    height: 3rem;
    align-self: center;
    justify-self: end;
    width: max-content;
    margin-right: 1rem;

    .icon {
      margin-right: 1rem;
      display: flex;
      align-self: center;
    }
    span {
      display: flex;
      align-self: center;
      font-size: 1.2rem;
    }
  }

  .visible {
    background-color: $yellow-light;
    color: $yellow-dark;
  }
  .invisible {
    background-color: $orange-light;
    color: $orange-dark;
  }

  .chevronicon {
    @include center-vertically;
    right: 2rem;
    cursor: pointer;
    transition: all 0.2s;
    height: 3rem;
    width: 3rem;
  }
  .invertedIcon {
    color: white;
  }

  .iconReversed {
    transform: rotate(180deg) translateY(50%);
  }

  .inverted {
    background-color: transparent;
    color: white;
    border-color: $purple-medium-1;
    font-weight: 400;
    font-size: 1.4rem;
  }
}
