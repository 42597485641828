@import "../../../scss/app.scss";

.headerContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $primary-bg-color;
}

.ioncontent {
  --background: none;
  background-color: $primary-bg-color;
}

.purpleBackground {
  background-color: $primary-bg-color;
}
