@import "../../scss/index.scss";

.config {
  z-index: 5;
  background-color: $purple-light-1;
  grid-row: 1/2;
  grid-column: 1/-1;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;

  .listViewToggle {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  @media screen and (max-width: $small) {
    display: none;
  }

  .sort {
    display: flex;
    justify-content: space-between;
  }
  .filters {
    margin: 4rem 0 2rem 0;
  }
}

.hide {
  display: none;
}

// .listView {
//   box-shadow: 0 10px 15px -15px $grey-dark-1;
// }
