@import "../../../scss/index.scss";

.contact {
  margin: 2rem 1rem 0;

  .title {
    font-size: 2.2rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .subtitle {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    color: $grey-dark-2;
    border-bottom: 0.1rem solid $grey-light-2;
  }

  .socialmedia {
    display: grid;
    grid-template-columns: 40% 60%;
    border-bottom: 0.1rem solid $grey-light-2;
    padding-bottom: 2rem;
    margin-bottom: 1rem;

    .label {
      font-size: 1.5rem;
      color: $grey-dark-2;
    }

    .icons {
      display: grid;
      grid-template-columns: repeat(4, auto);
      justify-items: end;

      ion-icon {
        width: 2.2rem;
        height: 2.2rem;
        color: $grey-dark-2;
      }
    }
  }

  .email {
    color: $grey-dark-2;
    font-size: 1.5rem;
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 1rem;
    padding-bottom: 1.5rem;

    .emailAddress {
      display: flex;
      justify-content: flex-end;
      text-decoration: underline !important;
    }
  }

  .owned {
    font-size: 1.5rem;
    color: $grey-dark-1;
    border-bottom: 0.1rem solid $grey-light-2;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;

    .bold {
      color: $grey-dark-2;
      font-weight: 600;
    }
  }

  .form {
    .formlabel {
      color: $grey-dark-2;
      font-size: 2.2rem;
      font-weight: 600;
      margin-top: 2rem;
    }

    .formsubtitle {
      color: $grey-dark-1;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    .inputs {
      ion-row {
        margin-bottom: 1rem;
      }

      .textarea {
        width: 100%;
        border: 0.1rem solid $grey-light-2;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
      }
    }
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    height: 5rem;
  }
}
