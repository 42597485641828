@import "../../../scss/index.scss";

.dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: white;
  width: 100%;
  max-height: 35rem;
  overflow-y: scroll;
  @include hide-scrollbar;
  padding: 0.5rem;
  margin-top: 0.7rem;
  box-shadow: $box-shadow;
  font-size: 1.5rem;
  font-weight: 400;
  border-radius: $radius-sm;
  transition: all 0.3s;
  animation: fadeInDown 2s;
  opacity: 0;
  visibility: hidden;
  z-index: 10001;
}
.open {
  opacity: 1;
  visibility: visible;
}
