@import "../../../../scss/index.scss";

.container {
  position: absolute;
  bottom: 2rem;
  left: 0;
  padding: 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 4rem;

  .saveSearch {
    justify-self: flex-end;
    z-index: 500;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;

    z-index: 500;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0.85rem;
      border-radius: $radius-sm;
      cursor: pointer;
      background-color: white;
      margin-right: 1.5rem;
      box-shadow: $box-shadow-map-button;

      .icon {
        stroke: $grey-dark-2;
        height: 2.2rem;
        width: 2.2rem;
      }

      .enabledIcon {
        stroke: white;
        height: 2.2rem;
        width: 2.2rem;
      }

      .disabledIcon {
        stroke: $grey-light-2;
        height: 2.2rem;
        width: 2.2rem;
        cursor: default;
      }

      .label {
        font-size: 1.5rem;
        color: $purple-medium-2;
        margin-left: 0.5rem;

        .clearIcon {
          font-size: 1.2rem;
        }
      }

      // &:not(:last-of-type)::after {
      //   content: "|";
      //   padding: 1rem;
      //   color: $line-color;
      // }
    }
    .enabledButton {
      background-color: $purple-dark-1;
      .icon {
        stroke: white;
      }
    }
  }
}
