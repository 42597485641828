@import "../../scss/index.scss";

.clientTours {
  display: grid;
  grid-column: 2/-1;
  grid-row: 2/-1;
  grid-template-rows: min-content 1fr;
  overflow-y: scroll;
  @include show-scrollbar;
  height: 100%;

  .cardContainer {
    display: flex;
    justify-content: center;
    padding: 3rem;
    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem;
      font-size: 1.5rem;

      svg {
        width: 40rem;
        height: 40rem;
      }

      .title {
        font-size: 4.2rem;
        font-weight: 600;
        color: $grey-dark-2;
        margin-top: -3rem;
        margin-bottom: 1.5rem;
        justify-self: flex-start;
      }

      .text {
        color: $grey-dark-2;
        width: 70%;
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }

  .note {
    margin: 1rem 0 2rem 4rem;
    color: $grey-dark-1;
  }
}
