@import "../../../scss/index.scss";

.range {
  position: relative;
  width: 100%;
  .title {
    font-size: 1.5rem;
    font-weight: 500;
    color: $purple-medium-2;
  }

  .options {
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    overflow-y: scroll;
    @include hide-scrollbar;
    border: 1px solid $line-color;
    border-bottom-left-radius: $radius-sm;
    border-bottom-right-radius: $radius-sm;

    .option {
      font-size: 1.5rem;
      color: $grey-dark-2;
      padding: 0.5rem;
      margin: 0 0.5rem;
      border-radius: $radius-sm;

      &:hover {
        background-color: $grey-light-1;
        cursor: pointer;
      }
    }

    .selected {
      background-color: $grey-light-1;
    }
  }
}
