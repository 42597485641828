@import "../../scss/index.scss";

.readOnly {
  transition: all 0.5s;
  ion-icon {
    margin: 0 0.2rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;
  }
  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}

.md {
  display: flex;
  justify-content: center;
  transition: all 0.5s;

  ion-icon {
    margin: 0 0.2rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      color: $grey-dark-2;
    }
  }

  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}

.lg {
  display: flex;
  justify-content: center;
  transition: all 0.5s;

  ion-icon {
    margin: 0 0.4rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 2.2rem;

    &:hover {
      color: $grey-dark-2;
    }
  }

  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}

.xlg {
  display: flex;
  justify-content: center;
  transition: all 0.5s;

  ion-icon {
    margin: 0 1rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 3.2rem;

    &:hover {
      color: $grey-dark-2;
    }
  }

  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}

.mdreadOnly {
  display: flex;
  justify-content: center;
  transition: all 0.5s;

  ion-icon {
    margin: 0 0.2rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;
  }

  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}

.lgreadOnly {
  display: flex;
  justify-content: center;
  transition: all 0.5s;

  ion-icon {
    margin: 0 0.4rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 2.2rem;
  }

  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}

.xlgreadOnly {
  display: flex;
  justify-content: center;
  transition: all 0.5s;

  ion-icon {
    margin: 0 1rem;
    color: $grey-medium-1;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 3.2rem;
  }

  .filledStar {
    color: $grey-dark-2 !important;
    transition: all 0.5s;
  }
}
