@import "../../../../../scss/index.scss";

.profileRow {
  display: grid;
  grid-template-columns: 80% 20%;
}

.firstColumTitle {
  grid-template-rows: 4rem 4rem !important;

  .header {
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subheader {
    font-size: 1.5rem;
    font-weight: normal;
    color: $grey-dark-2;
  }
}

.imageContainer {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: $purple-light-2;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: end;
  cursor: default;

  .image {
    width: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
  }

  .initials {
    color: $purple-medium-2;
    font-size: 2.8rem;
    font-weight: 600;
  }
}
