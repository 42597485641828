@import "../../scss/index.scss";

.mobileView {
  grid-row: 1/-1;
  grid-column: 1/-1;
  height: 100%;
  width: 100%;
  @media screen and (min-width: $small) {
    display: none;
  }
}

.search {
  position: relative;
  grid-row: 2/-1;
  grid-column: 2/-1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 3rem 5rem 0;

  @media screen and (max-width: $small) {
    display: none;
  }

  .buttons {
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;

    .group {
      display: flex;
    }
  }

  .comingSoon {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .animation {
      width: 35rem;
      margin-bottom: 2rem;

      @media screen and (max-height: 840px) {
        display: none;
      }
    }

    .header {
      color: $grey-dark-2;
      margin-bottom: 1rem;
    }

    .content {
      font-size: 1.5rem;
      width: 100%;
      text-align: center;
      color: $grey-dark-1;
    }
  }
}

.listView,
.hide {
  display: none;
}

@media screen and (max-width: $large) {
  .search {
    grid-column: 1/-1;
    padding: 0rem 3rem 7rem 3rem;
  }
}
