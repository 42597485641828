@import "../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 62.5rem;

  .error {
    padding-top: 1.5rem;
    font-size: 1.3rem;
    color: $orange-medium;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subtitle {
      font-size: 2.6rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    .note {
      padding: 0 3rem;
      font-size: 1.5rem;
      color: $grey-dark-1;
      text-align: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .terms {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .label {
        font-size: 1.8rem;
        font-weight: 600;
        color: $grey-dark-2;
        margin-bottom: 1rem;
      }

      .textarea {
        resize: none;
        border: 1px solid $grey-dark-1;
        padding: 1rem;
        height: 13rem;
        overflow-y: scroll;
        @include show-scrollbar;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      .option {
        margin-top: 1.5rem;

        .label {
          font-size: 1.8rem;
          font-weight: 600;
          color: $grey-dark-2;
          margin-bottom: 0.5rem;
        }

        .checkbox {
          display: flex;
          justify-content: flex-start;
          & > * {
            width: 33%;
          }
        }
      }
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
}
