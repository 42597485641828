@import "../../../../scss/index.scss";

.badge {
  position: absolute;
  @include center-vertically;
  left: 100%;
  margin-left: 1rem;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-radius: $radius-sm;
  color: white;
  white-space: nowrap;
  background-color: $orange-medium;
}
