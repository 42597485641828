@import "../../../scss/index.scss";

.header {
  background-color: #f4f4f7;
  color: $grey-dark-2;
  display: flex;
  flex-direction: row;
  height: 5rem;

  ion-icon {
    display: flex;
    justify-self: center;
    align-self: center;
    height: 2.5rem;
    width: 3rem;
  }
}
.content {
  font-size: 1.5rem;
  //   --padding-start: 1rem;
  //   --padding-end: 1rem;
  //   --padding-top: 1rem;

  .search {
    font-size: 1.5rem;
    background-color: white;
    border-bottom: 1px solid $border-color;
    padding: 2rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .footer {
    background-color: #f4f4f7;
    position: sticky;
    bottom: 0;
    height: 8rem;
    width: 100%;
    display: flex;
    align-items: center;

    .btn {
      width: 100%;

      margin: auto 1.5rem;
      height: 5rem;
      text-transform: capitalize;
      --background: #e9e3f2;
      --background-activated: #7158ad;
      --color: #7158ad;
      --color-activated: #e9e3f2;
      --border-style: solid;
      --border-width: 0.1rem;
      --border-color: #7158ad;
      --box-shadow: none;
    }
  }
}

.item {
  --border-style: solid !important;
  --border-width: 0.1rem !important;
  --border-radius: 5px !important;
  margin-bottom: 2rem !important;
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
  }

  ion-label {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    color: $grey-dark-1 !important;

    ion-icon {
      display: flex;
      align-self: center;
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  }

  ion-select {
    color: $grey-dark-2 !important;
  }
}

// .header {
//   font-size: 1.5rem;
//   color: $grey-dark-2;
//   margin-bottom: 1rem;
// }

.listItem {
  font-size: 1.5rem;
  color: $grey-dark-2;

  .labels {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;

    .main {
      font-weight: 500;
    }

    .secondary {
      font-size: 1.2rem;
      color: $grey-medium-1;
    }
  }
}

.radio {
  --color-checked: #7158ad;
}

.addItem {
  --background: #f9f9f9;
}

.checkbox {
  --background-checked: #7158ad;
  --border-color: #707070;
  --border-color-checked: #7158ad;
  --checkmark-color: white;
}

.closeIcon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  //   margin: 1rem 2rem;
  ion-icon {
    padding: 1rem;
    height: 2rem;
    width: 2rem;
  }
}
