@import "../../../scss/index.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;

  padding: 2rem;

  .filters {
    flex: 1;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(8, 1fr) !important;
    row-gap: 1rem;
    column-gap: 1.5rem;

    .item {
      position: relative;
      .icon {
        @media screen and (max-width: $large) {
          display: none;
        }
      }
    }

    .location {
      grid-row: 1/2;
      grid-column: 1/4;
      @media screen and (max-width: $large) {
        grid-column: 1/3;
      }
    }
    .purpose {
      grid-row: 1/2;
      grid-column: 4/6;
      @media screen and (max-width: $large) {
        grid-column: 3/5;
      }
    }
    .price {
      grid-row: 1/2;
      grid-column: 6/8;
      @media screen and (max-width: $large) {
        grid-column: 5/7;
      }
    }
    .beds {
      grid-row: 1/2;
      grid-column: 8/9;
      @media screen and (max-width: $large) {
        grid-column: 7/8;
      }
    }
    .status {
      grid-row: 2/3;
      grid-column: 1/3;
      @media screen and (max-width: $large) {
        grid-column: 1/4;
      }
    }

    .date {
      grid-row: 2/3;
      grid-column: 3/5;
      @media screen and (max-width: $large) {
        grid-column: 4/6;
      }
    }

    .types {
      grid-row: 2/3;
      grid-column: 5/7;
      @media screen and (max-width: $large) {
        grid-column: 6/8;
      }
    }

    .more {
      grid-row: 2/3;
      grid-column: 7/8;
      @media screen and (max-width: $large) {
        grid-column: 8/9;
        grid-row: 1/2;
      }
    }

    .saveSearch {
      grid-row: 2/3;
      grid-column: 8/-1;
    }
  }
}

// @media screen and (max-width: $large) {
//   .filters {
//     grid-template-rows: 1fr 1fr;
//     grid-template-columns: repeat(8, 1fr) !important;
//     row-gap: 1rem;

//     .location {
//       grid-row: 1/2;
//       grid-column: 1/4;
//     }
//     .purpose {
//       grid-row: 1/2;
//       grid-column: 4/6;
//     }
//     .price {
//       grid-row: 1/2;
//       grid-column: 6/8;
//     }
//     .beds {
//       grid-row: 1/2;
//       grid-column: 8/9;
//     }
//     .status {
//       grid-row: 2/3;
//       grid-column: 1/4;
//     }

//     .types {
//       grid-row: 2/3;
//       grid-column: 4/7;
//     }

//     .more {
//       grid-row: 2/3;
//       grid-column: 7/8;
//     }

//     .saveSearch {
//       grid-row: 2/3;
//       grid-column: 8/9;
//     }
//   }
// }
