@import "../../../../scss/index.scss";

.topbar {
  display: flex;
  flex-direction: row;
  margin: 2rem 4rem;
  justify-content: space-between;
  color: $grey-dark-1;
  flex: 1;
  font-size: 1.5rem;

  .category {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 3rem;
    font-size: 2.2rem;
    border-bottom-color: #e5e5e5;
    border-bottom-style: solid;
    border-bottom-width: 0.2rem;

    div {
      cursor: pointer;
    }
    // div:nth-child(-n + 2) {
    //   padding-right: 4rem;
    // }

    .bold {
      color: $grey-dark-2;
      font-weight: 600;
      padding-bottom: 0.5rem;
      border-bottom-style: solid;
      border-bottom-width: 0.2rem;
      border-bottom-color: $grey-dark-2;
    }
  }

  .clientCategory {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 4rem;
  }

  .filter {
    display: flex;
    flex-direction: row;
    align-self: center;
    font-size: 1.5rem;

    .item {
      font-size: 1.5rem;
      margin: 1.5rem;
      color: $grey-dark-2;
      cursor: pointer;
    }

    .title {
      margin-right: 1rem;
    }

    .value {
      color: $grey-dark-2;
      font-weight: 600;
      cursor: pointer;
      text-transform: capitalize;
    }

    .icon {
      height: 1.5rem;
      width: 1.5rem;
      font-size: 1.5rem;
      padding: 0 1rem;
      color: $grey-dark-2;
      align-self: center;
      border-right-color: $purple-light-3;
      border-right-width: 0.1rem;
      border-right-style: solid;
      cursor: pointer;
    }

    .sorticon {
      height: 1.5rem;
      align-self: center;
      padding: 0 1rem;
      cursor: pointer;
      fill: $grey-dark-1;
      transition: all 0.2s;
    }
    .reversedIcon {
      transform: rotate(180deg);
    }
  }
}
