@import "../../../scss/index.scss";

.result {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: $radius-sm;

  &:hover {
    cursor: pointer;
    background-color: $grey-light-1;
  }

  .iconContainer {
    height: 100%;
    width: 2rem;
    .icon {
      width: 1.1rem;
      height: 1.1rem;
      fill: $grey-dark-2;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;

    .type {
      font-size: 1.2rem;
      font-weight: 400;
      color: $grey-dark-1;
    }

    .purple {
      color: $purple-medium-2;
    }

    .addressContainer {
      flex: 1;
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      flex-wrap: nowrap;
      width: 100%;

      .address {
        display: flex;
        font-size: 1.5rem;
        font-weight: 400;
        color: $grey-dark-2;
        height: 2.1rem;
        width: 100%;
        overflow: hidden;
      }
    }
  }
}
