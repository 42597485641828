@import "./variables.scss";
@import "./utils.scss";

* {
  font-family: $base-font-family !important;
  box-sizing: border-box;
  caret-color: $purple-medium-2;

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  &:focus {
    outline: none;
  }
}

html {
  height: 100%;
  font-size: $base-font-size !important;
  font-family: $base-font-family;
}

body {
  font-size: 1.8rem !important;
  font-weight: 400;
  ::selection {
    background: $purple-light-1;
    color: $purple-medium-2;
  }
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2.6rem;
  font-weight: 500;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

h5 {
  font-size: 1.5rem;
  font-weight: 500;
}

h6 {
  font-size: 1.2rem;
  font-weight: 500;
}
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

ion-item {
  --background-activated: #e9e3f2 !important;
  --background-focused: #e9e3f2 !important;
  --highlight-color-focused: #e9e3f2 !important;
  --highlight-color-valid: #e9e3f2 !important;
  --ripple-color: #e9e3f2 !important;
  --background-hover: #e9e3f2 !important;
}

ion-item::part(detail-icon) {
  font-size: 2rem;
  color: $grey-dark-2;
  opacity: 1;
  z-index: 100;
}

ion-item {
  --color: #dedaec !important;
  --color-focused: #dedaec !important;
  --color-hover: #dedaec !important;
  --highlight-color-focused: #dedaec !important;
  --ripple-color: #927bca !important;
  --border-color: #e5e5e5 !important;
}

ion-picker-column {
  .picker-opts {
    .picker-opt-selected {
      color: #7158ad !important;
    }
  }
}

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

ion-back-button {
  --ripple-color: #e9e3f2 !important;
}
