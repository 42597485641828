@import "../../scss/index.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.5rem;

  .sortContainer {
    display: flex;
    align-items: center;
    width: auto;
    font-size: 1.5rem;
    --background: transparent;

    &::after {
      content: "|";
      padding: 1rem;
      font-weight: 300;
      color: $line-color;
    }

    .title {
      color: $grey-dark-1;
      margin-right: 0.5rem;
      font-weight: 400;
      flex: 1;

      @media screen and (max-width: 390px) {
        display: none;
      }
    }
    .value {
      color: $grey-dark-2;
      font-weight: 600;
    }
    .select {
      opacity: 0;
      width: 0;
    }
    .icon {
      height: 2rem;
      padding: 0;
      cursor: pointer;
      fill: $grey-dark-1;
      transition: all 0.2s;
    }
  }

  .text {
    font-size: 1.5rem;
    font-weight: 400;
    color: $grey-dark-1;
  }

  .button {
    display: flex;
    align-items: center;
    width: 10rem;
    height: 2.5rem;
    border-right: 1px solid $line-color;
  }

  .icon {
    height: 2rem;
    padding: 0 1rem;
    cursor: pointer;
    fill: $grey-dark-1;
    transition: all 0.2s;
  }
  .reversedIcon {
    transform: rotate(180deg);
  }
}

.searchMode {
  @media screen and (max-width: $large) {
    display: none;
  }
}

.toursMode {
  .title {
    color: $grey-dark-1;
    margin-right: 0.5rem;
    font-weight: 400;
    flex: 1;
    @media screen and (max-width: 390px) {
      display: block !important;
    }
  }
}
