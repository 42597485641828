@import "../../../../scss/index.scss";

.appItem {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  // align-items: center;
  font-size: 1.5rem;
  color: $grey-dark-1;
  margin: 1rem 0;

  .order {
    font-size: 1.2rem;
    margin-right: 1.5rem;
    width: 1rem;
    display: flex;
    align-self: center;
  }

  .direction {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 75%;
    padding: 1rem;
    background-color: $blue-light;
    border-radius: $radius-sm;
    margin-right: 1.5rem;

    ion-icon {
      flex: 1;
      font-size: 2rem;
      color: $blue-dark;
    }
  }

  .info {
    flex: 1;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .address {
      color: $grey-dark-2;
      font-weight: 600;
    }

    .container {
      .time {
        font-size: 1.2rem;
      }
      .status {
        font-size: 1.2rem;

        &::after {
          content: "|";
          padding: 0 0.5rem;
        }
      }
    }

    .listing {
      display: flex;
      font-size: 1.2rem;
      font-weight: 600;
      text-decoration: underline;

      ion-icon {
        display: flex;
        align-self: center;
        margin-left: 0.5rem;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

.item {
  flex: 1;
  margin: 2rem 0 0;
  padding: 0 0 2rem;
  display: grid;
  grid-template-columns: 3% 12% 15% 10% 25% 10% 15% 10%;
  font-size: 1.5rem;
  border-bottom-color: #dedaec;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  color: $grey-dark-1;

  ion-icon {
    width: 2rem;
    height: 2rem;
    // display: flex;
    // align-self: center;
  }

  .col {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .settingIcon {
      cursor: pointer;
      background-color: #e9e3f2;
      color: #7158ad;
      padding: 0.75rem;
      border-radius: 5px;
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .order {
    display: flex;
    align-self: center;
  }

  .mls {
    display: flex;
    align-self: center;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
    ion-icon {
      display: flex;
      align-self: center;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }

  .direction {
    display: flex;
    justify-self: center;
    width: max-content;
    padding: 1rem;
    color: #007b94;
    background-color: #e0faff;
    border-radius: 5px;
  }

  .status {
    display: flex;
    justify-self: center;

    span {
      width: max-content;
      padding: 0 0.5rem;
    }

    ion-icon {
      display: flex;
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  .greyBtn {
    --color: #707070;
    --background: white;
    --background-hover: white;
    --background-activated: white;
  }

  .green {
    display: flex;
    justify-content: center;
    background-color: $green-light;
    color: $green-dark;
    border-radius: 5px;
  }

  .yellow {
    display: flex;
    justify-content: center;
    color: $yellow-dark;
    background-color: $yellow-light;
    border-radius: 5px;
  }

  .orange {
    display: flex;
    justify-content: center;
    background-color: $orange-light;
    color: $orange-dark;
    border-radius: 5px;
  }

  .details {
    display: flex;
    justify-self: center;
    width: max-content;
    background-color: #e9e3f2;
    color: #7158ad;
    padding: 1rem;
    border-radius: 5px;
  }
}

.status {
  --padding-end: 1rem;
  --padding-start: 1rem;
  min-width: 13rem;
  font-size: 1.5rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  background-color: transparent;
  text-transform: capitalize;

  ion-label {
    font-weight: 400 !important;
  }
}
.confirmed {
  display: flex;
  justify-content: center;
  background-color: $green-light;
  color: $green-dark;
  border-radius: 5px;
}

.requested {
  display: flex;
  justify-content: center;
  color: $yellow-dark;
  background-color: $yellow-light;
  border-radius: 5px;
}

.cancelled {
  display: flex;
  justify-content: center;
  background-color: $orange-light;
  color: $orange-dark;
  border-radius: 5px;
}

.rejected {
  display: flex;
  justify-content: center;
  background-color: rgb(255, 210, 210);
  color: red;
  border-radius: 5px;
}

.skipped {
  display: flex;
  justify-content: center;
  background-color: $yellow-light;
  color: $yellow-dark;
  border-radius: 5px;
}

.popoverContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.option {
  color: $purple-medium-2 !important;
  &:hover {
    color: $purple-dark-1 !important;
  }
}
.fadedOut {
  opacity: 0.5 !important;
}
