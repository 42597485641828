@import "../../../../../../../../scss/index.scss";

.pricehistory {
  display: grid;
  margin: 0 auto;
  border: $cluster-border;
  border-top-style: none;
  border-radius: 0px 0px 5px 5px;

  @media only screen and (max-width: $large) {
    width: 100%;
  }

  .infos {
    display: grid;
    margin: 2rem 4rem 2rem 2rem;

    .priceHeader {
      display: grid;
      grid-template-columns: 15% 15% 17% 17% 10% 15%;
      column-gap: 2rem;
      height: 5rem;
      border-bottom-style: solid;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;
      margin-bottom: 2rem;
      color: #666666;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;

      @media only screen and (max-width: $medium) {
        display: none;
      }
    }

    .priceBody {
      display: flex;
      flex-direction: column-reverse;
    }

    .details {
      padding-left: 2rem;
      display: grid;
      grid-template-columns: repeat(5, 20%);
      border-bottom-style: solid;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;
      margin-bottom: 2rem;
      color: #666666;
      font-size: 1.8rem;
    }

    .noData {
      display: grid;
      justify-content: center;
      color: #666666;
      font-size: 2rem;
      font-weight: 600;
    }
  }

  .lockHistory {
    display: grid;
    justify-content: center;
    height: 16rem;

    .icon {
      display: grid;
      justify-content: center;
      align-items: end;
      margin-bottom: 2rem;
      ion-icon {
        height: 6rem;
        width: 4rem;
        color: $purple-medium-2;
      }

      @media only screen and (max-width: $medium) {
        margin-bottom: 1rem;
      }
    }
    .text {
      color: $purple-medium-2;
      font-size: 1.8rem;
      font-weight: 600;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
        text-align: center;
      }

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
