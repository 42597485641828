@import "scss/variables.scss";
.app {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.web {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.tab {
  --background: #110d1b;
  //--color-selected: #927bc1;
  --color: #dedaec;
  --padding-bottom: 0.4rem;
  --padding-top: 0.4rem;
  --ripple-color: #dedaec;
  --background-focused-opacity: 0;
  --overflow: visible;
  .icon {
    font-size: 2rem;
  }

  ion-tab-button:not(.tab-selected)::part(native):hover {
    color: #dedaec;
  }

  .label {
    font-weight: 300;
  }

  .badge {
    background-color: #ff5500;
  }

  .iosBadge {
    position: absolute;
    top: 15%;
    right: 30%;
    width: 0.8rem;
    height: 0.8rem;
    background-color: #ff5500;
    border-radius: 50%;
  }
}

ion-tab-button[aria-selected="true"] {
  box-shadow: 0 -2px 0 0 #927bc1 inset;
  --color-selected: #927bc1;
}

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.info {
  --background: #7158ad;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.notification {
  --background: #7158ad;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.connectionContent {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  text-align: center;
  color: #363636;

  .svg {
    width: 30rem;
    height: 30rem;
  }
  .title {
    font-size: 2.2rem;
    color: $grey-dark-2;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
    text-align: center;
    color: $grey-dark-1;
    padding: 0 1rem;
    &:last-of-type {
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000085;
  backdrop-filter: blur(2px);
  z-index: 999999;
}

.bottomCard {
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  background-color: white;
  box-shadow: $box-shadow-grey;
  z-index: 99999999;
  border-top-left-radius: $radius-md;
  border-top-right-radius: $radius-md;
  .header {
    padding: 1rem 0;
    margin: 0 1rem;
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    color: $grey-dark-2;
    border-bottom: 1px solid $grey-light-2;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem 0;
    .item {
      font-size: 1.4rem;
      font-weight: 500;
      color: $grey-dark-2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.5rem;

      .logo {
        display: flex;
        align-items: center;
        .appLogo {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $grey-light-2;
          border-radius: $radius-sm;
          padding: 0.25rem;
          margin-right: 1rem;
          img {
            width: 3rem;
            height: 3rem;
          }
        }
      }

      .logo {
        display: flex;
        align-items: center;
        .browserLogo {
          border: 1px solid $grey-light-2;
          border-radius: $radius-sm;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.25rem;
          margin-right: 1rem;
          .icon {
            width: 3rem;
            height: 3rem;
          }
        }
        .title {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      .label {
        padding: 0.5rem 1rem;
        border-radius: $radius-sm;
        min-width: 14rem;
        text-align: center;
      }

      .appLabel {
        background-color: $purple-medium-2;
        border: 1px solid $purple-medium-2;
        color: white;
      }
      .browserLabel {
        border: 1px solid $grey-light-2;
      }
    }
  }
}
