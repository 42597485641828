@import "../../../../scss/index.scss";

.dimmed {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0 1.8rem;
  transform: translateX(-50%);
}
