@import "../../scss/index.scss";
.slider {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: grab;
}

.active {
  cursor: grabbing;
}

.app {
  @include hide-scrollbar;
}

.scrollbar {
  @include show-scrollbar;
}
