@import "../../../../../../../scss/index.scss";

.comparables {
  margin: 0 -1rem;
  padding-top: 2rem;
  background-color: $purple-light-1;

  .title {
    margin-left: 2rem;
    color: $grey-dark-2;
    font-size: 2.2rem;
    font-weight: 600;
  }

  .single {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    margin: 0 auto;
  }
}
