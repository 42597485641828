@import "../../../scss/index.scss";

.appAgentTour {
  .body {
    height: 100%;
    margin: 2rem;

    .title {
      font-size: 2.2rem;
      font-weight: 600;
      color: $grey-dark-2;
      margin-bottom: 2rem;
    }

    .button {
      height: 4rem;
      text-transform: initial;
      --background: #e9e3f2;
      --background-activated: #7158ad;
      --color: #7158ad;
      --color-activated: #e9e3f2;
      --border-style: solid;
      --border-width: 1px;
      --border-color: #7158ad;
      --box-shadow: none;

      ion-icon {
        margin-right: 0.5rem;
      }
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
      margin: 2rem 0 1rem 0;

      .mapBtn {
        --background: #e0faff;
        height: 3rem;
        width: 13rem;
        --padding-start: 5px;
        --padding-end: 5px;
        .mapIcon {
          font-size: 1.5rem;
          color: #007b94;
          margin-right: 1rem;
        }
        .mapLabel {
          font-size: 1.3rem;
          color: #007b94;
        }
      }
    }

    .tourElement {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
      margin: 2rem -2rem;
      padding: 0 0 2rem 2rem;
      border-bottom-color: $grey-light-2;
      border-bottom-width: 0.1rem;
      border-bottom-style: solid;
    }

    .items {
      border-top: 0.1rem solid $grey-light-2;

      .item {
        --border-width: 0 0 0.1rem 0;
        --padding-start: 0;
        --padding-end: 0;
      }
    }

    .toggle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      padding: 0;
      margin: 0;
    }
  }
}

.options {
  font-size: 1.2rem;
  .option {
    font-size: 1.2rem;
  }
}

.revert {
  display: flex;
  justify-content: center;
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: $grey-light-2;
  .revertLabel {
    --color: #9e9e9e;
    font-size: 1.5rem;
    --padding-start: 0;
  }

  .revertIcon {
    --color: #363636;
    font-size: 2rem;
    --padding-start: 0;
  }
  .revertItem {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --margin: 0;
  }
}
.share {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  height: 7rem;
  background-color: $purple-light-1;
  border-top-style: solid;
  border-top-width: 0.1rem;
  border-top-color: $grey-light-2;

  .shareBtn {
    --background: #dff7f0;
    --background-activated: #1f7a60;
    --color: #1f7a60;
    --color-activated: #dff7f0;
    --border-color: #1f7a60;
    --border-width: 0.1rem;
    --border-style: solid;
    --box-shadow: none;
    width: 100%;
    height: 4rem;
    margin: 0 1.5rem;
    text-transform: initial;

    ion-icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
    }
  }
}

.reorder {
  ion-item::part(native) {
    padding-left: 0 !important;
  }
}

.loader {
  --background: #f4f4f7;
  --spinner-color: $purple-medium-2 !important;
}
