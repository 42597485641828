@import "../../../../../../../scss/index.scss";

.photosSection {
  margin: 2rem 0;

  .currentUser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 0 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;
    .title {
      font-size: 1.8rem;
      font-weight: 600;
      color: $grey-dark-2;
    }
    .deleteMode {
      color: $orange-medium;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .others {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-bottom: 2rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;

    .role {
      color: $grey-dark-1;
      font-size: 1.2rem;
      margin-left: 0.5rem;
      font-weight: 400;
    }
  }

  .images {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 12rem;
    grid-gap: 1rem;
    align-items: stretch;
    padding: 0 1rem;

    span {
      position: relative;

      .img {
        width: 100%;
        height: 100%;
        border: 1px solid $grey-light-2;
        max-width: 100%;
        border-radius: 1rem;
        cursor: pointer;
      }

      .deleteIcon {
        position: absolute;
        font-size: 1.5rem;
        top: 0.8rem;
        right: 0.8rem;
        color: white;
        border: 1px solid $orange-medium;
        background-color: $orange-medium;
        padding: 0.5rem;
        border-radius: 50%;
        box-shadow: $box-shadow-grey;
        cursor: pointer;
      }
    }
  }
}

ion-modal {
  --background: rgba(0, 0, 0, 0.85) !important;
  height: 100%;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .slides {
      height: 100%;

      .slide {
        img {
          height: 80%;
        }
      }
    }

    .closeContainer {
      position: absolute;
      display: flex;
      bottom: 4rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;

      .btn {
        color: $purple-light-2;
        --border-color: #e9e3f2;
        --border-width: 1px;
        --opacity: 0.9;
        font-weight: 300;
        --background-activated: #927bca;
      }
      .landscapeBtn {
        color: $purple-dark-1;
        --border-color: #33244c;
        --border-width: 2px;
        --opacity: 0.9;
        font-weight: 500;
        --background: #e9e3f2a1;
        --background-activated: #e9e3f2;
      }
      .deleteBtn {
        color: $orange-medium;
        --border-color: $orange-medium;
        --border-width: 1px;
        --opacity: 0.9;
        font-weight: 300;
        --background-activated: $orange-medium;
      }
    }
  }
}
