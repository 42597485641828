@import "../../scss/index.scss";

.radioContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: $grey-dark-2;

  .option {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0.5rem 0;

    .radio {
      position: relative;
      border: 1px solid $grey-dark-1;
      border-radius: 50%;
      min-width: 1.6rem;
      min-height: 1.6rem;
      display: flex;
      align-items: center;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .radioControl {
          width: 1.1rem;
          height: 1.1rem;
        }
      }

      .radioControl {
        display: block;
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .label {
      margin-left: 0.8rem;
      text-transform: capitalize;
    }
  }

  .purple {
    input {
      &:checked + .radioControl {
        background-color: $purple-medium-2;
        border-color: $purple-medium-2;
      }
    }
  }

  .green {
    input {
      &:checked + .radioControl {
        background-color: $green-dark;
        border-color: $green-dark;
      }
    }
  }

  .subItems {
    display: flex;
    flex-direction: column;
    border: $input-border;
    border-bottom-left-radius: $radius-sm;
    border-bottom-right-radius: $radius-sm;
    height: 15rem;
    overflow-y: scroll;
    .subItem {
      padding: 0.3rem 1.5rem;
      cursor: pointer;
    }
  }
}
.subItemSelected {
  font-weight: 600;
}

.showScroll {
  position: relative;
  @include show-scrollbar;
  overflow-y: scroll;
  height: 30rem;
}
