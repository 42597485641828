@import "../../scss/index.scss";

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    cursor: pointer;

    &:not(:last-of-type) {
      border-right: 1px solid $line-color;
    }
    .icon {
      height: 1.8rem;
      width: 1.8rem;
      margin-right: 0.5rem;
      stroke: $purple-medium-1;
    }

    .title {
      font-size: 1.5rem;
      margin-left: 0.5rem;
      color: $purple-dark-1;
      font-weight: 500;
    }
  }

  .selected {
    .title {
      color: $grey-dark-2;
      font-weight: 600;
    }
    .icon {
      stroke: $purple-medium-2;
    }
  }
}

.mapView {
  .button {
    @media screen and (min-width: $large) and (max-width: 1350px) {
      padding: 0 1rem;
    }

    .icon {
      @media screen and (min-width: $large) and (max-width: 1350px) {
        margin-right: 0;
      }
    }
  }
  // .title {
  //   @media screen and (min-width: $large) and (max-width: 1350px) {
  //     display: none;
  //   }
  // }
}
