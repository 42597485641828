@import "../../../../../../../scss/index.scss";

.btn {
  flex: 1;
  height: 8rem;
  background-color: $grey-light-1;
  border: 1px solid $grey-light-2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: $grey-light-2;
  }

  // &:first-of-type {
  //   margin-right: 1.5rem;
  // }

  .img {
    display: grid;
    justify-content: end;
    align-self: center;
  }
  .label {
    display: grid;
    justify-content: start;
    align-self: center;
    font-size: 1.8rem;
    color: $grey-dark-2;
  }
}
.disable {
  cursor: not-allowed;
  .label {
    color: $grey-dark-1;
  }
  &:hover {
    background-color: $grey-light-1;
  }
}
.icon {
  width: 3.5rem;
  height: 3.5rem;
}
