@import "../../../scss/index.scss";

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0.5rem 0;

  .radio {
    position: relative;
    border: 1px solid $grey-dark-1;
    border-radius: 50%;
    min-width: 1.6rem;
    min-height: 1.6rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      display: flex;
      align-items: center;

      &:checked + .radioControl {
        background-color: $purple-medium-2;
        border-color: $purple-medium-2;
        width: 1.1rem;
        height: 1.1rem;
      }
    }

    .radioControl {
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .label {
    margin-left: 0.8rem;
    transform: translateY(0.5px);
    text-transform: capitalize;
  }
}
