@import "../../../scss/index.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.prefix {
  position: absolute;
  @include center-vertically;
  left: 1rem;
  font-size: 1.5rem;
  color: $grey-dark-1;
}

.postfix {
  position: absolute;
  @include center-vertically;
  right: 1rem;
  font-size: 1.5rem;
  color: $grey-dark-1;
  background-color: white;
  padding-left: 0.5rem;
}

.chips {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
}

.input {
  border: 1px solid #e5e5e5;
  border-radius: $radius-sm;
  height: 4rem;
  color: $input-text-color;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1.35rem;
  background-color: white;
  caret-color: $purple-medium-2;

  &:required + label::after {
    content: "*";
    font-size: 1.8rem;
  }

  &:focus + .label,
  &:not(:placeholder-shown) + label {
    color: $input-label-color;
    font-size: 1.2rem;
    font-weight: 500;
    transform: translateY(-2.8rem);
    @media only screen and (max-width: $small) {
      transform: translateY(-3.2rem);
    }
    background-color: white;
    padding: 0 3px;
    &::after {
      display: none;
    }
  }

  &:focus + .appLabel,
  &:not(:placeholder-shown) + label {
    transform: translateY(-2.8rem);
    @media only screen and (max-width: $small) {
      transform: translateY(-3rem);
    }
  }

  &:focus + .authLabel,
  &:not(:placeholder-shown) + label {
    transform: translateY(-2.8rem);
    @media only screen and (max-width: $small) {
      transform: translateY(-3rem);
    }
  }

  &::placeholder {
    color: white;
  }
}

.focus {
  & + .label {
    color: $input-label-color;
    font-size: 1.2rem;
    font-weight: 500;
    transform: translateY(-2.8rem);
    @media only screen and (max-width: $small) {
      transform: translateY(-3rem);
    }
    background-color: white;
    padding: 0 3px;

    &::placeholder {
      font-size: 1.5rem;
      color: $grey-dark-1;
      font-weight: 400;
    }
  }
}

.search {
  & + .label {
    color: $input-label-color;
    font-size: 1.2rem;
    font-weight: 500;
    transform: translateY(-2.8rem);
    @media only screen and (max-width: $small) {
      transform: translateY(-3rem);
    }
    background-color: white;
    padding: 0 3px;
    &::after {
      display: none;
    }
  }
}

.showPlaceholder {
  &::placeholder {
    color: $grey-dark-1;
    font-weight: 400;
  }
}

.homePlaceholder {
  &::placeholder {
    color: $purple-medium-2;
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.withPrefix {
  padding-left: 2.5rem;
}

.label {
  @include center-vertically;
  margin-left: 1.35rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: $placeholder-color;
  transition: all ease-in-out 0.3s;
  pointer-events: none;
}

.decreaseTop {
  top: 40%;
}

.icon {
  @include center-vertically;

  right: 1.35rem;
  color: $input-icon-color;
  --ionicon-stroke-width: 4.8rem;
}

.actionIcon {
  cursor: pointer;
}

.loading {
  animation: Border 1.5s ease infinite;
}

.error {
  position: absolute;
  top: 105%;
  left: 1.5rem;
  color: $orange-medium;
  font-size: 1.2rem;
  width: 100%;
  z-index: 10;

  // @media only screen and (max-width: $small) {
  //   width: 100%;
  // }
}

.visiblePlaceholder {
  height: 4.5rem;
  &::placeholder {
    color: $grey-dark-1;
    font-weight: 400;
  }
}

.preserveColor:disabled {
  color: #363636 !important;
}
