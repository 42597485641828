@import "../../../scss/index.scss";

.checkbox {
  display: flex;
  align-items: center;
  position: relative;

  .item {
    display: flex;
    align-items: center;
    color: $grey-dark-2;
    margin-left: 0.8rem;
    font-size: 1.5rem;
  }
  .disabled {
    color: $grey-light-2;
  }

  input {
    display: flex;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
  }
}

.checkbox > span {
  color: $grey-dark-1;
}

.checkbox > input:checked {
  content: "\2713";
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  appearance: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.checkbox > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  color: white;
  position: absolute;
  left: 0.6rem;
  top: 0;
}

.purple > input:checked {
  background-color: $purple-medium-2;
  &:disabled {
    background-color: $grey-light-2;
  }
}

.purple > ion-checkbox {
  --background-checked: #7158ad;
  --border-color-checked: #7158ad;
}

.green > input:checked {
  background-color: $green-dark;
  &:disabled {
    background-color: $grey-light-2;
  }
}

.green > ion-checkbox {
  --background-checked: #1f7a60;
  --border-color-checked: #1f7a60;
}
