@import "../../../../scss/index.scss";

.tag {
  position: absolute;
  top: 2rem;
  left: -1rem;
  height: 3rem;
  background-color: $orange-medium;
  color: white;
  font-size: 1.5rem;
  border-radius: $radius-sm;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;

  .title {
    margin-right: 0.5rem;
  }

  .value {
    font-weight: 600;
  }
}

.appTag {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 3rem;
  background-color: $orange-light;
  color: $orange-dark;
  font-size: 1.5rem;
  border-radius: $radius-sm;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  z-index: 100;

  .title {
    margin-right: 0.5rem;
  }

  .value {
    font-weight: 600;
  }
}
