@import "../../../../scss/index.scss";

.container {
  display: flex;
  align-items: center;
}

.favorite {
  display: flex;
  cursor: pointer;
  overflow: visible;
  align-self: flex-start;
  padding: 0.5rem;

  .favIcon {
    color: transparent;
    stroke: $purple-medium-2;
    stroke-width: 2px;
    font-size: 2.7rem;

    &:hover {
      color: $purple-medium-2;
    }
  }

  .app {
    &:hover {
      color: transparent;
    }
  }

  .invertedFavIcon {
    color: transparent;
    color: $purple-medium-2;
    stroke: $purple-medium-2;
    stroke-width: 2px;
    font-size: 2.7rem;

    &:hover {
      color: transparent;
    }
  }
}

.notification {
  display: flex;
  cursor: pointer;
  overflow: visible;
  align-self: flex-start;
  margin-left: 0.5rem;
  padding: 0.5rem;

  .notIcon {
    fill: transparent;
    stroke-width: 2px;
    font-size: 3rem;
    color: $orange-medium;

    &:hover {
      fill: $orange-medium;
    }
  }

  .invertedNotIcon {
    fill: $orange-medium;
    stroke-width: 2px;
    font-size: 3rem;

    &:hover {
      fill: transparent;
    }
  }

  .disabled {
    color: $grey-light-2;
    &:hover {
      fill: transparent;
      cursor: auto;
    }
  }
}
.map {
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
}

.spinnerContainer {
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    --color: #7158ad;
  }
}
