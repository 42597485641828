@import "../../../../scss/index.scss";

.details {
  .tourSection {
    margin-top: 3rem;

    .tourSectionHeader {
      padding: 0 2rem 1rem;
      display: grid;
      grid-template-columns: repeat(3, 30%);
      column-gap: 4rem;
      border-bottom-color: #e9e3f2;
      border-bottom-width: 0.1rem;
      border-bottom-style: solid;

      div {
        display: flex;
        color: $grey-dark-2;

        span {
          font-size: 2.6rem;
          font-weight: 500;
        }
        ion-icon {
          display: flex;
          align-self: center;
          width: 3.5rem;
          height: 3.5rem;
          margin-right: 1rem;
        }
      }
    }

    .tourSectionBody {
      padding: 0 2rem 1rem;
      display: grid;
      grid-template-columns: repeat(3, 30%);
      column-gap: 4rem;

      .noItem {
        margin: 2rem 0;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 8rem;
  .spinner {
    transform: scale(2);
    --color: #7158ad;
  }
}
