@import "../../scss/index.scss";

.container {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
  overflow-y: scroll;
  @include show-scrollbar;

  .content {
    margin: 2rem 3rem;

    .title {
      font-size: 3rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .subtitle {
      font-size: 1.5rem;
      color: $grey-dark-1;
    }

    .greeting {
      font-size: 1.8rem;
      color: $grey-dark-2;
      margin-top: 3rem;
    }

    .body {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: normal;

      p {
        text-align: justify;

        .email {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
