@import "../../../scss/index.scss";

.container {
  width: 53rem;

  .error {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: $orange-medium;
  }
  .icon {
    display: grid;
    width: 7rem;
    height: 7rem;
    margin: 2rem auto;
    background-color: #e9e3f2;
    border-radius: 25rem;

    ion-icon {
      width: 6rem;
      height: 6rem;
      display: grid;
      justify-self: center;
      align-self: center;
      color: #7158ad;
    }
  }

  .header {
    display: grid;
    justify-content: center;
    color: #363636;
    font-size: 2.6rem;
    font-weight: 600;
  }

  .subtitle {
    display: grid;
    justify-content: center;
    text-align: center;
    text-justify: newspaper;
    color: #707070;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    span {
      width: 44rem;
    }
  }

  .btns {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 2rem;
    column-gap: 3rem;
  }
}
