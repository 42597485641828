@import "../../../scss/app.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .textarea {
    width: 100%;
    height: 10rem;
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    color: $grey-dark-2;
    outline: none !important;
    border: $input-border;
    border-radius: $radius-sm;
    resize: none;
    @include show-scrollbar;

    &::placeholder {
      color: $grey-dark-1;
      font-size: 1.5rem;
    }

    &::placeholder {
      color: white;
    }
    &:required + label::after {
      content: "*";
      font-size: 1.8rem;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      color: $input-label-color;
      font-size: 1.2rem;
      font-weight: 500;
      transform: translateY(-1.8rem);
      background-color: white;
      padding: 0 3px;
      &::after {
        display: none;
      }
    }
  }
  .label {
    position: absolute;
    top: 1rem;
    left: 0;
    margin-left: 1.35rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $placeholder-color;
    transition: all ease-in-out 0.3s;
    pointer-events: none;
  }
}
