@import "../../../scss/index.scss";

.container {
  display: flex;
  width: 100%;
  height: 100%;

  .segment {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;
    border: 1px solid $grey-light-2;
    border-right: none;

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $grey-dark-2;
    }
    &:first-of-type {
      border-top-left-radius: $radius-sm;
      border-bottom-left-radius: $radius-sm;
    }

    &:last-of-type {
      border-right: 1px solid $grey-light-2;
      border-top-right-radius: $radius-sm;
      border-bottom-right-radius: $radius-sm;
    }
  }
  .active {
    border-color: $purple-medium-2;
    border-right: 1px solid $purple-medium-2 !important;
    background-color: $purple-light-3;
    cursor: default;
    pointer-events: none;
    .title {
      color: $purple-medium-2;
    }
  }
}
