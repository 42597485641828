@import "../../../../scss/index.scss";

.inputs {
  margin: 4rem 2rem;

  .container {
    position: relative;
    margin-bottom: 2rem;
  }

  .times {
    display: grid;
    grid-template-columns: 45% 10% 45%;

    .hyphen {
      height: 100%;
      display: flex;
      align-self: center;
      justify-content: center;
      margin: 0 1rem;
      padding-top: 0.5rem;
    }
  }

  .meetup {
    display: grid;
    grid-template-columns: 45% 10% 45%;
    // column-gap: 4rem;
  }

  .textarea {
    margin: 0 0 2rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 29rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .note {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: $grey-dark-1;
    font-size: 1.2rem;
  }

  .range {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;

    &:hover {
      background-color: $purple-light-2;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
