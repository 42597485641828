@import "../../../../scss/index.scss";

.content {
  --background: none;
  background-color: $grey-light-1;
  overflow-y: scroll;
}

.appHeader {
  position: sticky;
  top: 0;

  .toolbar {
    --background: #f4f4f7;

    .title {
      display: flex;
      justify-content: center;
      font-size: 2.2rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .reset {
      text-transform: initial;
    }

    .btn {
      --color: #707070;
      --icon-font-size: 1.8rem;
      --icon-padding-end: 0.8rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      text-transform: initial;

      .label {
        font-size: 1.5rem;
      }
    }
  }
}

.filters {
  .range {
    --bar-background: #1f7a60;
    --bar-background-active: #dff7f0;
    --bar-height: 5px;
    --knob-background: white;
    --knob-box-shadow: 0px 0px 0px 4px #1f7a60;
    --knob-border-radius: 50%;
    --knob-size: 15px;

    ion-label {
      font-size: 1.5rem;
      font-weight: 600;
      color: $grey-dark-2;
    }
  }
  .rating {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .value {
      font-size: 1.5rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    ion-icon {
      font-size: 2.2rem;
      color: $grey-dark-2;
    }
  }

  .disabled {
    .value {
      font-size: 1.5rem;
      font-weight: 600;
      color: $grey-light-2;
    }

    ion-icon {
      font-size: 2.2rem;
      color: $grey-light-2;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .subtitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 2rem;
    border-bottom: 0.1rem solid $grey-light-2;
  }

  .toggleRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;

    .toggle {
      min-width: 6rem;
      --background: #707070;
      --background-checked: #1f7a60;
      --handle-spacing: 4px;
      --handle-height: 20px;
      --handle-width: 20px;
    }
  }

  .levelRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey-dark-2;
    font-size: 1.5rem;

    .list {
      width: 100%;
      --background: transparent;
      background: transparent;
      .item {
        --padding-start: 1rem;
        --padding-end: 0;
        --background: transparent;
        font-size: 1.5rem;
        color: $grey-dark-2;
        border-bottom: 0.1rem solid $grey-light-2;
        --background-activated: #1f7a60 !important;
        --background-focused: #1f7a60 !important;
        --highlight-color-focused: #1f7a60 !important;
        --highlight-color-valid: #1f7a60 !important;
        --ripple-color: #1f7a60 !important;
        --background-hover: #1f7a60 !important;

        .checkbox {
          --border-color: #707070;
          --border-color-checked: #7158ad;
          --background-checked: #1f7a60;
          --border-color-checked: #1f7a60;
          --checkmark-color: white;
          --border-radius: 50%;
        }
      }
    }

    // ion-checkbox {
    //   --border-width: 0.1rem;
    //   --border-radius: 5px;
    //   margin-right: 2rem;
    //   --background-checked: #1f7a60;
    //   --border-color-checked: #1f7a60;
    // }
  }

  .btn {
    --background: #dff7f0;
    --background-activated: #1f7a60;
    --border-color: #1f7a60;
    --border-style: solid;
    --border-width: 0.1rem;
    --color: #1f7a60;
    --color-activated: #dff7f0;
    --box-shadow: 0;
    height: 5rem;
    margin: 2rem auto;
    text-transform: initial;
  }

  .info {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    font-size: 1.2rem;
    color: $grey-dark-1;
    margin-bottom: 1rem;

    a {
      text-decoration: underline;
      color: $grey-dark-1;
    }
  }
}

.ratingHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span:not(:first-of-type) {
    font-size: 1.5rem;
    padding-right: 2rem;
  }
}
