@import "../../../scss/index.scss";

.container {
  width: 54rem;

  .header {
    display: grid;
    justify-content: center;
    color: #363636;
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    display: grid;
    justify-content: center;
    color: #707070;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;

    span {
      //   justify-content: center;
      width: 46rem;

      a {
        color: #707070;
        text-decoration: underline;
      }
    }
  }

  .btns {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 2rem;
  }
}
