@import "../../scss/index.scss";
@import "../../scss/variables.scss";

.container {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 3fr;
  overflow-y: scroll;
  @include show-scrollbar;

  .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-row: 2/-1;
    grid-column: 1/-1;

    .searchesContainer {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      height: 100%;

      .searches {
        flex: 1;
        padding: 0 3rem;
        .search {
          margin-bottom: 3rem;
        }
      }
    }
  }

  .header {
    display: grid;
    grid-template-columns: 30% 70%;
    height: 10rem;
    padding: 0 3.2rem;

    .viewResult {
      height: 4rem;
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 0.5rem;
      align-self: center;
      justify-content: center;
      align-content: center;
      background-color: white;
      box-shadow: 1px 1px 5px #e9e3f2;
      border: 1px solid #e9e3f2;
      border-radius: 20px;
      font-size: 1.5rem;

      span {
        color: #363636;
        display: grid;
        font-weight: 600;
        grid-template-columns: auto auto;
        column-gap: 1rem;

        ion-icon {
          display: grid;
          align-self: center;
        }
      }
    }
  }

  .mainSection {
    padding: 0 3.2rem;
    .savedSearch {
      display: grid;
      grid-template-columns: 20% 40% 30% 10%;
      background-color: white;
      box-shadow: 1px 1px 5px #e9e3f2;
      border: 1px solid #e9e3f2;
      border-radius: 25px;
      font-size: 1.5rem;
      color: #363636;
      height: 10rem;

      .columns {
        display: grid;
        justify-content: center;
        align-content: center;
        border-right-style: solid;
        border-right-width: 0.1rem;
        border-right-color: #e5e5e5;
        margin: 2rem 0;
        height: 5.5rem;
        font-size: 1.5rem;

        span {
          width: 46rem;
        }
      }

      .name {
        font-weight: 600;
      }

      .detailsRow {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 6rem;
        // width: 100%;

        .detail {
          display: grid;
          grid-template-rows: auto auto;
          row-gap: 1rem;

          .total {
            display: grid;
            color: #7158ad;
            justify-content: center;
          }

          .active {
            display: grid;
            color: #1f7a60;
            justify-content: center;
          }

          .inactive {
            display: grid;
            color: #c24100;
            justify-content: center;
          }
        }
      }
    }
  }

  .icons {
    display: grid;
    grid-template-columns: 50% 50%;

    .edit {
      display: grid;
      justify-content: center;
      align-self: center;
    }

    .delete {
      display: grid;
      align-items: center;

      ion-icon {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  }
}
