@import "../../../../../../scss/index.scss";

.detailPageDropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  flex: 1;
  height: 7.5rem;
  margin-top: 2rem;
  border: 1px solid $purple-medium-2;

  @media only screen and (max-width: $medium) {
    height: 5rem;
    margin-top: 1rem;
  }

  .btn {
    @include input-placeholder;
    cursor: pointer;
    width: 100%;
    height: 7.5rem;
    font-size: 2.2rem;
    font-weight: 400;
    background-color: $purple-light-2;
    color: $purple-medium-2;

    @media only screen and (max-width: $medium) {
      height: 5rem;
      font-size: 1.5rem;
      font-weight: 600;
    }

    &:disabled {
      color: $grey-light-2;
      cursor: default;
    }

    .toggle {
      margin-left: 2rem;
    }
  }

  .invertedLabel {
    font-weight: 400;
  }

  .icon {
    @include center-vertically;
    right: 3rem;
    color: $purple-medium-2;
    --ionicon-stroke-width: 4.8rem;
    cursor: pointer;
    transition: all 0.2s;
    height: 2.5rem;
    width: 2.5rem;

    @media only screen and (max-width: $medium) {
      height: 2rem;
      width: 2rem;
    }
  }

  .invertedIcon {
    color: white;
  }

  .iconReversed {
    transform: rotate(180deg) translateY(50%);
  }

  .inverted {
    background-color: transparent;
    color: white;
    border-color: $purple-medium-1;
    font-weight: 400;
    font-size: 1.4rem;
  }
}
.halfRound {
  border-radius: 5px 5px 0px 0px;
}

.round {
  border-radius: 5px 5px 5px 5px;
}
