@import "../../../../scss/index.scss";

.showing {
  padding-bottom: 4rem;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem;

    .title {
      display: flex;
      font-size: 2.6rem;
      font-weight: 500;

      ion-icon {
        display: flex;
        align-self: center;
        margin-right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .tag {
      display: flex;
      flex-direction: row;
      background-color: #ffeee5;
      color: $yellow-dark;
      border-radius: 5px;
      padding: 0 1rem;
      height: 3rem;
      align-self: center;

      .icon {
        color: #c24100;
        margin-right: 1rem;
        display: flex;
        align-self: center;
      }
      span {
        color: #c24100;
        display: flex;
        align-self: center;
        font-size: 1.2rem;
      }
    }
  }

  .inputs {
    margin: 2rem 2rem 0;

    .times {
      display: grid;
      grid-template-columns: 45% 10% 45%;
      margin: 2rem 0;
      width: 100%;

      .container {
        position: relative;
      }
      .hyphen {
        display: flex;
        justify-content: center;
        align-self: center;
      }
    }

    .container {
      position: relative;
    }

    .entryContainer {
      margin-top: 2rem;
    }

    .range {
      text-align: center;
      font-size: 1.5rem;
      padding: 0.5rem 2rem;
      cursor: pointer;

      &:hover {
        border-radius: 5px;
        background-color: $grey-light-1;
      }
    }
  }

  .map {
    position: relative;
    background-color: $purple-light-1;
    height: 20rem;
    margin: 2rem;
    border-radius: 5px;
  }

  .mapTransparent {
    background-color: transparent;
  }

  .details {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    font-size: 1.5rem;
    color: $grey-dark-1;

    div {
      margin: 0.5rem 0;
    }
    .bold {
      color: $grey-dark-2;
      font-weight: 600;
    }

    ion-icon {
      display: inline-flex;
      align-self: center;
    }
  }

  .button {
    margin: 2rem 2rem 0;
    --background: #e9e3f2;
    color: #7158ad;
    text-transform: initial;
    --border-color: #7158ad;
    --border-width: 0.1rem;
    --border-style: solid;
    --box-shadow: 0;
    height: 5rem;

    ion-icon {
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
    }
  }
}

.noResult {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
  .icon {
    font-size: 1.7rem;
    margin-right: 0.5rem;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selected {
  display: flex;
  align-items: center;
  border: $input-border;
  border-radius: $radius-sm;
  padding: 1rem;
  font-size: 1.5rem;
  color: $grey-dark-2;
  justify-content: space-between;

  .icon {
    font-size: 2rem;
    margin-right: 0.5rem;
    &:hover {
      cursor: pointer;
    }
  }
}

.favourites {
  .item {
    border: $input-border;
    border-radius: $radius-sm;
    --inner-padding-top: 0;
    --padding-top: 0;
    --color: $purple-medium-2;
    font-size: 1.5rem;
    margin-top: 1rem;
    cursor: pointer;
    .picker {
      color: $grey-dark-1;
    }

    ion-label {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      color: $grey-dark-1 !important;

      ion-icon {
        display: flex;
        align-self: center;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }
    }

    ion-select {
      color: $grey-dark-2 !important;
    }
  }

  .favourite {
    --background: #f9f9f9;
  }

  .slidesContainer {
    display: flex;
    align-items: center;

    ion-icon {
      width: 4rem;
      height: 4rem;
    }

    ion-slides {
      width: -webkit-fill-available;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .slidesIcon {
      font-size: 15rem;
      color: $grey-medium-1;
      padding: 0;
      transition: all 0.3s ease-in;

      &:hover {
        cursor: pointer;
        color: $grey-dark-2;
      }
    }
  }
}

.slides {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll !important;
  @include show-scrollbar;
}

.slides {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll !important;
  @include show-scrollbar;
}
