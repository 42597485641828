@import "../../../../../scss/index.scss";

.tourElement {
  position: relative;
  display: grid;
  grid-template-columns: 10% 60% 30%;
  align-items: center;
  border-bottom-color: $grey-light-2;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  color: $grey-dark-1;
  font-size: 1.5rem;
  .icon {
    ion-icon {
      display: flex;
      align-self: center;
      font-size: 2rem;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    color: $grey-dark-2;

    & span:last-of-type {
      margin-top: -0.35rem;
      font-size: 1.2rem;
      color: $grey-medium-1;
    }
  }

  .status {
    font-size: 1.2rem;
    position: absolute;
    right: 5rem;
    text-transform: capitalize;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    width: 8rem;
    text-align: center;
  }
  .ready {
    background-color: $green-light;
    color: $green-dark;
  }

  .draft {
    background-color: $yellow-medium;
    color: $yellow-dark;
  }

  .pending {
    color: $yellow-dark;
    background-color: $yellow-light;
  }

  .cancelled {
    background-color: $orange-light;
    color: $orange-dark;
  }

  .completed {
    background-color: $purple-light-2;
    color: $purple-medium-2;
  }

  .archived {
    background-color: $grey-medium-1;
    color: $grey-light-1;
  }

  .arrowIcon {
    display: flex;
    justify-content: flex-end;

    ion-icon {
      font-size: 2.2rem;
      color: $grey-dark-1;
    }
  }

  .shared {
    background-color: $green-light;
    color: $green-dark;
    padding: 0.25rem 0.75rem;
    border-radius: $radius-sm;
  }
  .unshared {
    background-color: $orange-light;
    color: $orange-dark;
    padding: 0.25rem 0.75rem;
    border-radius: $radius-sm;
  }
}
