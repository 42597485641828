@import "../../scss/index.scss";

.imageContainer {
  width: 100%;
  overflow: hidden;
  border-radius: $radius-md;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  .image {
    width: 100%;
    height: 100%;
    border-radius: $radius-md;
    object-fit: cover;
  }

  .imageLoading {
    width: 0;
    height: 0;
  }

  .spinnerContainer {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner {
      --color: #7158ad;
    }
  }
}
