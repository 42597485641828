@import "../../../../../../../../scss/index.scss";

.essentials {
  display: grid;
  margin: 0 auto;
  border: $cluster-border;
  border-top-style: none;
  border-radius: 0px 0px 5px 5px;

  @media only screen and (max-width: $large) {
    width: 100%;
  }

  .infos {
    display: grid;
    margin: 2rem 4rem 2rem 2rem;
    display: grid;
    grid-template-columns: repeat(2, 48%);
    column-gap: 4rem;

    @media only screen and (max-width: 1024px) {
      grid-template-columns: auto;
    }

    div {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      border-bottom-style: solid;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;

      margin-bottom: 2rem;
      padding-bottom: 2rem;
      color: #666666;
      font-size: 1.8rem;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }

      .bold {
        font-weight: 600;

        @media only screen and (max-width: $medium) {
          font-weight: 400;
        }
      }
    }

    .brokeragename {
      margin-left: 0.5rem;
      text-align: end;
    }

    .underline {
      text-decoration: underline;
    }

    .pointer {
      cursor: pointer;
    }
  }
}
