@import "../../../scss/index.scss";

.feedback {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
  @include show-scrollbar;
  overflow-y: scroll;

  .cards {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    column-gap: 2rem;
    height: 100%;

    .content {
      margin: 2rem 1rem 0;

      .title {
        font-size: 2.6rem;
        color: $grey-dark-2;
        font-weight: 600;
      }

      .subtitle {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        font-size: 1.5rem;
        color: $grey-dark-2;
        border-bottom: 0.1rem solid $grey-light-2;
      }

      .version {
        color: $grey-dark-2;
        font-size: 1.5rem;
        border-bottom: 0.1rem solid $grey-light-2;
        margin-bottom: 1rem;
        padding-bottom: 2rem;

        .versionNumber {
          display: flex;
          justify-content: flex-end;
          text-decoration: underline !important;
          font-weight: 600;
        }
      }
    }

    .form {
      margin: 2rem 1.5rem 1rem;

      .formlabel {
        color: $grey-dark-2;
        font-size: 2.6rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      .formsubtitle {
        color: $grey-dark-1;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      .inputs {
        ion-row {
          margin-bottom: 1rem;
        }

        .input {
          display: grid;
          grid-template-columns: repeat(2, auto);

          @media screen and (max-width: 1104px) {
            grid-template-columns: auto;
          }
        }
      }
    }

    .button {
      --background: #e9e3f2;
      --background-activated: #e9e3f2;
      --border-color: #7158ad;
      --border-width: 1px;
      --border-style: solid;
      --box-shadow: 0;
      --color: #7158ad;
      text-transform: initial;
      height: 5rem;
    }
  }
}
