@import "../../../scss/index.scss";

.favouriteheader {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: 600;
  color: $grey-dark-2;
  height: auto;
  padding: 1rem 1.5rem;

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;

    .addIcon {
      display: flex;
      align-items: center;
      font-size: 2rem;
      margin-right: 1rem;
    }

    .label {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
    }
    .disabled {
      color: $grey-dark-1;
      cursor: not-allowed;
    }
  }

  .sort {
    display: flex;
  }
}
