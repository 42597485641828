@import "../../../../scss/index.scss";

.clusterMarker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.8rem;
  font-size: 1.5rem;
  border-radius: 50%;
  color: white;
  background-color: $cluster-bg-color;
  border: $cluster-border;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: $purple-dark-1;
  }
}

.animatedClusterMarker {
  animation: mount 0.4s ease-in-out;

  //0.2s calc(var(--animation-order) * 125ms) both ease-in;
}

.animatedMarker {
  animation: mountTranslate 0.4s ease-in-out;
}

.clusterMarkerLight {
  background-color: #ffffffe5;
  border-color: $purple-medium-2;
  color: $purple-medium-2;

  &:hover {
    background-color: $purple-medium-2;
    color: white;
    border-color: white;
  }
}

.marker {
  position: relative;
  background-color: $marker-bg-color;
  width: 5rem;
  height: 2.5rem;
  cursor: pointer;
  z-index: 10;
  .price {
    @include center-vertically;
    @include center-horizontally;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: solid 0.7rem $marker-bg-color;
    border-left: solid 0.7rem transparent;
    border-right: solid 0.7rem transparent;
  }

  &:hover {
    background-color: $purple-dark-1;

    &::after {
      border-top-color: $purple-dark-1;
    }
  }
}

.wideMarker {
  width: 6rem;
}

.markerLight {
  background-color: #ffffffe5;
  .price {
    color: $purple-medium-2;
  }

  &::after {
    border-top-color: #ffffffe5;
  }

  &:hover {
    background-color: $purple-medium-2;

    .price {
      color: white;
    }

    &::after {
      border-top-color: $purple-medium-2;
    }
  }
}

.selectedCluster {
  background-color: $purple-dark-1;
}
.selectedMarker {
  background-color: $purple-dark-1;

  &:after {
    border-top-color: $purple-dark-1;
  }
}

.selectedClusterLight {
  background-color: $purple-medium-2;
  border-color: white;
  color: white;
}

.selectedMarkerLight {
  background-color: $purple-medium-2;
  .price {
    color: white;
  }

  &:after {
    border-top-color: $purple-medium-2;
  }
}

.hovered {
  animation: hoverAnimation 1.5s ease infinite;
}

.activeCluster {
  background-color: #ffeee5e8;
  border-color: $orange-medium;
  color: $orange-medium;
}

.activeMarker {
  background-color: $orange-medium;
  &:after {
    border-top-color: $orange-medium;
  }
}

@keyframes hoverAnimation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-0.5rem);
  }
  60% {
    transform: translateY(-0.2rem);
  }
}

@keyframes mount {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mountTranslate {
  0% {
    opacity: 0;
    transform: scale(1) translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
