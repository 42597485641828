@import "../../../../../scss/index.scss";
@import "../../../../../scss/variables.scss";

.firstColumn {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 2.5rem 4rem;

  .label {
    font-size: 1.5rem;
    font-weight: normal;
    color: $grey-dark-1;
  }

  .value {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;

    .title {
      flex: 1;
    }

    .edit {
      color: $purple-medium-2;
      font-size: 1.5rem;
      font-weight: 600;
      cursor: pointer;

      ion-icon {
        height: 2.2rem;
        width: 2.2rem;
      }
    }

    .verify {
      color: $purple-medium-2;
      font-size: 1.5rem;
      font-weight: 600;
      cursor: pointer;
      margin-right: 2rem;
      ion-icon {
        height: 2.2rem;
        width: 2.2rem;
      }
    }
  }
}

.border {
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: $grey-light-2;
  margin-bottom: 2rem;
}
