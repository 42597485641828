@import "../../../../scss/index.scss";

.attendees {
  .inputs {
    margin: 4rem 2rem;

    .container {
      position: relative;
      margin-bottom: 2rem;
    }

    .remove {
      display: grid;
      grid-template-columns: 90% 10%;

      .delete {
        display: flex;
        justify-self: flex-end;
        width: max-content;
        height: max-content;
        padding: 1rem;
        border: 1px solid $grey-light-2;
        border-radius: 5px;
        background-color: $grey-light-1;
        cursor: pointer;

        ion-icon {
          color: $grey-dark-2;
        }
      }
    }

    .add {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      padding-left: 1rem;
      background-color: $grey-light-1;
      border: 1px solid $grey-light-2;
      border-radius: 5px;
      height: 4rem;
      cursor: pointer;

      ion-icon {
        color: $grey-dark-1;
        margin-right: 1rem;
        width: 2rem;
        height: 2.5rem;
      }

      span {
        color: $grey-dark-1;
        font-size: 1.5rem;
      }
    }

    .agentDropdown {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;

      &:hover {
        background-color: $purple-light-2;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .noClient {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
    }

    .button {
      --background: #dff7f0;
      --color: #1f7a60;
      --border-color: #1f7a60;
      --border-style: solid;
      --border-width: 0.1rem;
      --box-shadow: 0;
      text-transform: initial;
      height: 5rem;
      margin-bottom: 2rem;
    }

    .note {
      display: flex;
      justify-content: center;
      color: $grey-dark-1;
      font-size: 1.2rem;
    }

    .guests {
      display: flex;
      //justify-content: space-evenly;
      row-gap: 1.5rem;
      flex-wrap: wrap;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;

      .guest {
        width: 100%;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 1.5rem;
        border: 1px solid $grey-light-2;
        border-radius: $radius-sm;
        .icon {
          color: $grey-dark-1;
          font-size: 2rem;
          margin-right: 1rem;
        }
        .closeIcon {
          color: $grey-dark-1;
          font-size: 2rem;
          cursor: pointer;
          justify-self: flex-end;
        }
        .label {
          flex: 1;
          font-size: 1.5rem;
          color: $grey-dark-1;
        }
      }
      .chip {
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        border: 1px solid $purple-dark-1;
        background-color: $purple-light-2;
        border-radius: $radius-sm;
        white-space: nowrap;
        .icon {
          color: $purple-dark-1;
          cursor: pointer;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
