@import "../../../scss/index.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 45rem;

  .iconContainer {
    width: max-content;
    margin: 0 auto;
    background-color: $green-light;
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    .icon {
      color: $green-dark !important;
      width: 4rem;
      height: 4rem;
    }
  }

  .title {
    display: grid;
    justify-content: center;
    color: $grey-dark-2;
    font-size: 2.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .subtitle {
    margin: 0 auto;
    color: $grey-dark-1;
    font-size: 1.5rem;
    width: 80%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .btn {
    display: flex;
    justify-content: center;
  }
}
