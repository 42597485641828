@import "../../scss/index.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  height: 100%;
  width: 100%;

  .left {
    display: flex;
    align-items: center;
    .stores {
      font-size: 1.2rem;
      font-weight: 300;
      .label {
        margin-right: 1rem;
      }
      .link {
        a {
          color: $purple-light-3;
          text-decoration: underline;
        }
        &:not(:last-of-type)::after {
          content: "|";
          padding: 0 1rem;
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      margin-left: 2rem;

      span {
        font-size: 1.2rem;
        margin-right: 1rem;
        @media screen and (max-width: $large) {
          display: none;
        }
      }

      .icon {
        margin-right: 1.5rem;
        cursor: pointer;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 300;
    .links {
      .link {
        a {
          color: $purple-light-3;
          text-decoration: underline;
        }
        &:not(:last-of-type)::after {
          content: "|";
          padding: 0 0.8rem;
        }
      }
    }

    .copyright {
      margin-left: 2rem;
    }

    .version {
      margin-left: 2rem;
    }
  }
}
