@import "../../../../scss/index.scss";

.details {
  margin: 0 2rem 4rem;

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
    line-height: 1rem;
  }

  .textarea {
    margin: 2rem 0;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 24rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
    color: $grey-dark-1;
    @include show-scrollbar;
  }
}
