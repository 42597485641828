@import "../../../../scss/app.scss";

.container {
  background-color: white;
  width: 100%;
  height: 100%;
}

.content {
  padding: 2rem;

  .msg {
    font-size: 1.5rem;
    color: $grey-dark-1;
    font-weight: 400;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $grey-light-2;
  }

  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey-light-2;
    height: 6rem;

    .icon {
      display: flex;
      align-items: center;
      margin-right: 1.5rem;
      .svg {
        stroke: $purple-medium-2;
        fill: transparent;
        width: 4.5rem;
        height: 3rem;
      }
    }

    .label {
      font-size: 1.5rem;
      color: $grey-dark-2;
      font-weight: 400;
      width: 60%;
    }
  }

  .btn {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  }

  .signIn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $grey-dark-1;

    .link {
      margin-left: 1rem;
      text-decoration: underline;
      color: $purple-medium-2;
      font-weight: 600;
    }
  }
}
