@import "../../../../scss/index.scss";

.recordingsSection {
  margin: 2rem 0;

  .currentUser {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    // padding: 0 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: $small) {
      padding-left: 2rem;
    }
    .title {
      font-size: 1.8rem;
      font-weight: 600;
      color: $grey-dark-2;
    }
    .disableDeleteMode {
      cursor: not-allowed;
      color: $orange-medium;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .deleteMode {
      cursor: pointer;
      color: $orange-medium;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .others {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;
    margin-top: 4rem;

    @media only screen and (max-width: $small) {
      padding-left: 2rem;
    }

    .role {
      color: $grey-dark-1;
      font-size: 1.2rem;
      margin-left: 0.5rem;
      font-weight: 400;
    }
  }

  .recordings {
    .noRecording {
      font-size: 1.5rem;
      color: $grey-dark-1;
      border-bottom-width: 0.1rem;
      border-bottom-style: solid;
      border-bottom-color: $purple-light-2;
      padding: 1rem 0.5rem;

      @media only screen and (max-width: $small) {
        padding: 1rem 2rem;
      }
    }
  }
}
