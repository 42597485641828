@import "../../../scss/app.scss";

.card {
  padding: 0;
  background-color: white;
  margin-bottom: 1rem;
  border-radius: $radius-normal;
  height: auto;
  width: 95%;
  min-width: 95%;

  .header {
    position: relative;
    padding: 0;
    height: 12rem;
    width: 100%;

    background-color: $purple-light-1;

    .spinner {
      position: absolute;
      @include center-vertically;
      @include center-horizontally;
      color: $purple-medium-2;
    }

    .image {
      object-fit: cover;
      width: 100%;

      height: 100%;
      padding: 0;
    }

    .tag {
      display: flex;
      width: auto;
      flex-wrap: nowrap;
      white-space: nowrap;
      align-items: center;
      position: absolute;
      bottom: 1.5rem;
      left: 1.5rem;
      background-color: white;

      border-radius: $radius-sm;
      font-size: 1.2rem;
      font-weight: 300;
      color: $grey-dark-1;

      .active {
        background-color: $green-light;
        color: $green-dark;
        flex: 1;
        border-top-left-radius: $radius-sm;
        border-bottom-left-radius: $radius-sm;
        padding: 0.25rem 0.5rem;
      }

      .inactive {
        background-color: $orange-light;
        color: $orange-dark;
        flex: 1;
        border-top-left-radius: $radius-sm;
        border-bottom-left-radius: $radius-sm;
        padding: 0.25rem 0.5rem;
      }
      .date {
        flex: 1;
        border-top-right-radius: $radius-sm;
        border-bottom-right-radius: $radius-sm;
        padding: 0.25rem 0.5rem;
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    padding: 1.5rem;
    padding-bottom: 1rem;

    .icons {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      display: flex;
      z-index: 1000;
    }

    .price {
      font-size: 1.8rem;
      font-weight: 600;
      color: $grey-dark-2;
      margin-bottom: 0.3rem;
      text-align: left;
    }
    .priceDiff {
      display: flex;
      font-size: 1.2rem;
      color: $grey-dark-2;
      font-weight: 400;
      margin-bottom: 0.5rem;

      .asking,
      .increased,
      .decreased {
        display: flex;
        align-items: center;
      }

      .increased {
        color: $green-dark;
      }

      .decreased {
        color: $orange-dark;
      }

      .value {
        margin-left: 0.2rem;
        font-weight: 500;
      }

      .text {
        font-style: italic;
        color: $grey-dark-1;
        margin-left: 0.3rem;
      }
    }
    .address {
      font-size: 1.2rem;
      color: $grey-dark-1;
      text-align: left;
    }

    .info {
      font-size: 1.2rem;
      color: $grey-dark-1;
      text-align: left;

      & span:not(:last-of-type)::after {
        content: "|";
        padding: 0 0.5rem;
        color: $grey-light-2;
      }
    }
  }
}

.expandedCard {
  padding: 0;
  background-color: white;
  margin-bottom: 2rem;
  border-radius: $radius-normal;
  height: auto;

  .header {
    position: relative;
    padding: 0;
    height: 18rem;

    .spinner {
      position: absolute;
      @include center-vertically;
      @include center-horizontally;
      color: $purple-medium-2;
    }

    .image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  .content {
    position: relative;
    padding: 1.5rem;
    padding-bottom: 1rem;

    .icons {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      display: flex;
      z-index: 1000;
    }

    // .icon {
    //   color: $grey-dark-2;
    //   margin-right: 0.8rem;
    //   font-size: 1.6rem;
    // }

    .locationIcon {
      color: $grey-dark-2;
      margin-right: 0.8rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    .propertyIcon {
      color: $grey-dark-2;
      margin-right: 0.8rem;
      width: 1.5rem;
      height: 1.3rem;
    }

    .bedIcon {
      color: $grey-dark-2;
      margin-right: 0.8rem;
      width: 1.5rem;
      height: 1.6rem;
    }

    .price {
      font-size: 2.5rem;
      font-weight: 600;
      color: $grey-dark-2;
      margin-bottom: 0.3rem;
    }

    .priceDiff {
      display: flex;
      font-size: 1.5rem;
      color: $grey-dark-2;
      font-weight: 400;
      margin-bottom: 1rem;

      .asking,
      .increased,
      .decreased {
        display: flex;
        align-items: center;
      }

      .increased {
        color: $green-dark;
      }

      .decreased {
        color: $orange-dark;
      }

      .value {
        margin-left: 0.2rem;
        font-weight: 500;
      }

      .text {
        font-style: italic;
        color: $grey-dark-1;
        margin-left: 0.3rem;
      }
    }
    .address {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: $grey-dark-1;
      // margin-bottom: 0.5rem;
    }

    .info {
      font-size: 1.5rem;
      color: $grey-dark-1;
      display: flex;
      align-items: center;

      & span:not(:last-of-type)::after {
        content: "|";
        padding: 0 0.5rem;
        color: $grey-light-2;
      }
    }

    .tag {
      display: flex;
      width: auto;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: $grey-dark-1;
      margin: 2rem 0;

      .active {
        background-color: $green-light;
        color: $green-dark;
        border-radius: $radius-sm;
        padding: 0.25rem 0.8rem;
      }

      .inactive {
        background-color: $orange-light;
        color: $orange-dark;
        border-radius: $radius-sm;
        padding: 0.25rem 0.8rem;
      }
      .date {
        flex: 1;
        padding: 0.25rem 0.5rem;
        font-weight: 400;
      }
    }
  }
}

.mlsContainer {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2rem;
  color: $grey-dark-1;
  margin-top: 0.5rem;

  span {
    text-decoration: underline;
    margin-left: 0.3rem;
  }
}
