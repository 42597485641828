@import "../../../scss/index.scss";

.appClientTour {
  .segment {
    margin: 2rem;
  }

  .cardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .message {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.5rem;
      font-size: 1.5rem;

      svg {
        width: 22rem;
        height: 22rem;
      }

      .title {
        font-size: 2.2rem;
        font-weight: 600;
        color: $grey-dark-2;
        margin-top: -1rem;
        margin-bottom: 1rem;
        justify-self: flex-start;
      }

      .text {
        color: $grey-dark-1;
        width: 90%;
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }

  .archiveSubtitle {
    margin: 2rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
  }

  .card {
    background-color: white;
    border-radius: $radius-md;
    box-shadow: $box-shadow-grey;
    padding: 1rem 1.5rem;
    margin: 0 2rem 2rem;

    // h3 {
    //   font-size: 1.8rem;
    //   font-weight: 600;
    //   margin-bottom: -0.5rem;
    // }
    p {
      font-size: 1.5rem;
      color: $grey-dark-1;
    }
  }
}
