@import "../../../../../scss/app.scss";

.item {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;

  .pricePin {
    position: absolute;
    width: auto;
    height: 3rem;
    background-color: $purple-light-2;
    border-radius: $radius-sm;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include center-horizontally;
    top: 0;
    z-index: 100;

    &:after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-top: solid 1.1rem $purple-light-2;
      border-left: solid 1.1rem transparent;
      border-right: solid 1.1rem transparent;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem;

    .note {
      font-size: 1.2rem;
      color: $grey-medium-1;
      margin-left: 1rem;
    }
    .range {
      --bar-background: #e9e3f2;
      --bar-background-active: #7158ad;
      --bar-height: 5px;
      --knob-background: white;
      --knob-box-shadow: 0px 0px 0px 4px #7158ad;
      --knob-border-radius: 50%;
      --knob-size: 15px;
    }
    .value {
      --color: #363636;
    }
    .label {
      color: $grey-dark-1;
    }

    .pins {
      display: flex;
      justify-content: space-between;
      margin-top: -1rem;
      margin-bottom: 1rem;
      padding: 0 1rem;
      .pin {
        color: $purple-medium-2;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
}
