@import "../../../scss/index.scss";

.aboutus {
  margin: 2rem 1rem;

  .generalInformation {
    border-bottom: 0.1rem solid $grey-light-2;
    padding-bottom: 2.5rem;
    margin-bottom: 4rem;

    .title {
      margin-bottom: 1rem;
      font-size: 2.2rem;
      font-weight: 600;
      color: $grey-dark-2;
    }
    .info {
      font-size: 1.5rem;
      color: $grey-dark-2;
      margin-bottom: 2rem;

      div {
        margin-bottom: 0.5rem;
      }
    }
    .button {
      --background: #e9e3f2;
      --background-activated: #e9e3f2;
      --border-color: #7158ad;
      --border-width: 1px;
      --border-style: solid;
      --box-shadow: 0;
      --color: #7158ad;
      text-transform: initial;
      height: 5rem;
    }
  }

  .team {
    border-bottom: 0.1rem solid $grey-light-2;
    padding-bottom: 2rem;

    .title {
      margin-bottom: 2rem;
      font-size: 2.2rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    .img {
      display: flex;
      margin: 3rem auto 1.5rem;
      width: 15rem;
    }

    .info {
      font-size: 1.5rem;
      color: $grey-dark-2;

      .label {
        font-weight: 600;
        line-height: 2.5;
      }
    }
  }

  .career {
    margin: 4rem 0;
    color: $grey-dark-2;
    border-bottom: 0.1rem solid $grey-light-2;
    padding-bottom: 2rem;

    .title {
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    .subtitle {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.5;
    }
    .info {
      font-size: 1.5rem;
    }
  }

  .faq {
    .title {
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .question {
      font-size: 1.5rem;
      border-bottom: 0.1rem solid $grey-light-2;
      padding-bottom: 2rem;
      margin-bottom: 2rem;

      .label {
        font-weight: 600;
        margin-bottom: 1rem;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .underline {
    text-decoration: underline;
  }
}
