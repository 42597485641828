@import "../../../scss/index.scss";

.container {
  width: 58rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $grey-dark-2;
  padding: 0 2rem;
  overflow-y: scroll;

  .header {
    display: flex;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
    background-color: $grey-light-2;
    padding: 2rem 0;
    border-radius: $radius-normal;

    .label {
      margin-right: 2rem;
      font-size: 1.8rem;
    }
  }

  .enabled {
    background-color: $green-light;
  }

  .content {
    padding: 0 0.3rem;

    .config {
      display: flex;
      margin-top: 2rem;
      justify-content: space-between;

      .category {
        display: flex;
        flex-direction: column;

        .title {
          font-weight: 600;
          margin-bottom: 1rem;
        }
        .disabled {
          color: $grey-light-2;
        }
      }
    }
    .rating {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .range {
        --bar-background: #1f7a60;
        --bar-background-active: #dff7f0;
        --bar-height: 5px;
        --knob-background: white;
        --knob-box-shadow: 0px 0px 0px 4px #1f7a60;
        --knob-border-radius: 50%;
        --knob-size: 15px;
        padding: 0;
        margin: 0;

        ion-label {
          font-size: 1.5rem;
          font-weight: 600;
          color: $grey-dark-2;
        }
      }

      .title {
        display: flex;
        align-items: center;
        font-weight: 600;

        span:last-of-type {
          margin-left: 1.5rem;
          font-size: 1.8rem;
          color: $green-dark;
        }
      }
      .disabled {
        color: $grey-light-2;
      }

      .config {
        display: flex;
        flex-direction: column;

        .checkbox {
          flex: 1;
        }
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
    }
  }
}
