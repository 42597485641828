@import "../../scss/index.scss";

.container {
  display: grid;
  height: 100%;
  overflow-y: hidden;
  grid-template-rows: 10rem min-content 1fr;
  grid-template-columns: 20rem repeat(8, 1fr);
  background: #f4f4f7 0% 0% no-repeat padding-box;

  @include hide-scrollbar;

  // & * {
  //   @include hide-scrollbar;
  // }
}

.collapse {
  grid-template-columns: 7.5rem repeat(8, 1fr);
}

.config {
  z-index: 5;
  background-color: $purple-light-1;
  grid-row: 2/3;
  grid-column: 2/-1;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .sort {
    display: flex;
    justify-content: space-between;
  }
  .filters {
    margin-bottom: 2rem;
  }
}

.modal {
  --width: 75%;
  --height: 80%;
  --border-radius: 2.5rem;
  background: rgba($grey-dark-2, 0.7);

  @media screen and (min-width: $largest) {
    --width: 65%;
  }
}

.error {
  grid-row: 1/-1;
  grid-column: 2/-1;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 950;
  background-color: rgba(#000, 0.6);
}

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.5rem;
  --button-color: white;
}

.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.5rem;
  --button-color: white;
}

.filters {
  z-index: 1000;
  position: relative;
  grid-row: 1/4;
  grid-column: 2/4;
  height: 100%;
  display: flex;
  @media screen and (min-width: $large) {
    min-width: 40rem;
  }
}

.hideFilters {
  visibility: hidden;
}
