@import "../../../scss/index.scss";

.appHeader {
  position: sticky;
  top: 0;

  .toolbar {
    --background: #f4f4f7;

    .title {
      display: flex;
      justify-content: center;
      font-size: 2.2rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .backButton {
      display: flex;
      justify-content: flex-start;
      --color: #707070;
      --icon-font-size: 1.8rem;
      --icon-padding-end: 0.8rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      padding: 0;
      text-transform: initial;

      .label {
        font-size: 1.5rem;
      }
    }

    .clear {
      display: flex;
      justify-content: flex-end;
      --color: #707070;
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: initial;
      span {
        color: $grey-dark-1;
      }
    }
  }
}
