@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

$base-font-size: 62.5%; // 10px
$base-font-family: "Poppins", "Open Sans", "Source Sans Pro", sans-serif;

// Breakpoints
$largest: 1824px;
$large: 1224px;
$x-medium: 959px;
$medium: 768px;
$small: 640px;
$smallest: 420px;

$search-bp1: 1600px;
$search-bp2: 1400px;
$search-bp3: 1080px;
$search-bp4: 950px;

// COLORS
$green-light: #dff7f0;
$green-dark: #1f7a60;

$yellow-light: #fff6d6;
$yellow-medium: #fff6d7;
$yellow-dark: #8a6a00;

$orange-light: #ffeee5;
$orange-medium: #ff5500;
$orange-medium2: #ff5f0f;
$orange-dark: #c24100;

$blue-light: #e0faff;
$blue-dark: #007b94;

$purple-light-1: #f4f4f7;
$purple-light-2: #e9e3f2;
$purple-light-3: #dedaec;
$purple-medium-1: #927bca;
$purple-medium-2: #7158ad;
$purple-dark-1: #33244c;
$purple-dark-2: #110d1b;

$grey-light-1: #f9f9f9;
$grey-light-2: #e5e5e5;
$grey-medium-1: #9e9e9e;
$grey-dark-1: #707070;
$grey-dark-2: #363636;

// BORDER COLOR
$border-color: #bfbfbf;

// MAP COLORS
$cluster-bg-color: #8069b59a;
$cluster-border: 1px solid #7158ad;
$marker-bg-color: #7158ad;

// BACKGROUND COLORS
$primary-bg-color: #f4f4f7;
$secondary-bg-color: white;

// TEXT COLORS
$primary-text-cplor: #363636;
$secondary-text-color: #707070;

// LINE COLOR
$line-color: #dedaec;

// SHODOW COLOR
$shadow-color: #e9e3f2;
$box-shadow: 1px 1px 1.5rem #e9e3f2;
$box-shadow-dark: 1px 1px 1.5rem #33244c0d;
$box-shadow-grey: 1px 1px 1.5rem #00000027;
$box-shadow-map-button: 1px 1px 15px #00000034;

// COLORED TEXT
@mixin blue-light-text {
  color: #007b94;
  background-color: #e0faff;
}

@mixin orange-light-text {
  color: #c24100;
  background-color: #ffeee5;
}

@mixin orange-dark-text {
  color: white;
  background-color: #ff5500;
}

@mixin green-light-text {
  color: #1f7a60;
  background-color: #dff7f0;
}

@mixin purple-light-text {
  color: #7158ad;
  background-color: #e9e3f2;
}

@mixin grey-light-text {
  color: #363636;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
}

// NAV
$btn-text-color: #dedaec;
$btn-highlight-color: #927bc1;
$btn-bg-color: #33244c;
$btn-border-color: #7158ad;

@mixin primary-btn {
  color: $btn-text-color;
  background-color: $btn-bg-color;
  border: 1px solid $btn-border-color;
}

// BORDER RADIUS
$radius-sm: 0.5rem;
$radius-normal: 1rem;
$radius-md: 1.5rem;
$radius-lg: 2.5rem;

// BORDER
$dashed-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23333' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

// INPUT
$placeholder-color: #707070;
$placeholder-font-size: 1.5rem;
$placeholder-font-weight: 400;
$input-icon-color: #363636;
$input-label-color: #7158ad;
$input-label-font-size: 1.2rem;
$input-label-font-weight: 500;
$input-text-color: #363636;
$input-text-font-size: 1.5rem;
$input-text-font-weight: 600;
$input-border: 1px solid #e5e5e5;

// ANIMATION

@keyframes Border {
  0% {
    border-color: #f4f4f7;
  }
  10% {
    border-color: #e9e3f2;
  }
  20% {
    border-color: #dedaec;
  }
  30% {
    border-color: #927bca;
  }
  40% {
    border-color: #7158ad;
  }
  50% {
    border-color: #33244c;
  }
  60% {
    border-color: #7158ad;
  }
  70% {
    border-color: #927bca;
  }
  80% {
    border-color: #dedaec;
  }
  90% {
    border-color: #e9e3f2;
  }
  100% {
    border-color: #f4f4f7;
  }
}

@-webkit-keyframes Border {
  0% {
    border-color: #f4f4f7;
  }
  10% {
    border-color: #e9e3f2;
  }
  20% {
    border-color: #dedaec;
  }
  30% {
    border-color: #927bca;
  }
  40% {
    border-color: #7158ad;
  }
  50% {
    border-color: #33244c;
  }
  60% {
    border-color: #7158ad;
  }
  70% {
    border-color: #927bca;
  }
  80% {
    border-color: #dedaec;
  }
  90% {
    border-color: #e9e3f2;
  }
  100% {
    border-color: #f4f4f7;
  }
}
