@import "../../../scss/index.scss";
.favorite {
  display: flex;
  margin: 14rem auto 0;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  overflow: visible;
  transform: translateY(-0.4rem);
  path {
    stroke-width: 2px;
    stroke: $purple-medium-2;
  }

  &:hover {
    path {
      fill: $purple-medium-2;
    }
  }
}

.appFavorite {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  overflow: visible;
  transform: translateY(-0.4rem);

  .icon {
    font-size: 5rem;
    color: $purple-medium-2;
  }
  path {
    stroke-width: 2px;
    stroke: $purple-medium-2;
  }

  &:hover {
    path {
      fill: $purple-medium-2;
    }
  }
}

.title {
  display: grid;
  justify-content: center;
  margin: 1.5rem 0 0.9rem;
  //   align-items: center;
  font-size: 2.6rem;
  font-weight: 500;
  color: $grey-dark-2;
}

.content {
  //   display: grid;
  margin: 0 auto;
  font-size: 1.5rem;
  color: $grey-dark-1;
  width: 24rem;
  text-align: center;

  a {
    color: $grey-dark-1;
    text-decoration: underline;
    cursor: pointer;
  }
}
