@import "../../../../scss/app.scss";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .map {
    width: 100%;
    height: 100%;
    flex: 1;
    cursor: grab;
    background-color: white;

    .leaflet {
      width: 100%;
      height: 100%;
    }

    &:active {
      cursor: grabbing;
    }
  }
}
