@import "../../../../../../scss/index.scss";

.blurMsg {
  // position: absolute;
  // z-index: 150;
  background-color: #ffffffed;
  width: 80%;
  border-radius: 15px;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  padding: 4rem;
  box-shadow: 1px 1px 5px $purple-light-2;

  @media only screen and (max-width: $small) {
    padding: 3rem;
    height: 75%;
  }

  .icon {
    display: grid;
    justify-content: center;
    align-items: center;

    ion-icon {
      width: 6rem;
      height: 6rem;
      color: $purple-medium-2;

      @media only screen and (max-width: $small) {
        width: 4rem;
        height: 4rem;
      }
    }
  }

  .msg {
    color: $purple-medium-2;
    text-align: center;
    font-weight: 600;

    @media only screen and (max-width: $small) {
      font-size: 1.5rem;
    }

    span {
      cursor: pointer;
      color: $purple-medium-2;
      text-decoration: underline;
    }
  }
}
