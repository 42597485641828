@import "../../../../../../scss/index.scss";

.header {
  display: grid;
  grid-template-columns: 80% 20%;
  margin: 2rem 0;
  padding: 0 3rem;

  .street {
    font-size: 3rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .address {
    color: $grey-dark-1;
    font-size: 1.5rem;
    margin-top: 1rem;

    span {
      color: $grey-dark-1;
      // font-weight: 600;
    }
    .path {
      font-weight: 400;
    }
    .activePath {
      transition: all 0.3s ease-in;

      &:hover {
        color: $purple-medium-2;
        cursor: pointer;
      }
    }
  }

  .img {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    align-self: center;
  }
}
