@import "../../../../../scss/app.scss";

.item {
  --inner-padding-end: 0;
}
.label {
  font-size: 1.5rem !important;
  color: $grey-dark-1 !important;
}

.selected {
  color: #7158ad !important;
}

.checkbox {
  --background-checked: #7158ad;
  --border-color: #707070;
  --border-color-checked: #7158ad;
  --checkmark-color: white;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  .icon {
    font-size: 2rem;
    color: $grey-dark-2;
    opacity: 1;
    z-index: 100;
    padding-left: 5rem;
    padding-right: 1rem;
  }
}
