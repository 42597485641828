@import "../../scss/index.scss";

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($grey-dark-2, 0.7);
  z-index: 3500;

  .container {
    padding: 4rem;
    border-radius: $radius-lg;
    background-color: white;
    @include center-horizontally;
    @include hide-scrollbar;
    width: auto;
    height: auto;
    max-height: 90%;
    margin: auto auto;
    overflow: auto;

    .closeIcon {
      position: absolute;
      top: 3rem;
      right: 3rem;
      cursor: pointer;
      ion-icon {
        height: 2.82rem;
        width: 2.82rem;
        color: $grey-dark-1;
      }
    }
  }

  .overflow {
    overflow-y: scroll;
  }
}

@media screen and (max-width: $small) {
  .wrapper {
    .container {
      border-radius: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}
