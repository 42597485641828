@import "./scss/index.scss";

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.alert-wrapper {
  --button-color: #971e49 !important;
  background: white !important;
  --button-color-hover: #79193b !important;
}

// .modal-wrapper {
//   height: 100% !important;
//   width: 100% !important;
// }

.alert-button {
  color: $purple-medium-2 !important;
}
.select-interface-option {
  .alert-radio-label {
    color: $grey-dark-2 !important;
  }
}
.alert-input.sc-ion-alert-ios {
  margin-bottom: 1.2rem;
}
alert-message.sc-ion-alert-ios,
.alert-input-group.sc-ion-alert-ios {
  padding-bottom: 1rem !important;
}
.select-interface-option[aria-checked="true"] {
  .alert-radio-icon {
    border-color: $purple-medium-2 !important;
  }

  .alert-radio-inner {
    border-color: $purple-medium-2 !important;
    background-color: $purple-medium-2 !important;
  }
  .sc-ion-alert-ios {
    background-color: transparent !important;
  }
  .alert-radio-label {
    color: $purple-medium-2 !important;
    font-weight: 600 !important;
  }
}

ion-tab-bar {
  background-color: $purple-dark-2;
}

ion-datetime {
  --placeholder-color: #7158ad;
}

ion-datetime::part(text) {
  color: #7158ad !important;
}

[aria-checked="true"].sc-ion-alert-ios .alert-checkbox-icon.sc-ion-alert-ios {
  background-color: #7158ad !important;
  border-color: #7158ad !important;
}
[aria-checked="true"].sc-ion-alert-md .alert-checkbox-icon.sc-ion-alert-md {
  background-color: #7158ad !important;
  border-color: #7158ad !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// .tourItem {
//   --border-width: 0 0 0.1rem 0;
// }

.connection {
  display: none;
  --background: #ff5500;
  --color: white;
  font-size: 1.1rem;
  --button-color: white;
}

.alert {
  border-radius: 15px !important;
}

@media only screen and (min-width: $medium) {
  .alert-button-group.sc-ion-alert-md {
    justify-content: center !important;
  }
  .alert-wrapper {
    border-radius: 15px !important;
  }
}

.fullScreenModal {
  --height: 100%;
  --width: 100%;
}
