@import "../../../scss/index.scss";

.container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5rem;
  padding-bottom: 4rem;
  .item {
    display: flex;
    flex-direction: column;
    .title {
      font-size: 2.2rem;
      color: $purple-medium-2;
      font-weight: 600;
      padding: 1rem 0;
      border-bottom: $input-border;
    }

    .search {
      font-size: 1.4rem;
      color: $grey-dark-2;
      font-weight: 500;
      margin-bottom: 1rem;

      &:first-of-type {
        margin-top: 1rem;
      }

      & a {
        transition: color 0.3s linear;
      }
      & a:hover {
        cursor: pointer;
        color: $purple-medium-2;
      }
    }
  }

  @media screen and (max-width: $large) {
    .item {
      .title {
        font-size: 1.8rem;
      }

      .search {
        font-size: 1.2rem;
      }
    }
  }
}
