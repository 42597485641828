@import "../../../../scss/index.scss";

.buttonsContainer {
  position: absolute;
  z-index: 5;
  top: 1.5rem;
  left: 1.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-grey;
  border-radius: $radius-lg;

  .line {
    position: absolute;
    width: 100%;
    z-index: 10;

    @include center-vertically;
    hr {
      border: 1px solid $line-color;
      width: 70%;
    }
  }

  .zoomIn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
    height: 4.2rem;
    width: 4rem;
    cursor: pointer;
  }

  .zoomOut {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom-left-radius: $radius-lg;
    border-bottom-right-radius: $radius-lg;
    height: 4.2rem;
    width: 4rem;
    cursor: pointer;
  }

  .disabled {
    .icon {
      color: $grey-light-2;
      cursor: not-allowed;
    }
  }

  .icon {
    font-size: 2.2rem;
  }
}
