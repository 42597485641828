@import "../../../scss/index.scss";

.requestForm {
  width: 55.6rem;

  .error {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: $orange-medium;
  }
  .title {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .address {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    margin: 1rem 0;
  }

  .subtitle {
    display: grid;
    justify-content: center;
    font-size: 1.5rem;
    color: $grey-dark-1;
    margin-bottom: 4rem;
    text-align: center;
  }
  .sectionTitle {
    color: $grey-dark-2;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 3rem 0 1rem;
  }

  .row {
    display: flex;
    flex-direction: column;

    .inputLabel {
      font-size: 1.2rem;
      font-weight: 500;
      color: $purple-medium-2;
    }
  }
  .inputRow {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 2rem;
    margin-bottom: 2rem;
  }

  .options {
    display: grid;
    grid-template-columns: auto auto;
    font-size: 1.5rem;
    color: $grey-dark-2;
  }

  .declaration {
    display: grid;
    margin: 2rem auto 0;
    width: 36rem;
    font-size: 1.2rem;
    text-align: center;
    color: $grey-dark-1;
  }

  .textarealabel {
    font-size: 1.2rem;
    position: relative;
    top: 1.2rem;
    left: 1.5rem;
    padding: 0 0.5rem;
    background: white;
    color: $purple-medium-2;
  }
  .textarea {
    width: 100%;
    height: 10rem;
    border: 1px solid $grey-light-2;
    border-radius: 5px;
    color: $grey-dark-2;
    font-size: 1.5rem;
    padding: 1rem;
    resize: none;
  }

  .inputs {
    display: flex;
    flex-direction: column;
  }
  .offerDate {
    border: $input-border;
    border-radius: $radius-sm;
    font-size: 1.5rem;
    color: $grey-dark-2;
    font-weight: 600;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    --padding-start: 0;
    margin-bottom: 0.75rem;
    .checkbox {
      margin-right: 0.5rem;
      --background-checked: #7158ad;
      --border-radius: 0.5rem;
      --size: 1.5rem;
      --border-width: 1px;
    }
    .label {
      color: $grey-dark-2;
      font-size: 1.5rem;
    }
  }

  .dateTitle {
    color: $grey-dark-2;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
  }

  .addTime {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 2rem;
    background-color: $grey-light-1;
    padding: 1rem 2rem;
    color: $grey-dark-1;
    border-radius: 5px;
    font-size: 1.5rem;
    cursor: pointer;
    border: 1px solid $grey-light-2;

    .addIcon {
      display: grid;
      align-items: center;

      ion-icon {
        height: 2rem;
        width: 2rem;
        color: $grey-dark-1;
      }
    }
  }
}
