@import "../../../../../scss/index.scss";

.agentDetails {
  height: 100%;
  margin: 2rem;

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
    margin-bottom: 2rem;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    .title {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .container {
    margin-top: 2rem;
    position: relative;

    .select {
      --padding-start: 1rem;
      --padding-top: 0.75rem;
      --padding-bottom: 0.75rem;
      font-size: 1.5rem;
      color: $grey-dark-2;
      border: $input-border;
      border-radius: $radius-sm;

      &::part(placeholder) {
        color: $grey-dark-1;
      }
      &::part(text) {
        font-weight: 500;
      }
    }

    .agents {
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 1rem;
      }
    }
  }

  .textarea {
    margin-bottom: 1.5rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 21rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .button {
    height: 5rem;
    --background: #e9e3f2;
    --background-activated: #7158ad;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: 0;
    text-transform: initial;
  }

  .whitespaces {
    height: 1rem;
  }
}
