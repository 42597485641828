@import "../../../../scss/index.scss";

.showingDetails {
  margin: 2rem;

  .title {
    font-size: 2.6rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .address {
    margin: 1rem 0 2rem;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    color: $grey-dark-1;
    border-bottom-width: 0.1rem;
    border-bottom-color: $grey-light-2;
    border-bottom-style: solid;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    .bold {
      font-weight: 600;
    }
  }

  .details {
    div {
      display: flex;
      justify-content: space-between;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;
      border-bottom-style: solid;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      font-size: 1.5rem;
      color: $grey-dark-2;

      .bold {
        font-weight: 600;
        margin-right: 1rem;
      }
    }
  }

  .agentTitle {
    font-size: 1.8rem;
    font-weight: 600;
    border-bottom-width: 0.1rem;
    border-bottom-color: $grey-light-2;
    border-bottom-style: solid;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 4rem;
  }

  .agentNotes {
    font-size: 1.5rem;
    color: $grey-dark-1;
  }

  .ratingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0 2rem;
    padding: 2rem;
    background-color: $grey-light-1;
    border-radius: 10px;

    .label {
      display: flex;
      align-items: center;
      margin-right: 2rem;
      font-weight: 500;
      font-size: 1.8rem;
    }
  }
}
