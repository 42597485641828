@import "../../scss/index.scss";

.schoolDetails {
  display: flex;
  flex-direction: column;
  grid-row: 2/-1;
  grid-column: 2/-1;
  width: 100%;
  overflow-y: scroll;
  @include show-scrollbar;

  .container {
    display: flex;
    flex: 1;
    padding: 3rem;
  }

  .footer {
    padding: 0;
  }
}
