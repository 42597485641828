@import "../../../../../../../../scss/index.scss";

.container {
  //padding: 2rem 4rem 2rem 2rem;
  padding: 2rem;
  border: 1px solid $purple-medium-2;
  border-top: none;
  border-bottom-left-radius: $radius-sm;
  border-bottom-right-radius: $radius-sm;

  .spinner {
    color: $purple-medium-2;
    width: 100%;
    transform: scale(1.8);
    margin: 1.5rem 0;
  }

  .noSchool {
    font-size: 1.8rem;
    color: $grey-dark-2;
    font-weight: 500;
    @media only screen and (max-width: $medium) {
      font-size: 1.5rem;
    }
  }
  .schools {
    &:not(:first-of-type) {
      margin-top: 1.5rem;
    }
  }

  .header {
    border-bottom: 1px solid $grey-light-2;
    padding-bottom: 1.5rem;
    .title {
      font-size: 2.2rem;
      color: $purple-medium-2;
      font-weight: 500;
      padding-bottom: 1rem;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }
    }

    .note {
      font-size: 1.8rem;
      color: $grey-dark-1;

      .link {
        color: $grey-dark-1;
      }

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;

    .item {
      display: flex;
      justify-content: space-between;
      &:not(:first-of-type) {
        padding-top: 1rem;
      }
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey-light-2;

      @media only screen and (max-width: $medium) {
        display: flex;
        flex-direction: column;
        justify-content: unset;
      }

      .left {
        display: flex;
        flex-direction: column;

        .name {
          display: flex;
          align-items: center;
          font-size: 1.8rem;
          color: $grey-dark-1;
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;

          span {
            margin-right: 1rem;
          }

          @media only screen and (max-width: $medium) {
            font-size: 1.5rem;
            justify-content: space-between;
          }
        }

        .info {
          font-size: 1.6rem;
          margin-top: 0.5rem;
          font-weight: 300;
          color: $grey-dark-1;

          @media only screen and (max-width: $medium) {
            font-size: 1.5rem;
          }
        }
        .programs {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          font-weight: 300;
          color: $grey-dark-1;
          display: flex;
          flex-direction: column;

          .title {
            font-weight: 500;
          }
          .text {
            font-style: italic;
          }

          @media only screen and (max-width: $medium) {
            font-size: 1.5rem;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        .rate {
          align-self: flex-end;
          font-size: 1.8rem;
          color: $grey-dark-1;
          font-weight: 300;

          @media only screen and (max-width: $medium) {
            align-self: flex-start;
            font-size: 1.5rem;
          }

          .score {
            color: $orange-medium2;
            font-weight: 600;
          }

          .outOf {
            padding-left: 0.5rem;
          }
        }

        .boundary {
          margin-top: 0.5rem;
          font-size: 1.6rem;
          font-weight: 300;
          font-style: italic;
          color: $grey-dark-1;

          @media only screen and (max-width: $medium) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
