@import "../../../scss/index.scss";

.container {
  width: 58rem;

  .header {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subtitle {
    display: flex;
    justify-content: center;
    width: 100%;
    color: $grey-dark-1;
    font-size: 1.5rem;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  .content {
    margin: 2rem 0 2rem;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 2rem;

    .error {
      padding-top: 1.5rem;
      font-size: 1.3rem;
      color: $orange-medium;
    }

    .schools {
      display: flex;
      flex-direction: column;
      .school {
        display: flex;
        align-items: center;
        .schoolIcon {
          display: flex;
          align-items: center;
          background-color: $green-light;
          border-radius: $radius-sm;
          color: $green-dark;
          padding: 0.3rem 0.75rem;
          margin-right: 1rem;

          .icon {
            font-size: 2rem;
          }

          .title {
            color: $green-dark;
            font-size: 1.5rem;
            font-size: 400;
            margin-left: 0.75rem;
          }
        }

        .name {
          color: $grey-dark-1;
          font-size: 1.5rem;
        }

        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }
    }

    .labelContainer {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }
    .label {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
      margin-right: 1rem;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      color: $grey-dark-2;
    }

    .filter {
      font-size: 1.5rem;
      font-weight: 300;
      color: $grey-dark-1;
      margin: 0 0.5rem;
    }

    .notify {
      color: $grey-dark-1;

      span {
        color: $grey-dark-2;
        font-weight: 600;
      }
    }
  }

  .note {
    width: 34rem;
    margin: 1rem auto 0;
    color: $grey-dark-1;
    font-size: 1.5rem;
    text-align: center;

    a {
      color: $grey-dark-1;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
