@import "../../../../../scss/index.scss";

.listingFooter {
  position: fixed;
  bottom: 0;
  z-index: 500;
  width: 100%;
  background-color: $purple-light-1;
  height: 7rem;
  border-top-color: $purple-light-2;
  border-top-width: 0.1rem;
  border-top-style: solid;

  .icon {
    display: flex;
    justify-content: center;
    align-self: center;

    ion-icon {
      width: 3rem;
      height: 3rem;
      color: $purple-medium-2 !important;

      @media screen and (max-width: 350px) {
        width: 2.3rem;
        height: 2.3rem;
      }
    }
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --color: #7158ad;
    --border-color: #7158ad;
    --border-style: solid;
    --border-width: 1px;
    text-transform: initial;
    --box-shadow: 0;
    height: 4rem;

    @media screen and (max-width: 350px) {
      width: 100%;
      font-size: 1.2rem;
    }
  }
}
