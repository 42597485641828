@import "../../../../../../scss/index.scss";

.container {
  .onMarket {
    margin-top: 1rem;
    color: $grey-dark-2;
    span {
    }
  }
  .priceContainer {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    .listPrice {
      color: $grey-dark-1;
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 0.1rem solid $purple-light-3;
      border-bottom: 0.1rem solid $purple-light-3;
      padding-bottom: 1rem;
    }
    .asking,
    .increased,
    .decreased {
      display: flex;
      align-items: center;
    }

    .increased {
      color: $green-dark;
      padding-top: 1rem;
      border-top: 0.1rem solid $purple-light-3;
    }

    .decreased {
      color: $orange-dark;
      padding-top: 1rem;
      border-top: 0.1rem solid $purple-light-3;
    }

    .value {
      margin-left: 0.2rem;
    }

    .text {
      font-style: italic;
      color: $grey-dark-1;
      margin-left: 0.3rem;
    }
  }
}
