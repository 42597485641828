@import "../../../../../scss/index.scss";

.buttons {
  width: 100%;
  --background: #f4f4f7;
  --background-activated: #f4f4f7;
  --color: #707070;
  height: 5rem;
  text-transform: inherit;

  .apple {
    color: #000;
  }
}
