@import "../../../../scss/index.scss";

.card {
  font-size: 1.5rem;
  .header {
    display: flex;
    height: 100%;
    flex-direction: column;
    border-bottom: 2px solid rgba($grey-light-2, 0.6);
    padding-bottom: 0.5rem;

    .link {
      color: $grey-dark-2;
      font-weight: 600;
      text-decoration: underline;
    }
    .name {
      font-size: 1.5rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    .info {
      display: flex;
      font-size: 1.2rem;
      font-weight: 300;
      color: $grey-dark-1;

      span {
        &:not(:last-of-type)::after {
          content: "|";
          padding: 0 0.5rem;
          color: $grey-light-2;
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    .row {
      display: flex;
      margin: 0.5rem 0;
    }

    .scoreContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .score {
        font-size: 3rem;
        font-weight: 600;
        color: $orange-medium2;
      }
      .scoreOut {
        color: $grey-dark-1;
        margin-top: -1rem;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      margin-left: 1.5rem;

      .title {
        font-size: 1.2rem;
        font-weight: 600;
        color: $grey-dark-1;
      }
      .year {
        color: $grey-dark-1;
      }
    }

    .toggleContainer {
      display: flex;
      align-items: center;
      padding: 1rem;
      font-size: 1.2rem;
      color: $grey-dark-2;
      background-color: $grey-light-2;
      border-radius: $radius-md;
      width: 100%;
      .label {
        order: 2;
        width: 75%;
        margin-left: 1rem;
      }
    }

    .noBoundaryContainer {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 1rem;
      font-size: 1.2rem;
      color: $grey-dark-2;
      background-color: $orange-light;
      border-radius: $radius-md;
      .label {
        color: $orange-dark;
        width: 100%;
      }
    }

    .note {
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      span {
        text-align: center;

        a {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
