@import "../../../../../scss/index.scss";

.recordingDetails {
  height: 100%;

  .subtitle {
    margin: 2rem 2rem 4rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
  }
}
