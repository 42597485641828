@import "../../../scss/index.scss";

.schools {
  margin: 2rem;
  color: $grey-dark-2;
  font-size: 1.5rem;

  .title {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
  }

  .body {
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $grey-dark-2;

    .email {
      text-decoration: underline;
    }

    a {
      text-decoration: underline;
    }
  }
}
