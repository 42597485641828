@import "../../../scss/index.scss";

.signup {
  margin: 0 2rem;

  .inputs {
    margin-top: 2rem;

    ion-row {
      margin: 2rem 0;

      ion-col {
        div {
          input {
            height: 5rem;
          }
        }
      }
    }
  }

  .error {
    padding-top: 1rem;
    margin-left: 1rem;
    font-size: 1.4rem;
    color: $orange-medium;
  }

  .consent {
    display: flex;
    color: $grey-dark-2;
    display: grid;

    ion-item {
      --border-style: none;
      --padding-start: 0;

      ion-checkbox {
        --background-checked: #7158ad;
        --border-color-checked: #7158ad;
        display: grid;
        margin: 0;
        margin-right: 1rem;
        height: 2rem;
        width: 2rem;
      }
      ion-label {
        font-size: 1.2rem;
        white-space: unset;
        color: $grey-dark-2;
      }
    }

    .icon {
      display: flex;
      align-self: center;
      width: 2rem;
      height: 2rem;
      color: $grey-dark-1;
    }
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    margin: 2rem auto;
    width: 100%;
    height: 5rem;
  }

  .title {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    .spinner {
      transform: scale(0.8);
      margin-right: 1rem;
      --color: #7158ad;
    }
  }

  .login {
    display: grid;
    justify-content: center;
    color: $grey-dark-2;
    font-size: 1.5rem;

    a {
      text-decoration: underline;
      color: $purple-medium-2;
      margin: 0 auto;
    }
  }

  .agree {
    margin: 1rem 0;
    color: $grey-dark-1;
    font-size: 1.2rem;
    text-align: center;

    a {
      text-decoration: underline;
      color: $grey-dark-1;
    }
  }
}

.popoverContent {
  margin: 0;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: $grey-dark-1;
}
