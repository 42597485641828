@import "../../../../../scss/index.scss";

.noteDetails {
  margin: 2rem 2rem 1rem;

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
  }
}
