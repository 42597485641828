@import "../../scss/index.scss";

.listingCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  transition: all 0.2s;

  .details {
    display: grid;
    row-gap: 0.2rem;
    width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // flex: 1;
    // height: 100%;
    cursor: pointer;

    .priceContainer {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem 0;

      .icons {
        display: flex;
      }

      .price {
        display: flex;
        flex-direction: column;

        .listPrice {
          font-size: 2.6rem;
          color: $grey-dark-2;
          font-weight: 600;
        }
        .priceDiff {
          display: flex;
          font-size: 1.5rem;
          color: $grey-dark-2;
          font-weight: 400;

          .asking,
          .increased,
          .decreased {
            display: flex;
            align-items: center;
          }

          .increased {
            color: $green-dark;
          }

          .decreased {
            color: $orange-dark;
          }

          .value {
            margin-left: 0.2rem;
            font-weight: 500;
          }

          .text {
            font-style: italic;
            color: $grey-dark-1;
            margin-left: 0.3rem;
          }
        }
      }
    }

    .addressContainer,
    .typeContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .address,
      .type {
        font-size: 1.5rem;
        font-weight: 400;
        color: $grey-dark-1;
        margin-left: 0.8rem;
      }
    }

    .rooms {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;

      // & span:first-of-type {
      //   margin-right: 0.8rem;
      // }

      .icon {
        margin-right: 0.8rem;
      }
      .bedIcon {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
        color: $grey-dark-2;
      }

      & span:not(:last-of-type)::after {
        content: "|";
        color: $line-color;
        padding: 0 0.8rem;
      }

      & span:last-of-type::after {
        content: "";
      }
    }

    .info {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;
      margin-top: 1.5rem;

      .dateContainer {
        margin-left: 1rem;
        display: flex;
        // .date {
        // }
        // .onMarket {
        //   &::before {
        //     content: "|";
        //     color: $line-color;
        //     padding: 0.8rem;
        //   }
        // }
      }
    }

    .mlsContainer {
      font-size: 1.2rem;
      color: $grey-dark-1;
      margin-top: 1.5rem;
    }
  }

  .icon {
    display: flex;
    width: 1.25rem;
    height: 1.65rem;
    fill: $grey-dark-2;
  }
}

.map {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 13rem;
  justify-content: center;

  .details {
    display: flex;
    flex-direction: column;
    width: 100%;

    .priceContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 1rem;

      .icons {
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .price {
        display: flex;
        align-items: center;

        .listPrice {
          font-size: 1.8rem;
          color: $grey-dark-2;
          font-weight: 600;
        }
        .priceDiff {
          margin-left: 1rem;
          display: flex;
          font-size: 1.5rem;
          color: $grey-dark-2;
          font-weight: 400;

          .increased {
            display: flex;
            align-items: center;
            color: $green-dark;
          }

          .decreased {
            display: flex;
            align-items: center;
            color: $orange-dark;
          }
        }
      }
    }

    .addressContainer,
    .typeContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .address,
      .type {
        font-size: 1.2rem;
        font-weight: 400;
        color: $grey-dark-1;
        margin-left: 0.8rem;
      }

      .icon {
        display: flex;
        align-items: center;
        width: 1.25rem;
        height: 1.65rem;
        fill: $grey-dark-2;
      }
    }

    .rooms {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 400;
      color: $grey-dark-1;
      margin-left: 0.8rem;

      & span {
        position: relative;
        padding-right: 0.8rem;
      }

      & span:not(:first-of-type) {
        padding-left: 0.8rem;
      }

      & span:not(:last-of-type)::after {
        content: "";
        height: 65%;
        width: 1px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $line-color;
      }
    }

    .info {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 400;
      color: $grey-dark-1;
      margin-top: 0.8rem;
      margin-left: 0.8rem;

      .dateContainer {
        margin-left: 1rem;
        // .date {
        // }
        .onMarket {
          display: none;
        }
      }
    }

    .mapMlsContainer {
      font-size: 1.2rem;
      color: $grey-dark-1;
      margin-top: 0.5rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    --color: #7158ad;
  }
}

ion-spinner {
  transform: scale(0.7);
}

.detailsBtn {
  display: flex;
  width: 10rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: $green-light;
  margin-top: 0.75rem;
  margin-left: 0.8rem;
  background-color: $green-dark;
  padding: 0.25rem 0;
  border-radius: $radius-md;
}

.webDetailsBtn {
  background-color: $purple-medium-2;
  cursor: pointer;
  color: white;
}
