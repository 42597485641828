@import "../../../scss/index.scss";

.shareListing {
  width: 64rem;

  .title {
    display: grid;
    justify-content: center;
    font-size: 3rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .info {
    width: 32rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
    display: grid;
    margin: 0 auto 4rem;
    text-align: center;
  }

  .btns {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-evenly;
    margin-bottom: 4rem;
  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin: 0 2rem;

      ion-icon {
        justify-self: center;
        width: 2.6rem;
        height: 2.6rem;
      }

      span {
        font-size: 1.5rem;
        color: $grey-dark-1;
      }
    }
  }
}

.container {
  width: 55.4rem;

  .pic {
    display: grid;
    justify-self: center;
    justify-content: center;
    margin: 2rem auto;
    background-color: $purple-light-2;
    width: 8rem;
    height: 8rem;
    border-radius: 50px;

    ion-icon {
      height: 4.8rem;
      width: 4.8rem;
      border: 0.2rem solid $purple-medium-2;
      color: $purple-medium-2;
      border-radius: $radius-lg;
      display: grid;
      justify-self: center;
      align-self: center;
    }
  }
  .title {
    display: grid;
    justify-content: center;
    margin-top: 1rem;
    color: $grey-dark-2;
    font-size: 2.6rem;
    font-weight: 600;
  }
  .btn {
    margin-top: 2rem;
  }
}
