@import "../../scss/index.scss";

.container {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 3fr;
  overflow-y: scroll;
  @include show-scrollbar;

  .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-row: 2/-1;
    grid-column: 1/-1;

    .userAccount {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      .sections {
        // display: flex;
        // justify-content: space-around;
        display: grid;
        grid-template-columns: auto;
        margin: 0 4rem 4rem;
        height: 100%;
        // margin-bottom: 4rem;
        @include show-scrollbar;
        @media screen and (min-width: $large) {
          // flex-direction: column;
          // justify-content: flex-start;
          grid-template-columns: auto auto;
          column-gap: 4rem;
        }
      }
    }
  }
}
