@import "../../../../../scss/app.scss";
.label {
  font-size: 1.5rem !important;
  color: $grey-dark-1 !important;
}

.selected {
  color: #7158ad !important;
}

.checkbox {
  --background-checked: #7158ad;
  --border-color: #707070;
  --border-color-checked: #7158ad;
  --checkmark-color: white;
}
