@import "../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  .title {
    font-size: 2.6rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .note {
    font-size: 1.5rem;
    color: $grey-dark-1;
    margin-top: 1.5rem;
    padding: 0 6rem;
    text-align: center;
  }

  .btns {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
  }
}
