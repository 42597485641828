@import "../../scss/index.scss";
@import "../../scss/variables.scss";

.typesFilter {
  div {
    .checkbox {
      display: inline-flex;
      cursor: pointer;
      position: relative;
      align-items: center;
      margin: 0.8rem 0 0.8rem 1.4rem;

      .item {
        color: $grey-dark-2;
        margin-left: 0.8rem;
        font-size: 1.5rem;
      }

      input {
        display: grid;
        align-self: center;
        height: 1.6rem;
        width: 1.6rem;
        cursor: pointer;
      }
    }

    ion-checkbox {
      --background-checked: #7158ad;
      --border-color-checked: #7158ad;
    }
  }

  // .checkbox > span {
  //   color: $grey-dark-1;
  // }

  // .checkbox > input:checked {
  //   content: "\2713";
  //   color: white;
  //   background-color: $purple-medium-2;
  //   height: 1.6rem;
  //   width: 1.6rem;
  //   appearance: none;
  //   border-radius: 3px;
  //   outline: none;
  //   cursor: pointer;
  // }

  // .checkbox > input:checked + span::before {
  //   content: "\2713";
  //   display: block;
  //   text-align: center;
  //   color: white;
  //   position: absolute;
  //   left: 0.6rem;
  //   top: 0;
  // }
}
