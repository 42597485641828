@import "../../../../scss/app.scss";

.card {
  background-color: white;
  border-radius: $radius-md;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  height: 100%;

  .img {
    display: flex;
    justify-content: center;
    width: 40%;
    height: 15rem;
    margin: 3rem 0 1rem 0;
  }

  .title {
    margin-top: 2rem;
    color: $grey-dark-2;
    font-size: 2.2rem;
    font-weight: 600;
  }

  .text {
    margin-top: 2rem;
    font-size: 1.8rem;
    color: $grey-dark-1;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1rem;
  }

  .actionBtn {
    height: 5rem;
    width: 100%;
    --border-radius: 25px;
    border-radius: 25px;
    --background: #e0faff !important;
    --background-activated: #007b94 !important;
    --background-focused: #007b94 !important;
    --background-hover: #007b94 !important;
    --background-hover-opacity: 1 !important;
    background-color: #e0faff;
    --color: #007b94 !important;
    --color-activated: #e0faff !important;
    --color-focused: #e0faff !important;
    --color-hover: #e0faff !important;
    --border-width: 0.1rem;
    --border-color: #007b94 !important;
    border-color: #007b94;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-transform: initial;
    @media screen and (min-width: $medium) {
      font-size: 1.8rem;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}
