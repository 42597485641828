@import "../../../../../scss/app.scss";
.footer {
  position: sticky;
  bottom: 0;
  left: 0;

  .icons {
    width: 100%;
    display: flex;
    padding: 1.5rem 2rem;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    column-gap: 1rem;
    background-color: $purple-light-1;
    border-top: 0.1rem solid $grey-light-2;

    ion-icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: 0.5rem;
    }
    .icon {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      margin-right: 1rem;
      text-align: center;

      span {
        text-align: center;
        width: 70%;
        line-height: 1.8rem;
      }
    }

    .camera {
      border: 0.1rem solid $blue-dark;
      color: $blue-dark;
      background-color: $blue-light;
    }

    .note {
      border: 0.1rem solid $green-dark;
      color: $green-dark;
      background-color: $green-light;
    }

    .mic {
      border: 0.1rem solid $purple-medium-2;
      color: $purple-medium-2;
      background-color: $purple-light-2;
    }
    .disabled {
      border: 0.1rem solid $grey-light-2;
      color: $grey-light-2;
      background-color: $grey-light-1;
    }
  }
}
.disabledNoteIcon {
  opacity: 0.3;
}
