@import "../../../scss/index.scss";

.item {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  color: $grey-dark-2;

  .radioContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $grey-dark-2;

    .radio {
      background-color: red;
    }

    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0.5rem 0;

      .radio {
        position: relative;
        border: 1px solid $grey-dark-1;
        border-radius: 50%;
        width: 1.6rem;
        height: 1.6rem;
        input {
          opacity: 0;
          width: 0;
          height: 0;
          display: flex;
          align-items: center;

          &:checked + .radioControl {
            background-color: $purple-medium-2;
            border-color: $purple-medium-2;
            width: 1.1rem;
            height: 1.1rem;
          }
        }

        .radioControl {
          display: block;
          width: 1.1rem;
          height: 1.1rem;
          border-radius: 50%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .label {
        margin-left: 0.8rem;
        transform: translateY(0.5px);
        text-transform: capitalize;
      }

      .selectedLabel {
        font-weight: 600;
        color: $green-dark;
      }
    }
  }

  .branches {
    margin-left: 4rem;
    border-left: 2px solid $purple-light-2;

    .branch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;

      .checkbox {
        display: inline-flex;
        cursor: pointer;
        position: relative;

        .item {
          display: block;
          flex-shrink: 10;
          color: $grey-dark-2;
          margin-left: 0.5rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0.5rem 0;
          margin-left: 0.5rem;
        }

        input {
          display: grid;
          align-self: center;
          height: 1.4rem;
          width: 1.4rem;
          cursor: pointer;
        }
      }

      .checkbox > span {
        color: $grey-dark-1;
      }

      .checkbox > input:checked {
        content: "\2713";
        color: white;
        background-color: $purple-medium-2;
        height: 1.4rem;
        width: 1.4rem;
        appearance: none;
        border-radius: 3px;
        outline: none;
        cursor: pointer;
      }

      .checkbox > input:checked + span::before {
        content: "\2713";
        display: block;
        text-align: center;
        color: white;
        position: absolute;
        left: 0.6rem;
        top: 5px;
      }
    }

    .subBranches {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      margin-right: 2rem;
      border-left: 2px solid $purple-light-2;
      padding-left: 1rem;
    }
  }

  .count {
    font-weight: 600;
    color: $grey-dark-1;
    margin-left: 0.5rem;
    color: $purple-medium-2;
  }

  .icon {
    cursor: pointer;
    transition: all 0.2s;
    height: 1.7rem;
    width: 1.7rem;
    stroke-width: 4rem;
    stroke: $grey-dark-2;
    margin-left: 0.5rem;
    align-self: center;
  }

  .iconReversed {
    transform: rotate(180deg);
  }
}
