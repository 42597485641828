@import "../../scss/index.scss";

.filtersContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid $line-color;
  box-shadow: $box-shadow;
  overflow-y: scroll;
  @include hide-scrollbar;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 10rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: white;
    padding: 0 2.5rem;
    border-bottom: 1px solid $line-color;
    font-size: 2.4rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .segments {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid $line-color;
    background-color: white;
    .segment {
      border: 1px solid #7158ad;
      --background: transparent;

      ion-segment-button {
        --color: #7158ad;
        --color-checked: #7158ad;
        font-size: 1.5rem;
        --indicator-color: #e9e3f2;
        --indicator-box-shadow: none;
        --border-radius: 0;
        --border-width: 1px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        &:first-of-type {
          border-right: 1px solid #7158ad;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
    left: 0;
    height: 7rem;
    min-height: 7rem;
    background-color: white;
    padding: 0 2.5rem;
    border-top: 1px solid $line-color;
    margin-top: auto;
    margin-bottom: 0;
    z-index: 10;
  }

  .filters {
    position: relative;
    border-right: 2px solid transparent;

    .active {
      position: absolute;
      @include center-vertically;
      right: 0;
      margin-right: 5rem;
      font-size: 1.5rem;
      color: $purple-medium-2;
      text-align: left;
      font-weight: 600;
      cursor: pointer;
    }
    .item {
      padding-left: 1rem;
      border-bottom: 1px solid $line-color;

      .keywords {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 1.5rem;

        .label {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
  .activeFilter {
    //border-right: 2px solid $purple-medium-2;
  }
}
.keywordInput {
  border: 1px solid $grey-light-2;
  border-radius: $radius-sm;
  height: 4rem;
  font-size: 1.5rem;
  color: $purple-dark-1;
  --padding-start: 1rem;
  --padding-end: 1rem;
}

.tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  height: auto;

  .chipContainer {
    background-color: $purple-light-3;
    border-radius: $radius-sm;
    flex: 1;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    .chip {
      padding: 0 0.5rem;
      --background: transparent;
      --color: #7158ad;
      display: flex;

      .chipLabel {
        flex: 1;
      }
      .chipIcon {
        justify-self: flex-end;
      }
    }
  }
}

@media screen and (max-width: $large) {
  .filtersContainer {
    width: 200%;
  }
}

@media screen and (max-width: $small) {
  .filtersContainer {
    grid-row: 1/-1;
    grid-column: 1/-1;
    width: 85%;
    height: 100%;
    z-index: 1000;
    box-shadow: none;
    overflow-x: hidden;

    .header {
      min-height: 4rem;
      height: 4rem;
    }

    .footer {
      display: none;
    }
  }
}
