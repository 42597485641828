@import "../../../../../scss/index.scss";

.blurMsg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 20%;
  left: 10%;
}
.listingCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 32rem;

  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  filter: blur(4px);

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    height: 100%;

    .priceContainer {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem 0;

      .icons {
        display: flex;
      }

      .price {
        display: flex;
        flex-direction: column;

        .listPrice {
          font-size: 2.6rem;
          color: $grey-dark-2;
          font-weight: 600;
        }
      }
    }

    .addressContainer,
    .typeContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .address,
      .type {
        font-size: 1.5rem;
        font-weight: 400;
        color: $grey-dark-1;
        margin-left: 0.8rem;
      }
    }

    .rooms {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;

      & span:first-of-type {
        margin-right: 0.8rem;
      }

      & span:not(:first-of-type)::after {
        content: "|";
        color: $line-color;
        padding: 0 0.8rem;
      }

      & span:last-of-type::after {
        content: "";
      }
    }
    .info {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;
      margin-top: 1.5rem;

      .dateContainer {
        margin-left: 1rem;
        display: flex;
        .onMarket {
          &::before {
            content: "|";
            color: $line-color;
            padding: 0.8rem;
          }
        }
      }
    }
  }
  .icon {
    display: flex;
    width: 1.25rem;
    height: 1.65rem;
    fill: $grey-dark-2;
  }
}
