@import "../../../../scss/index.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.4rem;

  .item {
    padding: 0.5rem 1.2rem;
    border: $input-border;
    border-radius: $radius-sm;
    background-color: white;
    margin: 0 0.75rem;
    transition: all 0.2s ease-in;

    &:last-of-type {
      margin-right: 1.5rem;
    }
    &:first-of-type {
      margin-left: 1.5rem;
    }

    &:hover {
      cursor: pointer;
      background-color: $purple-medium-2;
      color: white;
    }
  }

  .current {
    background-color: $purple-medium-2;
    color: white;
  }

  .icon {
    font-size: 1.8rem;
    transition: all 0.2s ease-in;
    &:hover {
      cursor: pointer;
      color: $purple-medium-2;
      text-indent: -9999px;
    }
  }

  .disabled {
    color: $grey-light-2;
    &:hover {
      pointer-events: none;
      cursor: default;
      color: $grey-light-2;
    }
  }
}
