@import "../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55rem;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.6rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .content {
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;

    .subtitle {
      font-size: 1.8rem;
      font-weight: 600;
    }

    .firstRow {
      display: grid;
      grid-template-rows: auto auto;
      row-gap: 1.4rem;
      margin-bottom: 2.5rem;
    }

    .secondRow {
      display: grid;
      grid-template-rows: auto auto auto;
      row-gap: 1.4rem;
    }
  }

  .error {
    font-size: 1.5rem;
    color: $orange-medium;
  }
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }
}
