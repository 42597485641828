@import "../../../../../../scss/app.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  .searchbar {
    margin-top: 1rem;
    padding: 0 1.5rem;
    .input {
      --border-radius: 20px;
      --cancel-button-color: #363636;
    }
  }

  .results {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;

    .result {
      position: relative;
      padding-top: 5rem;

      .title {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        --padding-top: 0;
        z-index: 10;

        ion-label {
          color: $grey-dark-1;
        }
      }
    }

    ion-loading {
      --spinner-color: #7158ad !important;
      --background: #f4f4f7;
    }

    .noResult {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      z-index: 100;
      .icon {
        color: $grey-dark-1;
      }
      .label {
        font-size: 1.5rem;
        margin-left: 1rem;
        color: $grey-dark-1;
      }
    }
  }
}
