@import "../../../../../scss/index.scss";

.photoDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  .subtitle {
    margin: 2rem 2rem 1rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
  }
}
