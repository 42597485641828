@import "../../../scss/app.scss";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    color: $grey-dark-2;
    font-size: 1.8rem;
  }

  .header {
    padding: 0 1.5rem;
    margin: 1rem 0;
  }

  .overview {
    display: flex;
    flex-direction: column;
    padding: 0 1.5rem;
    margin-top: 1.5rem;

    .title {
      border-bottom: 1px solid $grey-light-2;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .appOverview {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .item {
        margin: 0 0.5rem;
        padding: 0.5rem 0;
        font-size: 1.5rem;
        color: $grey-dark-2;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-of-type) {
          border-bottom: 1px solid $grey-light-2;
        }

        .label {
          font-weight: 500;
        }
        .value {
          font-weight: 600;
        }
      }
    }

    .icons {
      display: flex;

      .item {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;

        ion-icon {
          font-size: 1.6rem;
          color: $grey-dark-2;
        }

        span {
          margin-left: 0.5rem;
          font-size: 1.5rem;
          color: $grey-dark-2;
        }
      }
    }

    .summary {
      margin: 1rem 0;
      font-size: 1.4rem;
      columns: $grey-dark-2;
    }

    .info {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }

        ion-icon {
          font-size: 1.6rem;
          color: $grey-dark-2;
        }

        span {
          margin-left: 0.5rem;
          font-size: 1.4rem;
          color: $grey-dark-2;
          font-weight: 400;
        }
      }
    }
  }

  .ratings {
    margin: 0 1.5rem;
    margin-top: 1.5rem;
    padding-bottom: 4rem;

    .title {
      text-align: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey-light-2;
    }

    .webChartInfo {
      @media only screen and (max-width: $medium) {
        display: none;
      }
    }
    .chartInfo {
      margin: 1rem 0;
      font-size: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: $medium) {
        font-size: 1.4rem;
        align-items: flex-start;
      }

      .item {
        flex-grow: 1;
        display: flex;
        align-items: center;
        color: $grey-dark-2;
        width: 50%;
        @media only screen and (max-width: $medium) {
          width: 100%;
        }

        & span:first-of-type {
          display: inline-block;
          min-width: 4rem;
          max-width: 4rem;
          height: 1.2rem;
          margin-right: 1rem;
        }

        & span:last-of-type {
          overflow-x: hidden;
          text-overflow: ellipsis;
        }

        &:first-of-type {
          & span:first-of-type {
            background-color: $purple-medium-2;
          }
        }

        &:last-of-type {
          & span:first-of-type {
            background-color: $purple-dark-1;
          }
        }
      }
    }

    .rate {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media only screen and (max-width: $medium) {
        border-bottom: 1px solid $grey-light-2;

        &:last-of-type {
          border-bottom: none;
        }
      }

      @media only screen and (min-width: $medium) {
        .chartInfo {
          display: none;
        }
      }

      .info {
        font-size: 1.5rem;
        color: $grey-dark-2;

        .link {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      @media only screen and (max-width: $medium) {
        flex-direction: column;
        align-items: center;

        .info {
          text-align: center;
          padding-bottom: 1rem;
        }
      }
    }
  }
  .map {
    padding: 0 1.5rem;
    width: 100%;
    max-height: 30rem;
    flex: 1;
    cursor: grab;

    .leaflet {
      border-radius: $radius-normal;
      width: 100%;
      height: 25rem;
    }

    &:active {
      cursor: grabbing;
    }

    .footer {
      margin-top: 1rem;
      text-align: center;
      font-size: 1.1rem;
      color: $grey-dark-2;
    }
  }
  .footer {
    margin: 1rem 0;
    padding: 0 1.5rem 8rem 1.5rem;
    text-align: center;
    font-size: 1.2rem;
    color: $grey-dark-2;
  }
}

.webContainer {
  .map {
    .footer {
      font-size: 1.5rem;
    }
  }
  .title {
    font-size: 2.5rem;
  }
  .ratings {
    .rate {
      width: 100%;
      padding: 1.5rem 0;
      justify-content: space-evenly;

      .info {
        max-width: 60rem;
        font-size: 2rem;
        color: $grey-dark-2;
        font-weight: 500;

        .link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .rates {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      //grid-template-columns: 1fr 1fr 1fr;

      .rate {
        width: 100%;
        flex: 1 0 21%;
        padding: 1.5rem 0;
        justify-content: space-evenly;

        @media only screen and (max-width: $large) {
          flex: 1 0 50%;
        }
      }
    }
  }
  .chartInfo {
    .item {
      justify-content: center;
      max-width: 40rem;
      span {
        flex-grow: 1;
      }
    }
  }
  .footer {
    font-size: 1.5rem;
    u {
      &:hover {
        cursor: pointer;
      }
    }
  }

  a {
    &:hover {
      cursor: pointer;
    }
  }
}
