@import "../../../../../../../../scss/index.scss";

.rooms {
  display: grid;
  margin: 0 auto;
  border: $cluster-border;
  border-top-style: none;
  border-radius: 0px 0px 5px 5px;

  @media only screen and (max-width: $large) {
    width: 100%;
  }

  .infos {
    display: grid;
    margin: 2rem 4rem 2rem 2rem;

    .roomsHeader {
      display: grid;
      grid-template-columns: 30% 30% 40%;
      padding-left: 2rem;
      height: 4rem;
      border-bottom-style: solid;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;
      margin-bottom: 2rem;
      color: #666666;
      font-size: 1.8rem;
      font-weight: 600;

      @media only screen and (max-width: $medium) {
        display: none;
      }

      .size {
        display: flex;

        .txt {
          margin-right: 1.5rem;
        }
      }
    }
    .details {
      height: 6rem;
      padding-left: 2rem;
      display: grid;
      grid-template-columns: 30% 30% 40%;
      border-bottom-style: solid;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;
      margin-bottom: 2rem;
      color: #666666;
      font-size: 1.8rem;
    }
  }
}
