@import "../../../../scss/index.scss";

.confirmationCode {
  .title {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 2rem 0 0 2rem;
    color: $grey-dark-2;
  }

  .subtitle {
    margin: 0 0 0 2rem;
    color: $grey-dark-1;
    font-size: 1.5rem;
  }

  .codeInput {
    margin: 2rem 2rem 0;
    div {
      input {
        height: 5rem;
      }
    }
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    margin: 2rem auto 0;
    width: 100%;
    height: 5rem;
  }

  .btntitle {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    .spinner {
      transform: scale(0.8);
      margin-right: 1rem;
      --color: #7158ad;
    }
  }

  .error {
    padding: 0 2rem;
    font-size: 1.3rem;
    color: $orange-medium;
  }

  .resend {
    color: $grey-dark-2;
    text-transform: initial;
    width: 100%;
    font-size: 1.5rem;
  }
}
