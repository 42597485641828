@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 2rem;

    .iconContainer {
      margin-top: 2rem;
      background-color: $purple-light-3;
      border-radius: 50%;
      padding: 0.5rem 1rem 0.7rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      .icon {
        stroke: $purple-medium-2;
        fill: $purple-light-3;
        width: 5rem;
        height: 5rem;
        stroke-width: 2rem;
      }
    }

    .title {
      font-size: 2.2rem;
      color: $grey-dark-1;
    }

    .subtitle {
      font-size: 2.2rem;
      font-weight: 600;
      color: $grey-dark-2;
      // margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .text {
      .note {
        margin-bottom: 2rem;
        padding: 0 3rem;
        font-size: 1.5rem;
        color: $grey-dark-1;
        text-align: center;
      }
    }
  }

  .content {
    margin: 1rem 2rem;

    .inputs {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      row-gap: 1.5rem;
      .passwordPolicy {
        color: $grey-dark-1;
        font-size: 1.2rem;
      }
    }
    .error {
      margin-top: 1rem;
      font-size: 1.4rem;
      color: $orange-medium;
    }

    .passError {
      font-size: 1.2rem;
      color: $orange-medium;
      margin-bottom: 1rem;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: flex-end;
  }
}
