@import "../../../scss/index.scss";

.container {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
  overflow-y: scroll;
  @include show-scrollbar;

  .terms {
    .content {
      margin: 2rem 3rem;
      color: $grey-dark-2;
      font-size: 1.8rem;

      .header {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      .subHeader {
        font-size: 1.8rem;
        font-weight: 600;
        margin-bottom: 2rem;
      }

      .title {
        font-size: 2.6rem;
        border-bottom: 0.1rem solid #e9e3f2;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
      }

      .label {
        font-size: 2.2rem;
        font-weight: 600;
      }

      .section {
        display: grid;
        grid-template-columns: 30% 70%;
        row-gap: 4rem;
        border-bottom: 0.1rem solid #e9e3f2;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
      }
      .bold {
        font-weight: 600;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
