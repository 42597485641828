@import "../../../scss/index.scss";

.userAccount {
  overflow-y: hidden;

  .mainContent {
    margin: 2rem 0.5rem 0;

    .greeting {
      margin: 2.2rem 0 0 2.5rem;
      font-size: 2.2rem;

      span {
        font-weight: 600;
        color: $grey-dark-2;
      }
      div {
        color: $grey-dark-1;
      }
    }

    .options {
      margin: 1rem 5rem 2rem 0.5rem;

      ion-label {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        color: $grey-dark-2;
        font-weight: 600;
        font-size: 1.5rem;

        ion-icon {
          display: grid;
          align-self: center;
          margin-right: 1rem;
          color: $grey-dark-1 !important;
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .buttons {
      margin: 4rem 0 2rem;
      display: grid;
      width: 100%;

      .register {
        --background: #e9e3f2;
        --background-activated: #e9e3f2;
        --border-color: #7158ad;
        --border-width: 1px;
        --border-style: solid;
        --box-shadow: 0;
        --color: #7158ad;
        text-transform: initial;
        margin: 0 auto;
        width: 90%;
        height: 5rem;
      }

      .logout {
        --background: #e9e3f2;
        --background-activated: #e9e3f2;
        --border-color: #7158ad;
        --border-width: 1px;
        --border-style: solid;
        --box-shadow: 0;
        --color: #7158ad;
        text-transform: initial;
        margin: 0 auto;
        width: 90%;
        height: 5rem;

        ion-icon {
          margin-right: 1.5rem;
        }
      }
      // .signin {
      //   --color: #707070;
      //   text-transform: initial;
      // }
    }
    .login {
      display: flex;
      justify-content: center;
      font-size: 1.5rem;
      color: $grey-dark-1;
      margin-top: 2rem;

      div {
        text-decoration: underline;
        color: $purple-medium-2;
        margin-left: 0.5rem;
      }
    }

    .infos {
      div {
        display: flex;
        justify-content: center;
        color: $grey-dark-1;
        width: 80%;
        font-size: 1rem;
        margin: 0 auto;

        a {
          color: $grey-dark-1;
          text-decoration: underline;
          margin: 0 0.5rem;
        }
      }
    }
  }
}

.customIcon {
  color: red;
  font-size: 2rem;
  fill: red;
  stroke: red 2px;
}
