@import "../../../scss/index.scss";

.container {
  width: 58rem;
  overflow-y: hidden;

  .header {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subtitle {
    display: grid;
    justify-content: center;
    color: $grey-dark-1;
    font-size: 1.5rem;
  }

  .content {
    display: grid;
    margin: 2rem auto;
    grid-template-columns: 50% 50%;

    .label {
      display: grid;
      justify-self: end;
      margin-right: 2rem;
      font-size: 1.5rem;
      align-content: center;
    }
  }
  .dropdown {
    position: absolute;
    width: 22rem;

    .items {
      position: relative;
      @include show-scrollbar;
      overflow-y: scroll;
      height: 14rem;
    }

    .currency {
      font-size: 1.5rem;
      margin: 1rem 0 1rem 1rem;
      color: $grey-dark-2;
      cursor: pointer;
      padding: 0.5rem;

      &:hover {
        background-color: $purple-light-2;
        border-radius: $radius-sm;
      }
    }
  }

  .disclaimer {
    font-size: 1.2rem;
    padding-top: 2rem;
    color: $grey-dark-1;
    text-align: center;
  }
}
