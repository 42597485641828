@import "../../../../../scss/index.scss";

.errorToast {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.dateTimePicker {
  display: grid;
  grid-template-columns: max-content auto;

  .icon {
    display: flex;
    align-self: center;

    ion-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: $grey-dark-1 !important;
    }
  }
}
