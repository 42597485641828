@import "../../../../../../scss/index.scss";

.clientTourItem {
  padding: 2rem 0.5rem;
  display: grid;
  grid-template-columns: 5% 10% 10% 15% 10% 10% 15% 10%;
  column-gap: 2rem;
  font-size: 1.5rem;
  color: $grey-dark-1;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-color: $purple-light-2;
  border-bottom-width: 0.1rem;

  .order {
    display: flex;
    justify-content: center;
  }

  .map {
    display: flex;
    justify-content: center;

    ion-icon {
      width: 2rem;
      height: 2rem;
      background-color: $blue-light;
      color: $blue-dark;
      padding: 0.6rem;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .status {
    width: max-content;
    display: flex;
    justify-self: center;
    border-radius: $radius-sm;
  }

  .rejected {
    color: red;
    background-color: rgb(255, 204, 204);
    padding: 0 0.5rem;
  }

  .completed {
    color: $purple-light-2;
    background-color: $purple-dark-1;
    padding: 0 0.5rem;
  }
  .confirmed {
    color: $green-dark;
    background-color: $green-light;
    padding: 0 0.5rem;
  }

  .requested {
    color: $yellow-dark;
    background-color: $yellow-medium;
    padding: 0 0.5rem;
  }

  .skipped {
    color: $yellow-dark;
    background-color: $yellow-medium;
    padding: 0 0.5rem;
  }

  .rejected {
    color: $orange-dark;
    background-color: $orange-light;
    padding: 0 0.5rem;
  }

  .cancelled {
    background-color: $orange-light;
    color: $orange-dark;
    padding: 0 0.5rem;
  }

  .review {
    display: flex;
    justify-content: center;
    transition: all 1s;

    ion-icon {
      margin: 0 0.2rem;
      color: $purple-light-3;
      cursor: pointer;
      transition: all 1s;

      &:hover {
        color: $purple-medium-1;
      }
    }

    .filledStar {
      color: $purple-medium-2 !important;
      transition: all 1s;
    }
  }

  .details {
    display: flex;
    justify-content: center;

    ion-icon {
      width: 2.2rem;
      height: 2.2rem;
      background-color: $purple-light-2;
      color: $purple-medium-2;
      padding: 0.5rem;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
