@import "../../scss/index.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  @include show-scrollbar;

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0;
    background-color: $purple-light-2;
    background-image: url("../../assets/img/webhomepage.png");
    background-repeat: repeat-x;
    background-size: contain;
    background-position: bottom;
    // min-height: 60rem;
    // min-height: calc(100vh - 8rem);
    min-height: calc(max(92vh, 60rem));
    width: 100%;

    .logo {
      position: absolute;
      top: 3.5rem;
      left: 1.5rem;
      width: 18rem;
    }

    .navbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // cursor: pointer;

      li {
        cursor: pointer;
        a,
        span {
          transition: all 0.3s ease-in;
          &:hover {
            color: $purple-medium-2;
          }
        }
      }
      .more {
        display: flex;
        flex: 1;
        position: relative;

        .btnContainer {
          flex: 1;
          display: flex;
          align-items: center;
          .icon {
            margin-left: 0.5rem;
          }
          .btn {
            flex: 1;
            color: $purple-dark-1;
            transition: all 0.3s ease-in;

            &:hover {
              color: $purple-medium-2;
            }
          }
        }

        .item {
          cursor: default;

          a {
            color: $purple-medium-2 !important;
            transition: all 0.3s ease-in;

            &:hover {
              cursor: pointer;
              color: $purple-dark-1 !important;
            }
          }

          &:not(:last-of-type) {
            margin-bottom: 0.25rem;
          }
        }
      }

      .auth {
        display: flex;
        justify-self: flex-end;
        margin-right: 2rem;
        font-size: 1.5rem;
        font-weight: 500;
        z-index: 1;
        cursor: pointer;

        .signIn {
          display: flex;
          align-self: center;
          color: $purple-dark-1;
          margin-right: 1.5rem;
          font-size: 1.5rem;
          // button {
          //   padding: 1rem !important;
          // }
        }

        .register {
          --background: transparent;
          background-color: #7158ad;
          border-top-right-radius: 5rem;
          border-bottom-right-radius: 5rem;
          border-top-left-radius: 5rem;
          border-bottom-left-radius: 5rem;
          --color: white;
          --color-actived: white;
          --color-focuse: white;
          --color-hover: white;
        }

        ion-button {
          --padding-start: 3rem;
          --padding-end: 3rem;
          --padding-top: 1rem;
          --padding-bottom: 1rem;
          text-transform: none;
        }

        div {
          font-size: 2rem;
          color: $purple-dark-1;
          z-index: 2;
          transition: color 0.3s ease-in;

          &:hover {
            cursor: pointer;
            color: $purple-medium-2;
          }
        }
      }

      ul {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        color: $purple-dark-1;
        font-weight: 500;

        li {
          &:not(:last-of-type)::after {
            content: "|";
            padding: 0 3rem;
            font-weight: 400;
          }
        }
      }
    }

    .content {
      padding: 12rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 4.2rem;
        color: $purple-medium-2;
        font-weight: 600;
      }

      .search {
        margin-top: 2rem;
        width: 70%;
        background-color: white;
        box-shadow: $box-shadow-dark;
        border-radius: $radius-normal;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $large) {
          width: 80%;
        }
        @media screen and (max-width: 1060px) {
          width: 90%;
        }

        .input {
          position: relative;
          padding: 1.5rem;
          border-bottom: $input-border;
        }

        .filters {
          display: flex;
          align-items: center;

          .status {
            padding: 1.5rem;

            .segment {
              --background: #e9e3f2;
              border-top-right-radius: 5rem;
              border-bottom-right-radius: 5rem;
              border-top-left-radius: 5rem;
              border-bottom-left-radius: 5rem;

              .segmentBtn {
                font-size: 1.5rem;
                text-transform: none;
                --background-checked: #7158ad;
                --border-color: transparent;
                --indicator-color: transparent;
                --color-checked: white;
                --color: #7158ad;
                border-top-right-radius: 5rem;
                border-bottom-right-radius: 5rem;
                border-top-left-radius: 5rem;
                border-bottom-left-radius: 5rem;
                margin: 0.5rem;
                --transition: all 0.2s ease-in;
                height: 3rem;
                min-height: 3rem;

                &:hover {
                  --background: #e9e3f2;
                }
              }
            }
          }

          .extra {
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            .item {
              padding: 1.5rem;
              flex: 1;
              position: relative;
              border-left: $input-border;

              &:first-of-type {
                flex: 2;
              }
              &:nth-of-type(2) {
                flex: 2;
              }

              &:hover {
                box-shadow: $box-shadow-dark;
              }
            }
          }
        }
      }

      .mapBtn {
        cursor: pointer;
        margin-top: 2.5rem;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem 6rem;
        border-top-left-radius: 5rem;
        border-bottom-left-radius: 5rem;
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $purple-medium-2;
        box-shadow: $box-shadow-dark;
        transition: all 0.2s ease-in-out;
        .mapIcon {
          margin-right: 1rem;
          font-size: 1.8rem;
        }

        &:hover {
          background-color: $purple-medium-2;
          color: white;
        }

        &:active {
          background-color: $purple-medium-2;
          color: white;
          box-shadow: $box-shadow-grey;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .note {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $purple-dark-1;
      color: $purple-light-3;
      padding: 2rem 0;
      font-size: 1.8rem;
      font-weight: 500;
      height: 8vh;

      & span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1.5rem;
        cursor: pointer;
        .icon {
          margin-left: 0.75rem;
          font-size: 2.2rem;
        }
      }
    }

    .cardsContainer {
      display: flex;
      justify-content: center;
      background-color: $purple-light-1;
      padding-bottom: 4rem;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
      }

      .note {
        font-size: 3rem;
        color: $purple-dark-1;
        background-color: $purple-light-1;
        font-weight: 600;
        text-align: center;
        width: 75%;
        padding: 16rem 0;
      }

      .cards {
        padding: 0 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        .content {
          padding: 3rem 5rem;

          @media screen and (max-width: $large) {
            padding: 3rem;
          }

          .img {
            width: 100%;
            height: 12rem;
          }

          .title {
            margin-top: 2rem;
            color: $grey-dark-2;
            font-size: 2.2rem;
            font-weight: 600;
          }
          .text {
            margin-top: 2rem;
            font-size: 1.8rem;
            color: $grey-dark-1;
            font-weight: 300;
          }

          .btn {
            margin: 0 auto;
            margin-top: 2rem;
          }
        }
      }
    }

    .tours {
      background-image: url("../../assets/img/sparkle.png");
      background-size: contain;
      background-position: top;
      background-repeat: repeat-x;
      background-color: $purple-light-2;

      .toursContent {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 2rem 4rem 0 4rem;
        max-width: 1400px;
        margin: 0 auto;
        .image {
          display: flex;
          align-items: flex-end;
          width: 175%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .info {
          align-self: center;
          .title {
            font-size: 3rem;
            font-weight: 600;
            color: $purple-medium-2;
          }

          .text {
            font-size: 1.8rem;
            color: $purple-dark-1;
            margin-top: 2rem;
          }

          @media screen and (max-width: $large) {
            .title {
              font-size: 3rem;
              line-height: 4rem;
            }
          }
        }

        .stores {
          margin-top: 4rem;
          margin-bottom: 3rem;
          display: flex;

          .store {
            height: 4.64rem;
            // width: 15.68rem;
            opacity: 1;

            transition: 0.3s all ease-in;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
            }
          }
        }
      }
    }

    .schools {
      display: flex;
      background-image: url("../../assets/img/sparkle.png");
      background-size: contain;
      background-position: top;
      background-repeat: repeat-x;
      background-color: $purple-light-2;

      .schoolsContent {
        padding: 2rem 4rem 0 4rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        max-width: 1400px;
        margin: 0 auto;
        .image {
          display: flex;
          align-items: flex-end;
          width: 175%;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .info {
          align-self: center;
          .title {
            font-size: 3rem;
            font-weight: 600;
            color: $purple-medium-2;
          }

          .text {
            font-size: 1.8rem;
            color: $purple-dark-1;
            margin-top: 2rem;
          }
          @media screen and (max-width: $large) {
            .title {
              font-size: 3rem;
              line-height: 4rem;
            }
          }
        }

        .stores {
          margin-top: 4rem;
          display: flex;
          margin-bottom: 3rem;

          .store {
            height: 4.64rem;
            // width: 15.68rem;
            opacity: 1;

            transition: 0.3s all ease-in;

            &:hover {
              cursor: pointer;
              opacity: 0.75;
            }
          }
        }
      }
    }

    .homeCards {
      background-color: $primary-bg-color;

      .homeCardsContent {
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        padding: 8rem 6rem;
        .homeCard {
          flex: 1;
          height: 100%;

          &:not(:last-of-type) {
            margin-right: 4rem;
          }
        }
      }
    }

    .features {
      background-color: $primary-bg-color;
      display: flex;

      .featuresContent {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1400px;
        margin: 0 auto;
        .title {
          font-size: 3rem;
          color: $grey-dark-2;
          font-weight: 600;
        }

        .subtitle {
          font-size: 1.8rem;
          color: $grey-dark-2;
          font-weight: 500;
          width: 75%;
          text-align: center;
          margin-top: 1rem;
        }

        .tags {
          margin-top: 2rem;
          display: flex;
          align-items: flex-start;
          justify-content: center;

          .tag {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 2rem;
            &:not(:last-of-type) {
              margin-right: 2rem;
            }
            .imgContainer {
              height: 150px;

              img {
                height: 100%;
              }
            }

            .title {
              margin-top: 1rem;
              font-size: 1.8rem;
              color: $grey-dark-1;
              font-weight: 400;
              text-align: center;
            }
          }
        }

        .agentBtn {
          display: flex;
          align-items: center;
          height: 5rem;
          --border-radius: 25px;
          border-radius: 25px;
          --background: #dff7f0 !important;
          --background-activated: #1f7a60 !important;
          --background-focused: #1f7a60 !important;
          --background-hover: #1f7a60 !important;
          --background-hover-opacity: 1 !important;
          --color: #1f7a60 !important;
          --color-activated: #dff7f0 !important;
          --color-focused: #dff7f0 !important;
          --color-hover: #dff7f0 !important;
          --border-width: 0.1rem;
          --border-color: #1f7a60 !important;
          border-color: #1f7a60;
          font-size: 1.8rem;
          margin: 3rem;
          margin-bottom: 4rem;
          text-transform: initial;
          letter-spacing: normal;
          --padding-start: 8rem;
          --padding-end: 8rem;
        }
      }
    }

    .searches {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;

      .title {
        font-size: 3rem;
        color: $purple-medium-2;
        font-weight: 600;
        padding-top: 4rem;
        padding-bottom: 2rem;
      }
    }
  }
}

.modal {
  --width: 75%;
  --height: 80%;
  --border-radius: 2.5rem;
  background: rgba($grey-dark-2, 0.7);
}
