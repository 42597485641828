@import "../../../../../../scss/index.scss";

.container {
  position: relative;
  .map {
    position: absolute;
    @include center-horizontally;
    width: 95%;
    height: 100%;
    z-index: 1;
    cursor: grab;
    background-color: white;

    .leaflet {
      border-radius: $radius-md;
      width: 100%;
      height: 100%;
    }

    &:active {
      cursor: grabbing;
    }
  }
  .carouselIcon {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    background-color: white;
    color: $grey-dark-2;
    border-radius: 5px;
    margin: 0 2rem;
  }
  .carousel {
    div {
      div {
        border-radius: 10px;
        ul {
          li {
            img {
              border-radius: 10px;
            }
          }
        }
      }
    }
    .thumbnail {
      ul {
        li {
          margin: 0 1rem !important;
          img {
            border-radius: 10px;
            width: 135.52px;
            height: 81.8px;
          }
        }
      }
    }
    .indexBoard {
      margin-left: 0;
      span {
        border-radius: 5px;
      }
    }
  }
}
