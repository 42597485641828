@import "../../../../scss/index.scss";
.container {
  position: absolute;
  display: flex;
  height: 5rem;
  top: -5rem;
  left: 3rem;
  z-index: 10;

  .tabs {
    display: flex;
    position: relative;

    .tab {
      position: relative;
      display: flex;
      width: 48rem;

      &:not(:first-of-type) {
        margin-left: -11.5rem;
      }

      // &:last-of-type {
      //   margin-left: -9rem;
      // }
      .curve {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        height: 5rem;
        width: 100%;
        stroke-width: 1.2px;
      }

      .content {
        position: absolute;
        @include center-vertically;
        @include center-horizontally;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $purple-dark-1;
        cursor: pointer;
        z-index: 500;
        .icon {
          display: flex;
          align-items: center;
          color: $purple-medium-1;
          stroke: $purple-medium-1;
          margin-right: 0.5rem;
        }

        .label {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
    }

    .active {
      fill: white;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 4rem;
        background-color: white;
        z-index: 500;
        bottom: 0;
        width: calc(100% - 8rem);
        height: 1px;
      }
    }

    .small {
      width: 30rem;
    }
  }
}
