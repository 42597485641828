@import "../../../../../scss/index.scss";

.team {
  margin: 2rem 1.5rem;
  color: $grey-dark-2;

  .title {
    margin-bottom: 1rem;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .row {
    font-size: 1.5rem;
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 2.5rem;
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    .label {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    .img {
      display: flex;
      width: 15rem;
    }
  }

  .lastRow {
    border-bottom: none;
    margin-bottom: 0;
  }
}
