@import "../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 62.5rem;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 2.6rem;
      color: $grey-dark-1;
    }

    .subtitle {
      font-size: 2.6rem;
      font-weight: 600;
      color: $grey-dark-2;
      line-height: 1.6rem;
    }

    .note {
      margin: 2rem 0;
      padding: 0 3rem;
      font-size: 1.5rem;
      color: $grey-dark-1;
      text-align: center;
    }
  }

  .content {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 3rem;

    .item {
      display: flex;
      align-items: center;
      .icon {
        stroke: $purple-medium-2;
        fill: transparent;
        width: 4.5rem;
        height: 3rem;
      }

      .label {
        font-size: 1.5rem;
        color: $grey-dark-2;
        padding-left: 1.5rem;

        a {
          color: $grey-dark-2;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .button {
    margin-top: 3rem;
  }
}
