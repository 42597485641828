@import "../../../../scss/app.scss";
.featured {
  padding: 1rem 2rem;
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    line-height: 1.3;

    .viewAll {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: $purple-medium-2;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 1.5rem 0 1.5rem 1.5rem;
      z-index: 1000;
    }

    .title {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .subtitleContainer {
      display: flex;
      justify-content: space-between;

      .subtitle {
        font-size: 1.5rem;
        color: $grey-dark-1;
        font-weight: 400;

        a {
          color: $purple-medium-2;
        }
        .link {
          color: $purple-medium-2;
        }
      }
    }
  }

  .slider {
    display: flex;
    margin-top: -1rem;
  }
}

.featured {
  &:not(:last-of-type) {
    border-bottom: 2px solid #dedaecb9;
  }
}
