@import "../../../../scss/index.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $purple-dark-2;
  z-index: 10;

  .selected {
    position: relative;
    height: 4rem;
    padding: 0 1rem;
    border-radius: $radius-sm;
    border: 1px solid $blue-dark;
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;

    .label {
      margin-left: 1.5rem;
    }
  }

  .collapseSelected {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    justify-content: center;
    cursor: pointer;
  }

  .items {
    border: 1px solid $blue-dark;
    border-top: none;
    border-bottom-left-radius: $radius-sm;
    border-bottom-right-radius: $radius-sm;
    display: flex;
    flex-direction: column;

    .item {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 1rem 0;
      padding: 0 1rem;
      .icon {
        margin-right: 1.3rem;
      }
    }
  }
  .collapseItems {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .item {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.5rem;

      .icon {
        transform: scale(1.2);
      }

      .label {
        display: none;
      }
    }
  }

  .collapsed {
    display: none;
  }

  .expanded {
    background-color: #007b9427;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .icon,
  .chevron {
    color: $blue-dark;
    transform: scale(1.2);
  }

  .chevron {
    margin-left: 5rem;
  }

  .collapseIcon {
    color: $blue-dark;
    transform: scale(1.4);
  }

  .collapseIconExpand {
    color: white;
    transform: scale(1.4);
  }

  .collapseChevron {
    color: white;
    margin-top: 0.5rem;
    transform: scale(1.2);
  }

  .inverted {
    transform: rotate(180deg) scale(1.2);
  }

  .label {
    color: $blue-light;
    font-size: 1.5rem;
    font-weight: 300;
  }

  .hoveredItem {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 1rem;
    @include center-vertically;
    left: 0;
    width: 16rem;
    height: 250%;
    background-color: $purple-dark-2;
    border: 1px solid $blue-dark;
    border-radius: $radius-sm;

    .icon {
      margin-right: 2rem;
    }
    .hoverLabel {
      font-size: 1.5rem;
      font-weight: 300;
      color: white;
    }
  }
}
