@import "../../scss/index.scss";

.any {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
  .label {
    color: $grey-dark-2;
    margin-left: 0.8rem;
    font-size: 1.5rem;
    font-weight: 400;
  }
}
