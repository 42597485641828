@import "../../../scss/index.scss";

.nav {
  grid-row: 2/-1;
  grid-column: 1/2;
  height: 100%;
  width: 100%;
  background-color: $purple-dark-2;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2000;

  @media screen and (max-width: $small) {
    display: none;
  }

  .buttons {
    position: relative;
    padding: 0 1.8rem;
    width: 100%;
    flex: 1;
  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .links {
      display: flex;
      padding: 0 2rem;
    }

    .agentButton {
      cursor: pointer;
      padding: 2rem;
      display: flex;
      align-items: center;

      .container {
        display: flex;
        width: 100%;
        height: 4rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        border-radius: $radius-sm;
        border: 0.1rem solid $purple-medium-2;

        .agentIcon {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          color: $purple-medium-1;
          margin-right: 1.5rem;
        }

        .agentLabel {
          font-size: 1.4rem;
          font-weight: 300;
          color: $purple-light-3;
        }
      }

      // @media screen and (max-height: 775px) {
      //   .container {
      //     display: none;
      //   }
      // }
    }

    .inviteButton {
      cursor: pointer;
      padding: 2rem;
      display: flex;
      align-items: center;
      .inviteIcon {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        color: $blue-dark;
        margin-right: 1rem;
      }

      .inviteLabel {
        font-size: 1.5rem;
        font-weight: 300;
        color: $blue-light;
      }
    }

    .collapse {
      display: flex;
      justify-content: center;
      padding: 2rem 0;

      .container {
        border: none;

        .agentIcon {
          margin: 0 auto;
          width: 3rem;
          height: 3rem;
        }
      }
      .inviteIcon {
        margin: 0 auto;
      }
    }

    .hide {
      display: none;
    }
  }
}
