@import "../../../../../scss/index.scss";

.agentDetails {
  .details {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 1rem;

    .label {
      color: $grey-dark-2;
      font-size: 2.2rem;
      font-weight: 600;
      margin-right: 2rem;
    }

    .tag {
      display: flex;
      flex-direction: row;
      background-color: $orange-light;
      color: $orange-dark;
      border-radius: 5px;
      padding: 0 1rem;

      .icon {
        margin-right: 1rem;
        display: flex;
        align-self: center;
      }
      span {
        display: flex;
        align-self: center;
        font-size: 1.2rem;
      }
    }
  }

  .container {
    margin-top: 2rem;
    position: relative;

    .item {
      font-size: 1.5rem;
      cursor: pointer;
      margin: 1rem 1.5rem;
      background-color: $purple-light-1;
      border-radius: $radius-sm;
      padding: 0 1.5rem;
      text-align: center;
      color: $purple-dark-1;
    }

    .agents {
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 1rem;
      }
    }
  }

  .textarea {
    margin: 2rem 0;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 9rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .close {
    display: grid;
    justify-content: end;
    width: 100%;
    margin-top: 1rem;
    color: $grey-dark-1;
    text-transform: capitalize;

    .icon {
      width: 2rem;
      height: 2rem;
      color: $grey-dark-1;
      --ionicon-stroke-width: 56px;
    }
  }
}
