@import "../../../../../scss/index.scss";
@import "../../../../../scss/variables.scss";

.loginForm {
  margin: 4rem;

  @media screen and (max-width: $large) {
    margin: 0;
  }

  .title {
    display: grid;
    margin-bottom: 2rem;
    line-height: 3.5rem;
    font-size: 3rem;
    color: $grey-dark-1;
    span {
      font-weight: 600;
      color: $grey-dark-2;
    }
  }

  .option {
    display: grid;
    justify-content: center;
    position: relative;
    top: 1.1rem;
    background: white;
    width: 24rem;
    margin: 0 auto;
    font-size: 1.5rem;
    color: $grey-dark-1;
  }
  .line {
    border-top-style: solid;
    border-top-color: $grey-dark-1;
    border-top-width: 0.1px;
    margin-bottom: 2rem;
  }

  .inputs {
    display: grid;
    row-gap: 2.5rem;
  }

  .error {
    padding-top: 3rem;
    font-size: 1.5rem;
    color: $orange-medium;
  }

  .rememberRow {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 2rem 0;
    font-size: 1.5rem;
    color: $grey-dark-1;

    .href {
      cursor: pointer;
      color: $grey-dark-1;
    }

    .forgotPass {
      display: grid;
      justify-content: end;
      color: $grey-dark-1;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .register {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1rem;
    justify-content: center;
    align-content: center;
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $grey-dark-2;

    .href {
      cursor: pointer;
      color: $purple-medium-2;
      text-decoration: underline;
    }
  }
}
