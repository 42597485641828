@import "../../../../../scss/index.scss";

.generalNotes {
  .body {
    margin: 1rem;

    .subtitle {
      font-size: 1.5rem;
      color: $grey-dark-1;
    }

    .textarea {
      margin: 1rem 0;
      border: 1px solid $grey-light-2;
      width: 100%;
      height: 37rem;
      padding: 1rem 1.5rem;
      font-size: 1.5rem;
      resize: none;
    }

    .button {
      height: 5rem;
      --background: #dff7f0;
      --background-activated: #1f7a60;
      --color: #1f7a60;
      --color-activated: #dff7f0;
      --border-color: #1f7a60;
      --border-style: solid;
      --border-width: 1px;
      --box-shadow: 0;
      text-transform: initial;
    }
  }
}

.appHeader {
  position: sticky;
  top: 0;

  .toolbar {
    --background: #f4f4f7;

    .title {
      display: flex;
      justify-content: center;
      font-size: 2.2rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .backButton {
      display: flex;
      justify-content: flex-start;
      --color: #707070;
      --icon-font-size: 1.8rem;
      --icon-padding-end: 0.8rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      padding: 0;
      text-transform: initial;

      .label {
        font-size: 1.5rem;
      }
    }
  }
}
