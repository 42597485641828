@import "../../../../scss/index.scss";

.requestContainer {
  background-color: white;
  padding: 1.5rem;

  .formFields {
    ion-row {
      margin: 1rem 0;
    }
  }

  .subtitle {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: $grey-dark-1;
  }

  .address {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .label {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .inputLabel {
      font-size: 1.2rem;
      font-weight: 500;
      color: $purple-medium-2;
      margin-bottom: 0.2rem;
    }
  }

  .borderBottom {
    padding: 0.5rem 0;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: #e5e5e5;
  }

  .textarealabel {
    font-size: 1.2rem;
    position: relative;
    top: 1.2rem;
    left: 1.5rem;
    padding: 0 0.5rem;
    background: white;
    color: $purple-medium-2;
    z-index: 10;
  }
  .textarea {
    width: 100%;
    height: 10rem;
    border: 1px solid $grey-light-2;
    border-radius: 5px;
    color: $grey-dark-2;
    font-size: 1.5rem;
    padding: 1rem;
    resize: none;
  }

  .addTime {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 2rem;
    background-color: $grey-light-1;
    // width: 28rem;
    height: 4rem;
    padding: 0 1rem;
    color: $grey-dark-1;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid $grey-light-2;

    .text {
      display: flex;
      align-self: center;
      font-size: 1.5rem;
      font-weight: 300;
    }

    .addIcon {
      display: grid;
      align-items: center;

      ion-icon {
        height: 2rem;
        width: 2rem;
        color: $grey-dark-1;
      }
    }
  }

  .button {
    margin-top: 2rem;
    text-transform: initial;
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --color: #7158ad;
    --border-color: #7158ad;
    --border-style: solid;
    --border-width: 0.1rem;
    --box-shadow: 0;
    height: 5rem;
  }

  .consent {
    display: flex;
    justify-content: center;
    text-align: center;
    color: $grey-dark-1;
    font-size: 1.2rem;
    margin: 2rem 0;
    padding: 0 1.5rem;
  }
  .offerDate {
    border: $input-border;
    border-radius: $radius-sm;
    font-size: 1.5rem;
    color: $grey-dark-2;
    font-weight: 600;
    --padding-top: 1rem;
    --padding-bottom: 1rem;

    width: 100%;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    --padding-start: 0;
    --inner-padding-top: 0;
    --inner-padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    margin-bottom: 1.5rem;
    height: 2.5rem;
    .checkbox {
      margin-right: 0.5rem;
      --background-checked: #7158ad;
      --border-radius: 0.5rem;
      --size: 1.5rem;
      --border-width: 1px;
    }
    .label {
      margin-top: 1rem;
      color: $grey-dark-2;
      font-size: 1.2rem;
    }
  }
}
