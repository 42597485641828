@import "../../../../scss/app.scss";

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  font-size: 1.5rem;

  .label {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }

  .params {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;

    .item {
      margin-right: 0.5rem;
      .title {
        font-size: 1.5rem;
        font-weight: 500;
        color: $grey-dark-2;
      }

      .filter {
        font-size: 1.5rem;
        font-weight: 300;
        color: $grey-dark-1;
        margin: 0 0.5rem;
      }
    }
  }

  .details {
    & > div {
      border-bottom: 1px solid $line-color;
    }

    .schools {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      color: $grey-dark-1;
      padding: 0.5rem 0;
      .school {
        display: flex;
        align-items: center;
        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }

        .iconContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $green-light;
          padding: 0.35rem 0.8rem;
          border-radius: $radius-sm;
        }

        .name {
          overflow-x: hidden;
          color: $grey-dark-1;
          font-size: 1.5rem;
          padding-left: 2rem;
          text-decoration: underline;
        }
      }
    }

    .alerts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 5rem;

      .toggleContainer {
        --padding-top: 0;
        --padding-bottom: 0;

        .toggle {
          --background-checked: #ff5f0f;
          --handle-background: white;
          --handle-background-checked: white;
        }
      }
    }
    .iconContainer {
      display: flex;
      align-items: center;
      .icon {
        font-size: 2rem;
        color: $grey-dark-2;
        margin-right: 0.5rem;
      }
      .title {
        font-size: 1.5rem;
        color: $grey-dark-2;
      }
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    width: 100%;
    padding: 2rem;
  }
}

.toast {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

ion-toast::part(button) {
  font-size: 1.2rem;
}
