@import "../../../../scss/index.scss";

.inputs {
  margin: 4rem 2rem;

  .contact {
    display: grid;
    grid-template-columns: 25% 55% 20%;
    font-size: 1.5rem;
    color: $grey-dark-1;
    border-top-style: solid;
    border-top-color: $purple-light-2;
    border-top-width: 0.1rem;
    padding: 1rem 0;

    .title {
      display: flex;
      align-self: center;
    }
    .bold {
      font-weight: 600;
      color: $grey-dark-2;
    }
    .infos {
      display: flex;
      flex-direction: column;
    }

    .btn {
      display: grid;
      align-self: center;

      .button {
        --background: #e0faff;
        --color: #007b94;
        --box-shadow: 0;
        --border-color: #007b94;
        --border-radius: 10px;
        --border-style: solid;
        --border-width: 0.1rem;
        height: 4rem;
      }
    }
  }
}
