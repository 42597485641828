@import "../../../scss/app.scss";

.clientTour {
  --background: #f4f4f7;

  .appHeader {
    position: sticky;
    top: 0;

    .toolbar {
      --background: #f4f4f7;

      .title {
        display: flex;
        justify-content: center;
        font-size: 2.2rem;
        color: $grey-dark-2;
        font-weight: 600;
      }

      .backButton {
        display: flex;
        justify-content: flex-start;
        --color: #707070;
        --icon-font-size: 1.8rem;
        --icon-padding-end: 0.8rem;
        font-size: 1.5rem;
        margin-left: 1rem;
        padding: 0;
        text-transform: initial;

        .label {
          font-size: 1.5rem;
        }
      }
    }
  }

  .container {
    background-color: white;
    padding: 1rem 0;
    box-shadow: $box-shadow;
    .header {
      padding: 0 2rem;
      font-size: 1.4rem;
      text-align: center;
      color: $grey-dark-1;
      font-weight: 400;
      margin: 1rem 0;
    }

    .content {
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      color: $grey-dark-2;

      .navBtn {
        margin: 2rem;
        --background: #e0faff;
        --background-focused: #e0faff;
        --background-activated: #e0faff;
        --background-hover: #e0faff;
        --border-radius: 0.75rem;
        --border-style: solid;
        --border-color: #007b94;
        --border-width: 1px;
        --padding-start: 5px;
        --padding-end: 5px;
        .icon {
          font-size: 2rem;
          color: #007b94;
          margin-right: 1rem;
        }
        .label {
          font-size: 1.6rem;
          color: #007b94;
        }
      }

      .details {
        display: flex;
        flex-direction: column;

        &:not(:first-of-type) {
          margin-top: 1.5rem;
        }

        .btn {
          padding: 1rem 0;
          margin: 0 2rem;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $grey-light-2;

          .btnLabel {
            display: flex;
            align-items: center;
            .btnText {
              margin-left: 0.5rem;
            }

            .btnIcon {
              font-size: 1.6rem;
              color: $grey-dark-1;
            }
          }

          .btnChevron {
            font-size: 2rem;
            color: $grey-medium-1;
          }
        }

        .title {
          font-size: 1.8rem;
          font-weight: 600;
          padding: 0.5rem 2rem;
          border-bottom: 1px solid $grey-light-2;
        }
        .note {
          padding: 1rem 2rem 0 2rem;
          color: $grey-dark-1;
        }

        .item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $grey-light-2;
          padding: 1rem 0;
          margin: 0 2rem;

          .label {
            font-size: 1.4rem;
            font-weight: 400;
          }

          .value {
            display: flex;
            align-items: center;
            font-weight: 600;

            .text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 20rem;
              text-align: right;
            }

            .icon {
              margin-left: 0.5rem;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .tourElement {
    .title {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
      margin: 2rem -2rem;
      padding: 0 0 2rem 4rem;
      border-bottom-color: $grey-light-2;
      border-bottom-width: 0.1rem;
      border-bottom-style: solid;
    }

    .rows {
      margin: 0 2rem;
    }
  }
}

.popover {
  font-size: 1.2rem !important;
  p {
    padding: 0.25rem 1.5rem;
  }
}
