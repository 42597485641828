@import "../../scss/index.scss";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3.2rem 2.9rem;
  border: 1px solid $line-color;
  border-radius: $radius-lg;
  background-color: white;
  box-shadow: $box-shadow;

  & div:not([class]) {
    flex: 1;
    position: relative;
    border-radius: $radius-md;
  }
}
.mapCard {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.add,
.notFound {
  border: none;
  background-image: $dashed-border;
  border-color: $purple-medium-1;
  background-color: transparent;
  margin-bottom: 3rem;
}
