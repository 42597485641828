@import "../../../scss/index.scss";

.signin {
  margin: 0 2rem;

  .option {
    display: grid;
    justify-content: center;
    position: relative;
    top: 1.1rem;
    background: white;
    width: 22rem;
    margin: 0 auto;
    font-size: 1.5rem;
    color: $grey-dark-1;
    z-index: 2;
  }

  .line {
    border-top-style: solid;
    border-top-color: $grey-light-2;
    border-top-width: 0.2px;
    margin-bottom: 2rem;
    z-index: 1;
  }

  .inputs {
    ion-row {
      margin: 1rem 0;

      ion-col {
        div {
          input {
            height: 5rem;
          }
        }
      }
    }
  }

  .error {
    margin-left: 1rem;
    font-size: 1.5rem;
    color: $orange-medium;
  }

  .forgotPass {
    display: grid;
    justify-content: end;
    color: $grey-dark-1;
    text-decoration: underline;
    font-size: 1.5rem;
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    margin: 2rem auto;
    width: 100%;
    height: 5rem;
  }

  .title {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;

    .spinner {
      transform: scale(0.8);
      margin-right: 1rem;
      --color: #7158ad;
    }
  }

  .register {
    display: grid;
    justify-content: center;
    color: $grey-dark-2;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    a {
      text-decoration: underline;
      color: $purple-medium-2;
      margin: 0 auto;
      margin-bottom: 0.5rem;
    }
  }
}
