@import "../../../../../../../../scss/index.scss";

.overview {
  display: grid;
  margin: 0 auto;
  border: $cluster-border;
  border-top-style: none;
  border-radius: 0px 0px 5px 5px;

  @media only screen and (max-width: $large) {
    width: 100%;
  }

  .infos {
    display: grid;
    margin: 2rem 4rem 2rem 2rem;
    .title {
      font-size: 2.2rem;
      color: $purple-medium-2;
      margin-top: 4rem;
    }
    .details {
      display: grid;
      grid-template-columns: repeat(2, 48%);
      column-gap: 4rem;

      @media only screen and (max-width: 1024px) {
        grid-template-columns: auto;
      }

      div {
        display: flex;
        justify-content: space-between;
        border-bottom-style: solid;
        border-bottom-width: 0.1rem;
        border-bottom-color: $grey-light-2;

        margin-bottom: 2rem;
        padding-bottom: 2rem;
        color: #666666;
        font-size: 1.8rem;

        @media only screen and (max-width: $medium) {
          font-size: 1.5rem;
        }

        .bold {
          font-weight: 600;

          @media only screen and (max-width: $medium) {
            font-weight: 400;
          }
        }

        span:not(.bold) {
          text-align: right;
        }

        .marginRight {
          margin-right: 2.2rem;
        }
      }

      .ammenities {
        display: flex;
        justify-content: space-between;

        span {
          text-align: justify;
        }
      }
    }
  }
}
