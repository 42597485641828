@import "../../../../../../../scss/index.scss";

.agentDetails {
  margin: 2rem 1rem;

  ion-row {
    margin-bottom: 1rem;
  }

  .textarea {
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 21rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .button {
    height: 5rem;
    --background: #dff7f0;
    --background-activated: #1f7a60;
    --color: #1f7a60;
    --color-activated: #dff7f0;
    --border-color: #1f7a60;
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: 0;
    text-transform: initial;
  }

  .note {
    color: $grey-dark-1;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $purple-light-2;
  }

  .clearButton {
    --color: #c24100;
    font-weight: 500;
  }

  .archiveButton {
    --color: #c24100;
    font-size: 1.4rem;
    font-weight: 500;
  }
  .cancelButton {
    --color: #c24100;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.btn {
  font-size: 1.5rem !important;
}

.actionSheet {
  --button-background-activated: transparent;
  --button-background-focused: transparent;
  --button-background-hover: transparent;
  --color: #7158ad;
}
