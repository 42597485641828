@import "../../../scss/app.scss";

.container {
  padding: 1rem 2rem;
  font-size: 1.5rem;

  .invite {
    p {
      color: $grey-dark-1;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .btn {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $grey-light-1;
      padding: 1.5rem;
      border-radius: $radius-md;
      color: $grey-dark-2;

      .icon {
        font-size: 2.5rem;
        color: $grey-dark-2;
      }

      &:first-of-type {
        margin-right: 1.5rem;
      }
    }
  }
  .options {
    border-top: 1px solid #e5e5e5;
    margin-top: 2.5rem;

    .option {
      --padding-top: 1rem;
      --padding-bottom: 1rem;
      .icon {
        color: $grey-dark-2;
        font-size: 2.5rem;
      }

      .label {
        margin-left: 2rem;
        color: $grey-dark-2;
        font-size: 1.5rem;
      }
    }
  }
}

.toast {
  --background: #1f7a60;
  --color: #dff7f0;
  font-size: 1.2rem;
  --button-color: white;
}

ion-toast::part(button) {
  font-size: 1.2rem;
}
