@import "../../../../../../scss/index.scss";

.quickContact {
  div {
    display: flex;
    flex-direction: column;
    border-bottom-style: solid;
    border-bottom-color: $purple-light-2;
    border-bottom-width: 0.1rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
    padding: 1rem 0;

    .bold {
      font-weight: 500;
      color: $grey-dark-2;
    }

    .email {
      display: flex;
      flex-direction: row;
      //   flex-direction: column;
      text-decoration: underline;
      cursor: pointer;

      ion-icon {
        width: 2rem;
        height: 2rem;
        display: flex;
        margin-left: 0.5rem;
        align-self: center;
      }
    }
  }
}
