@import "../../scss/index.scss";

.map {
  position: relative;
  width: 100%;
  height: 100%;

  .clusters {
    position: absolute;
  }

  .locationPin {
    height: 3rem;
    width: 3rem;
    fill: $grey-dark-2;
    stroke: transparent;
    stroke-width: 1px;
  }

  .lightLocationPin {
    fill: $purple-medium-2;
    stroke: rgba(255, 255, 255, 0.75);
    stroke-width: 1px;
  }

  .detailsPin {
    height: 4rem;
    width: 4rem;
    fill: $orange-medium;
    stroke: $orange-light;
    stroke-width: 1px;
    z-index: 100;
  }

  .lightDetailsPin {
    fill: $orange-light;
    stroke: $orange-medium;
    stroke-width: 1px;
  }

  .schoolPin {
    height: 4rem;
    width: 4rem;
    stroke-width: 1px;
    cursor: pointer;
  }

  .schoolPinDeactive {
    fill: $green-light;
    stroke: $green-dark;
  }

  .schoolPinActive {
    stroke: $green-light;
    fill: $green-dark;
  }

  .schoolPinSelected {
    stroke: white !important;
    fill: $orange-dark !important;
  }

  .schoolAnimatedPin {
    animation: mountTranslate 0.4s ease-in-out;
  }
}

.hidden {
  display: none;
}

.cards {
  border-radius: $radius-lg;
  background-color: white;
  box-shadow: 2px 2px 13px #949494;
  border: 1px solid $line-color;
  display: flex;
  flex-direction: column;
  position: absolute;
  // right: 50%;
  // transform: translateX(50%);
  // margin-top: 4rem;
  max-height: 20rem;
  z-index: 100;
  overflow-y: scroll;

  .scroll {
    box-sizing: border-box;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10000000000000;
    background-color: white;
    font-size: 2.4rem;
    color: $grey-light-2;
    height: 0;
    transition: all 0.3s ease-in;
    cursor: default;
  }
  .scrollBottom {
    top: 58%;
  }
  .scrollTop {
    top: 42%;
  }
  .visible {
    color: $grey-dark-1;
    cursor: pointer;
  }

  .hide {
    display: none;
  }
}

.mobileCards {
  position: absolute;
  left: 0;
  top: 70%;
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;

  @include hide-scrollbar;
}

.schoolCard {
  border-radius: $radius-lg;
  background-color: white;
  box-shadow: 2px 2px 13px #949494;
  border: 1px solid $line-color;
  display: flex;
  flex-direction: column;
  position: absolute;
  // right: 50%;
  // margin-top: 4.5rem;
  // transform: translateX(50%);
  z-index: 100;
}

.layersBtn {
  position: absolute;
  left: 1.5rem;
  bottom: 1.5rem;
  width: 5rem;
  height: 5rem;
  z-index: 1000;
}

.saveSearchBtn {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 1000;
  display: none;
}

.loadingContainer {
  position: absolute;
  @include center-horizontally;
  width: 5rem;
  height: 5rem;
  z-index: 100;
  .loading {
    width: 5rem;
    height: 5rem;
    color: $purple-dark-1;
  }
}

.btnContainer {
  @include safe-top-margin;
  position: absolute;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.noResults {
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  background-color: $orange-light;
  box-shadow: $box-shadow-grey;
  font-size: 1.2rem;
  color: $orange-dark;
  text-align: center;
  z-index: 1500;
  border-radius: $radius-md;
  line-height: 15px;

  p {
    padding-right: 2rem;
  }

  .close {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-size: 1.8rem;
  }
}

.keyboard-shortcuts-dialog-view {
  display: none;
}

@keyframes Border {
  0% {
    border-color: #f4f4f7;
  }
  10% {
    border-color: #e9e3f2;
  }
  20% {
    border-color: #dedaec;
  }
  30% {
    border-color: #927bca;
  }
  40% {
    border-color: #7158ad;
  }
  50% {
    border-color: #33244c;
  }
  60% {
    border-color: #7158ad;
  }
  70% {
    border-color: #927bca;
  }
  80% {
    border-color: #dedaec;
  }
  90% {
    border-color: #e9e3f2;
  }
  100% {
    border-color: #f4f4f7;
  }
}

@-webkit-keyframes Border {
  0% {
    border-color: #f4f4f7;
  }
  10% {
    border-color: #e9e3f2;
  }
  20% {
    border-color: #dedaec;
  }
  30% {
    border-color: #927bca;
  }
  40% {
    border-color: #7158ad;
  }
  50% {
    border-color: #33244c;
  }
  60% {
    border-color: #7158ad;
  }
  70% {
    border-color: #927bca;
  }
  80% {
    border-color: #dedaec;
  }
  90% {
    border-color: #e9e3f2;
  }
  100% {
    border-color: #f4f4f7;
  }
}

@keyframes mountTranslate {
  0% {
    opacity: 0;
    transform: scale(1) translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
