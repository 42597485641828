@import "../../scss/index.scss";

.tour {
  display: grid;
  grid-column: 2/-1;
  grid-row: 2/-1;
  grid-template-rows: min-content 1fr;
  overflow-y: scroll;
  @include show-scrollbar;
  height: 100%;

  .nav {
    margin: 2rem 0 2rem 2rem;
    display: flex;
    color: $grey-dark-1;
    font-size: 1.5rem;

    .btn {
      cursor: pointer;
    }

    .icon {
      display: flex;
      align-self: center;
      margin: 0 0.5rem;
    }
    .bold {
      color: $grey-dark-2;
      font-weight: 600;
    }
  }

  .header {
    display: grid;
    grid-template-columns: auto auto;
    margin: 2rem 0 0;
    padding: 0 2rem 2rem;
    border-bottom-color: #dedaec;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;

    .firstColumn {
      display: flex;

      div {
        margin: 0 1rem;
      }
      .icon {
        display: flex;
        align-self: center;

        ion-icon {
          width: 4rem;
          height: 4rem;
        }
      }
      .title {
        font-size: 2.6rem;
        font-weight: 600;
        display: flex;
        align-self: center;
      }
      .tag {
        display: flex;
        flex-direction: row;
        background-color: $yellow-light;
        color: $yellow-dark;
        border-radius: 5px;
        padding: 0 1rem;
        height: 3rem;
        align-self: center;

        .icon {
          margin-right: 1rem;
          display: flex;
          align-self: center;
        }
        span {
          display: flex;
          align-self: center;
          font-size: 1.2rem;
        }
      }
    }

    .secondColumn {
      display: flex;
      justify-content: flex-end;

      .button {
        --background: #e9e3f2;
        --border-color: #7158ad;
        --color: #7158ad;
        --border-style: solid;
        --border-width: 0.1rem;
        --box-shadow: 0;
        height: 4.5rem;
        text-transform: none;
        --border-radius: 10px;
        font-size: 1.5rem;

        ion-icon {
          margin-right: 0.5rem;
        }
      }
      .directionsBtn {
        --background: #e0faff;
        --border-color: #007b94;
        --color: #007b94;

        ion-icon {
          margin-right: 0.5rem;
        }
        margin-right: 1.5rem;
      }
      .showingBtn {
        --background: #e9e3f2;
        --border-color: #7158ad;
        --color: #7158ad;

        ion-icon {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .titles {
    padding: 2rem 0;

    border-bottom-color: #dedaec;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    display: grid;
    grid-template-columns: 10% 10% 10% 10% 15% 12% 10% 10% 10%;
    font-size: 1.5rem;
    font-weight: 500;
    color: $grey-dark-2;
  }

  .options {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .left {
      grid-column: 1/2;
      max-width: 100%;
      overflow: hidden;

      @media screen and (max-width: $large) {
        grid-column: 1/-1;
      }
    }

    .right {
      grid-column: 2/-1;

      @media screen and (max-width: $large) {
        grid-column: 1/-1;
      }
    }
  }
}

.incompleteTour {
  display: flex;
  flex-direction: column;
  .title {
    margin: 0 auto;
    font-size: 1.2rem;
    font-weight: 500;
    color: $orange-medium2;
  }
}

.items {
  display: flex;
  width: 100%;
  justify-content: stretch;
  // margin-bottom: 1rem;
  min-height: 8rem;

  .reorder {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }
}

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.5rem;
  --button-color: white;
}

.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.5rem;
  --button-color: white;
}

ion-reorder::part(icon) {
  color: $grey-dark-1;
}

.revertItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  color: $grey-dark-1;
  cursor: pointer;

  .revertLabel {
    display: flex;
    align-self: center;
    font-size: 1.5rem;
    --padding-start: 0;
  }

  .revertIcon {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 1rem;
  }
}
