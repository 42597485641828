@import "../../../scss/index.scss";

.toggle {
  position: relative;
  width: 6.6rem;
  height: 2.6rem;
  border-radius: $radius-lg;
  background-color: $grey-dark-1;
  transition: all 0.3s;
  cursor: pointer;

  .circle {
    position: absolute;
    @include center-vertically;
    left: 7%;
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
    background-color: white;
    transition: all 0.3s;
  }
  .activeCircle {
    left: 70%;
  }

  .label {
    position: absolute;
    display: inline-flex;
    align-items: center;
    @include center-vertically;
    color: white;
    right: 20%;
    font-weight: 300;
    transition: all 0.3s;
  }
}
.orange {
  background-color: $orange-medium;
}
.purple {
  background-color: $purple-medium-2;
}
.green {
  background-color: $green-dark;
}

.activeLabel {
  left: 20%;
}

.disabled {
  background-color: $grey-light-2;
  cursor: not-allowed;
}
