@import "../../../../../../../scss/index.scss";

.tourDetails {
  .title {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0 0 1rem 2rem;
    border-bottom: 0.1rem solid $grey-light-2;
  }

  .description {
    display: grid;
    grid-template-columns: 25% 70%;
    column-gap: 2rem;

    font-size: 1.5rem;
    color: $grey-dark-2;
    margin: 0 2rem 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid $grey-light-2;

    .bold {
      display: flex;
      justify-content: flex-start;
      font-weight: 600;
      text-align: start;
    }
  }

  .details {
    font-size: 1.5rem;
    color: $grey-dark-2;
    margin: 0 2rem 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid $grey-light-2;

    .bold {
      display: flex;
      justify-content: flex-end;
      font-weight: 600;
      text-align: end;
    }

    .link {
      text-decoration: underline;

      ion-icon {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        margin-left: 0.5rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .note {
    margin: 0 1rem 1rem 2rem;
    color: $grey-dark-1;
    font-size: 1.5rem;
  }
}
