@import "../../scss/index.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  overflow-y: hidden;
  transition: all 0.2s linear;
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button {
    transition: all 0.2s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    height: 8rem;
    padding: 2rem 0;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      cursor: pointer;
    }

    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.5) 10%,
      rgba(255, 255, 255, 0.65) 15%,
      rgba(255, 255, 255, 0.75) 30%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.9) 75%,
      white 100%
    );
    backdrop-filter: blur(1px);

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .label {
      font-size: 1.5rem;
      position: absolute;
      z-index: 100;
    }
    .icon {
      margin-top: 2.5rem;
      font-size: 1.8rem;
      position: absolute;
      z-index: 100;
    }
    .label,
    .icon {
      z-index: 100;
      font-weight: 600;
      color: $grey-dark-2;
    }
  }
}

// .expanded {
// transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
// }
