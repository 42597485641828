@import "../../../scss/index.scss";

.container {
  width: 58rem;

  .header {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subtitle {
    display: grid;
    justify-content: center;
    color: $grey-dark-1;
    font-size: 1.5rem;

    span {
      width: 44rem;
      text-align: center;

      a {
        font-weight: 500;
        color: $grey-dark-1;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 3rem;

    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0.5rem 0;

      .radio {
        position: relative;
        border: 1px solid $grey-dark-1;
        border-radius: 50%;
        width: 1.6rem;
        height: 1.6rem;
        input {
          opacity: 0;
          width: 0;
          height: 0;
          display: flex;
          align-items: center;

          &:checked + .radioControl {
            background-color: $purple-medium-2;
            border-color: $purple-medium-2;
            width: 1.1rem;
            height: 1.1rem;
          }
        }

        .radioControl {
          display: block;
          width: 1.1rem;
          height: 1.1rem;
          border-radius: 50%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .label {
        margin-left: 0.8rem;
        // transform: translateY(0.5px);
      }
    }

    .title {
      margin-bottom: 1rem;
      font-size: 1.8rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    .options {
      display: grid;
      grid-template-columns: auto auto auto auto;
      font-size: 1.5rem;

      div {
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 1rem;

        ion-icon {
          display: grid;
          align-self: center;
          width: 2rem;
          height: 2rem;
          color: $grey-dark-1;
          cursor: pointer;
        }
      }
    }
  }
}
