@import "../../../scss/app.scss";

ion-refresher {
  z-index: 500;
  background-color: transparent;
}

.content {
  --color: #7158ad;
  font-size: 1rem !important;
  --refresher-icon-font-size: 5px;

  div {
    font-size: 1.3rem !important;
    color: $purple-medium-2 !important;
  }
  ion-icon {
    margin-top: 0.5rem;
    color: #7158ad;
    font-size: 2rem;
    background-color: white;
  }
}

.primary {
  background-color: $primary-bg-color;

  ion-icon {
    background-color: $primary-bg-color;
  }
}
