@import "../../../scss/index.scss";

.listingEmail {
  width: 62rem;

  .error {
    margin-top: 1rem;
    font-size: 1.5rem;
    color: $orange-medium;
  }

  .title {
    display: grid;
    justify-content: center;
    font-size: 3rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .inputs {
    display: grid;
    grid-row-gap: 2rem;
  }

  .info {
    display: grid;
    width: 32rem;
    text-align: center;
    color: $grey-dark-1;
    margin: 0 auto 4rem;
  }

  .textarea {
    width: 100%;
    height: 8.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 1px solid $grey-light-2;
    border-radius: 5px;
    color: $grey-dark-1;
    font-size: 1.5rem;
    padding: 1rem 0 0 1rem;
    resize: none;
  }
}

.container {
  width: 55.4rem;

  .pic {
    display: grid;
    justify-self: center;
    justify-content: center;
    margin: 2rem auto;
    background-color: $purple-light-2;
    width: 8rem;
    height: 8rem;
    border-radius: 50px;

    img {
      height: 5rem;
      display: grid;
      align-self: center;
    }

    ion-icon {
      height: 4.8rem;
      width: 4.8rem;
      border: 0.2rem solid $purple-medium-2;
      color: $purple-medium-2;
      border-radius: $radius-lg;
      display: grid;
      justify-self: center;
      align-self: center;
    }
  }
  .title {
    display: grid;
    justify-content: center;
    margin-top: 1rem;
    color: $grey-dark-2;
    font-size: 2.6rem;
    font-weight: 600;
  }
  .info {
    display: grid;
    width: 36rem;
    text-align: center;
    color: $grey-dark-1;
    margin: 0 auto 4rem;
  }
  .btn {
    margin-top: 2rem;
  }
}
