@import "../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55rem;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .iconContainer {
      background-color: $purple-light-2;
      border-radius: 50%;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      .icon {
        stroke: $purple-medium-2;
        fill: $purple-light-3;
        width: 5rem;
        height: 5rem;
        stroke-width: 2rem;
      }
    }

    .title {
      font-size: 2.6rem;
      color: $grey-dark-1;
    }

    .subtitle {
      font-size: 2.6rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    .text {
      .note {
        margin-bottom: 2rem;
        padding: 0 3rem;
        font-size: 1.5rem;
        color: $grey-dark-1;
        text-align: center;
      }
      .passwordPolicy {
        color: $grey-dark-1;
        font-size: 1.2rem;
        margin: 2rem 0;
      }
    }
  }

  .content {
    margin-bottom: 2rem;
    .inputs {
      display: grid;
      grid-template-rows: 1fr 1fr;
      row-gap: 1rem;
    }

    .error {
      padding-top: 1rem;
      font-size: 1.5rem;
      color: $orange-medium;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
}
