@import "../../../../../scss/index.scss";

.generalInformation {
  margin: 2rem 1.5rem;
  color: $grey-dark-2;

  .title {
    margin-bottom: 1rem;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subtitle {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .info {
    font-size: 1.5rem;
    color: $grey-dark-2;
    margin-bottom: 2rem;

    div {
      margin-bottom: 0.5rem;
    }
  }
  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    height: 5rem;
  }
}
