@import "../../../../scss/index.scss";

.comparables {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: $large) {
    width: 90vw;
  }

  .title {
    margin: 0 0 4rem 2rem;
    // margin-bottom: 4rem;
    font-size: 3rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .listings {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
