@import "../../../../../scss/index.scss";

.map {
  position: absolute;
  @include center-horizontally;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: grab;
  background-color: white;

  .leaflet {
    border-radius: $radius-md;
    width: 100%;
    height: 100%;
  }

  &:active {
    cursor: grabbing;
  }
}
