@import "../../../../scss/index.scss";

.button {
  position: relative;
  display: flex;
  height: 3.5rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

  .updates {
    position: absolute;
    left: 0.5rem;
    top: 1.1rem;
    width: 1rem;
    height: 1rem;
    background-color: $orange-medium;
    border-radius: 50%;
    border: 2px solid $purple-dark-2;
  }

  .label {
    color: $purple-light-3;
    font-size: 1.5rem;
    font-weight: 400;
    padding-left: 0.8rem;
  }

  .icon {
    // height: 1.7rem;
    width: 3rem;
    stroke: $purple-medium-1;
    padding-left: 0.5rem;
    height: 2rem;
  }
}

.active {
  background-color: $purple-dark-1;
  border: 1px solid $purple-medium-2;
  border-radius: $radius-sm;

  .updates {
    border-color: $purple-dark-1;
  }

  .icon {
    stroke: $purple-light-3;
  }
}

.hovered {
  &:hover {
    width: 16rem;
    background-color: $purple-dark-2;
    border: 1px solid $purple-medium-2;
    border-radius: $radius-sm;

    .label {
      display: block !important;
      font-size: 1.5rem;
      font-weight: 300;
      color: white;
    }
  }
}

.selectedHovered {
  &:hover {
    background-color: $purple-dark-1;
    border: 1px solid $purple-medium-2;
  }
}

.dimmed {
  cursor: default;

  .label {
    opacity: 0.3;
  }
  .icon {
    opacity: 0.3;
  }
}
