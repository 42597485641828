@import "../../scss/index.scss";

.search {
  position: relative;
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 3fr;

  @media screen and (max-width: $search-bp1) {
    grid-template-columns: 1.2fr 3fr;
  }
  @media screen and (max-width: $search-bp2) {
    grid-template-columns: 1.4fr 3fr;
  }

  .footer {
    position: absolute;
    bottom: 0;
    height: 4rem;
    width: 100%;
    left: 0;
    background-color: $purple-dark-1;
    color: $purple-light-3;
    z-index: 10;
    @media screen and (max-width: $small) {
      display: none;
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: 15rem;
    height: 15rem;
    stroke: $purple-medium-1;
    stroke-width: 1.5rem;
  }
}
