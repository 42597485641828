@import "../../../../../../scss/index.scss";

.card {
  grid-column: 1/-1;
  width: 95%;
  margin-left: 2rem;
  background-color: white;
  box-shadow: 1px 1px 5px $purple-light-2;
  border: 1px solid $purple-light-2;
  border-radius: 25px;
  padding-bottom: 4rem;

  @media only screen and (max-width: $large) {
    margin: 0 auto;
  }
}
