@import "../../../../../../../../scss/index.scss";

.rightNavbar {
  margin-top: 3rem;

  .info {
    font-size: 1.5rem;
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 0.5rem;
    margin-top: 2rem;

    .status {
      background-color: $green-light;
      color: $green-dark;
      padding: 0.3rem 0.6rem;
      border-radius: $radius-sm;
    }

    .conditionalTag {
      color: $orange-dark;
      background-color: $orange-light;
    }
    .daysNum {
      color: $grey-dark-1;
      padding: 0.3rem 0.6rem;
    }
  }
  .price {
    font-size: 4.2rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin: 1rem 0 2rem 0;
  }
  .dropdownContent {
    display: flex;
    .content {
      width: 15rem;
      color: $grey-dark-1;
      margin-left: 1rem;
    }
  }
}
