@import "../../../../../../scss/index.scss";

.descriptionContainer {
  margin-top: 4rem;
  padding: 0 3rem;
  .title {
    font-size: 3rem;
    color: $grey-dark-2;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .location {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 4rem;
    border-top: 1px solid $grey-light-2;
    border-bottom: 1px solid $grey-light-2;
    padding: 2rem 0;

    div {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 1rem;
      }
      span {
        display: grid;
        align-self: center;
        font-size: 1.5rem;
        color: $grey-dark-2;
      }
    }
  }

  .details {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin-bottom: 2rem;
    border-bottom: 1px solid $grey-light-2;
    padding: 1.2rem 0;

    div {
      display: flex;
      align-items: center;

      .icon {
        color: $grey-dark-2;
        --ionicon-stroke-width: 16px;
        margin-right: 1rem;
        height: 4rem;
        width: 4rem;
      }

      span {
        display: grid;
        align-self: center;
        font-size: 1.5rem;
        color: $grey-dark-2;
      }
    }
  }

  .description {
    margin-right: 2rem;
    font-size: 1.8rem;
    color: $grey-dark-2;
  }

  .offerForm {
    display: none;

    @media only screen and (max-width: $large) {
      display: flex;
      margin: 4rem 0;
      width: 100%;
    }
  }
}
