@import "../../../scss/index.scss";
@import "../../../scss/variables.scss";

.container {
  position: relative;
  padding: 4rem;
  display: flex;
  height: 100%;
  background-color: white;

  // .leftSection {
  //   background-color: #e9e3f2;
  //   border-radius: 1rem;
  //   width: 50%;
  //   height: 100%;

  //   @media screen and (max-width: $large) {
  //     display: none;
  //   }
  // }

  .rightSection {
    width: 100%;
    height: 100%;
    @include show-scrollbar;
    overflow-y: scroll;
    padding-right: 1rem;

    .logo {
      display: none;
    }

    @media screen and (max-width: $large) {
      width: 100%;

      .logo {
        display: block;
        object-fit: cover;
        padding: 0 4rem;
        margin-bottom: -3rem;
      }
    }
  }

  .closeIcon {
    position: absolute;
    top: 4rem;
    right: 6rem;
    cursor: pointer;
    ion-icon {
      height: 2.82rem;
      width: 2.82rem;
      color: $grey-dark-1;
    }
  }
}
