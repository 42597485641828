@import "../../../../../scss/index.scss";

.tourDetails {
  .details {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 1rem;

    .label {
      color: $grey-dark-2;
      font-size: 2.2rem;
      font-weight: 600;
      margin-right: 2rem;
    }

    .tag {
      display: flex;
      flex-direction: row;
      background-color: $yellow-light;
      color: $yellow-dark;
      border-radius: 5px;
      padding: 0 1rem;

      .icon {
        margin-right: 1rem;
        display: flex;
        align-self: center;
      }
      span {
        display: flex;
        align-self: center;
        font-size: 1.2rem;
      }
    }
  }

  .container {
    position: relative;
  }

  .range {
    text-align: center;
    font-size: 1.5rem;
    padding: 0.5rem 2rem;
    cursor: pointer;

    &:hover {
      border-radius: 5px;
      background-color: $grey-light-1;
    }
  }

  .firstRow {
    display: grid;
    grid-template-columns: 50% 22% 22%;
    column-gap: 3rem;
    margin-bottom: 2rem;
  }

  .secondRow {
    display: grid;
    grid-template-columns: 50% 47%;
    column-gap: 3rem;
    margin-bottom: 2rem;

    .times {
      display: grid;
      grid-template-columns: 45% 10% 45%;

      .hyphen {
        display: flex;
        align-self: center;
        justify-content: center;
        margin: 0 1rem;
      }
    }
  }

  .textarea {
    margin: 0 0 2rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 14rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .btns {
    display: flex;

    .btn {
      display: grid;
      width: 100%;
      margin-top: 1rem;
      color: $grey-dark-1;
      text-transform: capitalize;
      --color-hover: $grey-dark-1;
      --background-hover: white;

      .icon {
        width: 2rem;
        height: 2rem;
        color: $grey-dark-1;
        --ionicon-stroke-width: 56px;
      }
    }

    .back {
      justify-content: start;
    }

    .close {
      justify-content: end;
    }
  }
}
