@import "../../../../../scss/index.scss";

.career {
  margin: 2rem 1.5rem;
  color: $grey-dark-2;

  .title {
    margin-bottom: 1rem;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .row {
    border-top: 0.1rem solid $grey-light-2;
    padding-top: 1rem;
    font-size: 1.5rem;

    .label {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
