@import "../../../../../scss/index.scss";

.tourAttendees {
  height: 100%;
  margin: 2rem;

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
    margin-bottom: 2rem;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    .title {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .inputs {
    display: grid;
    grid-template-columns: auto;
    row-gap: 2rem;

    ion-item {
      --border-style: solid !important;
      --border-width: 0.1rem !important;
      --border-radius: 5px !important;
      font-size: 1.5rem;

      ion-label {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        color: $grey-dark-1 !important;

        ion-icon {
          display: flex;
          align-self: center;
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      ion-select {
        color: $grey-dark-2 !important;
      }
    }
  }

  .addItem {
    --background: #f9f9f9;
  }

  .button {
    height: 5rem;
    margin-top: 2rem;
    --background: #e9e3f2;
    --background-activated: #7158ad;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: 0;
    text-transform: initial;
    margin-bottom: 10rem;
  }

  .guests {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-size: 1.2rem;
    .chip {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      border: 1px solid $purple-dark-1;
      background-color: $purple-light-2;
      border-radius: $radius-sm;
      white-space: nowrap;
      .icon {
        color: $purple-dark-1;
        cursor: pointer;
        margin-left: 1rem;
        font-size: 1.5rem;
      }
    }
  }
}
