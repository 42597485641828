@import "../../../../scss/index.scss";

.item {
  display: grid;
  grid-template-columns: 10% 15% 15% 10% 15% 5% 10% 6% 6%;
  column-gap: 1rem;
  border-top-color: $purple-light-2;
  border-top-width: 0.1rem;
  border-top-style: solid;
  padding: 2rem 0;
  color: $grey-dark-1;
  font-size: 1.5rem;

  div {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .title {
    color: $grey-dark-2;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }

  .status {
    display: flex;
    justify-self: center;
    width: max-content;
    padding: 0 1rem;
  }

  .green {
    background-color: $green-light;
    color: $green-dark;
    border-radius: 5px;
  }

  .brown {
    background-color: $yellow-medium;
    color: $yellow-dark;
    border-radius: 5px;
  }

  .yellow {
    color: $yellow-dark;
    background-color: $yellow-light;
    border-radius: 5px;
  }

  .orange {
    background-color: $orange-light;
    color: $orange-dark;
    border-radius: 5px;
  }

  .purple {
    background-color: $purple-light-2;
    color: $purple-medium-2;
    border-radius: 5px;
  }

  .icon {
    cursor: pointer;
  }
}
