@import "../../../../scss/index.scss";

.clickable {
  display: flex;

  .title {
    font-size: 2.2rem;
    color: $grey-dark-2;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 1rem;
  }

  .icon {
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    cursor: pointer;

    .svg {
      stroke: $purple-medium-2;
      stroke-width: 1.2px;
      order: 2;
      height: 3rem;
      width: 3rem;
    }
  }
}

.note {
  display: flex;
  align-items: center;
  color: $grey-dark-1;
  font-size: 1.5rem;
  // margin-left: 1rem;

  a {
    color: $grey-dark-1;
    text-decoration: underline;
    cursor: pointer;
    padding: 0.3rem;
  }
}

.container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.app {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border: 1px solid $line-color;
  border-style: dashed;
  margin: 1.5rem;
  padding: 1rem;
  border-radius: $radius-normal;

  .title,
  .note,
  .icon {
    justify-content: center;
  }

  .note {
    margin: 0;
  }
  .icon {
    margin: 1rem 0;
  }
}
