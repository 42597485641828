@import "../../../../scss/app.scss";

.drawBtns {
  position: absolute;
  top: calc(8rem + 20px);
  top: calc(8rem + constant(safe-area-inset-top));
  top: calc(8rem + env(safe-area-inset-top));
  left: 1.5rem;
  width: calc(100% - 3rem);
  height: auto;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    width: 100%;
    flex: 1;
    display: flex;
    margin-top: 1.2rem;
  }
}

.top {
  position: absolute;
  top: calc(1.5rem + 20px);
  top: calc(1.5rem + constant(safe-area-inset-top));
  top: calc(1.5rem + env(safe-area-inset-top));
  width: 95%;
  left: 2.5%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 500;

  @media screen and (max-width: 350px) {
    width: 100%;
    left: 0;
    justify-content: center;
  }

  .searchFilters {
    background-color: white;
    box-shadow: $box-shadow-map-button;
    border-radius: $radius-normal;
    font-size: 1.5rem;
    color: $grey-dark-2;
    display: flex;
    align-items: center;
    height: 4.5rem;
    flex: 1;
    margin-right: 2rem;

    @media screen and (max-width: 350px) {
      margin-right: 0;
    }

    .search {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      font-weight: 500;
      padding: 1.2rem;
      padding-right: 5rem;
      flex: 1;
      ion-icon {
        stroke: $grey-dark-2;
        font-size: 2.1rem;
        margin-right: 0.75rem;
      }
    }
    .filters {
      display: flex;
      align-items: center;
      border-left: 1px solid $grey-light-2;
      padding: 1.2rem;
      ion-icon {
        stroke: $grey-dark-2;
        font-size: 2.1rem;
      }
    }
    .badge {
      position: relative;

      &::after {
        content: "";
        border-radius: 50%;
        border: 1px solid white;
        width: 0.65rem;
        height: 0.65rem;
        position: absolute;
        top: 0.75rem;
        right: 1rem;
        z-index: 5000;
        background-color: $purple-medium-2;
      }
    }
  }
  .status {
    position: relative;
    box-shadow: $box-shadow-map-button;
    border-radius: $radius-normal;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    height: 4.5rem;

    .segment {
      min-height: 4.5rem;
      --background: white;
      .btn {
        --background: white;
        --color: #33244c;
        //--color-checked: var(--cl);
        --color-checked: white;
        &::part(indicator-background) {
          background-color: #33244c;
        }
      }

      .leftSegment {
        &::part(indicator-background) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .rightSegment {
        &::part(indicator-background) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    // .tabBg {
    //   position: absolute;
    //   height: calc(100% - 6px);
    //   width: 50%;
    //   background-color: $purple-dark-1;
    //   transition: 0.6s cubic-bezier(0.02, 1.23, 0.89, 1.03);
    //   top: 3px;
    //   left: 3px;
    // }

    // .bgLeft {
    //   border-top-left-radius: $radius-normal;
    //   border-bottom-left-radius: $radius-normal;
    // }

    // .bgRight {
    //   transform: translateX(calc(100% - 6px));
    //   border-top-right-radius: $radius-normal;
    //   border-bottom-right-radius: $radius-normal;
    // }

    // .tab {
    //   display: flex;
    //   align-items: center;
    //   font-weight: 500;
    //   padding: 1.2rem;
    //   color: $purple-dark-1;
    // }

    // .tabLeft {
    //   width: 50%;
    //   border-top-left-radius: $radius-normal;
    //   border-bottom-left-radius: $radius-normal;
    //   text-align: center;
    //   padding-right: 2rem;
    //   transition: 0.6s all;
    //   // background-color: red;
    // }
    // .tabRight {
    //   width: 50%;
    //   border-top-right-radius: $radius-normal;
    //   border-bottom-right-radius: $radius-normal;
    //   transition: 0.6 all;
    // }

    // .tabActive {
    //   z-index: 10;
    //   color: white;
    //   font-weight: 400;
    // }
  }
}
.right {
  z-index: 500;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  .btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 1.85rem;
    border-radius: $radius-sm;
    box-shadow: $box-shadow-map-button;
    transition: all 0.2s;

    ion-icon {
      stroke: $grey-dark-2;
      color: $grey-dark-2;
      font-size: 2.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }
  }

  .activeBtn {
    background-color: $purple-dark-1;

    ion-icon {
      color: white;
      stroke: white;
    }
  }

  .disabled {
    pointer-events: none;
    background-color: $grey-light-1;
    ion-icon {
      color: $grey-light-2;
      stroke: $grey-light-2;
    }
  }
}
