@import "../../../../../../../scss/index.scss";

.tourAttendees {
  margin-top: 1rem;

  .attendees {
    border-bottom: 0.1rem solid $grey-light-2;
    margin: 0 2rem 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    color: $grey-dark-2;

    &:last-of-type {
      border-bottom: 0.1rem solid $grey-light-2;
    }

    .bold {
      text-align: end;
      font-weight: 600;
    }

    .link {
      display: flex;
      justify-content: flex-end;
      text-decoration: underline;

      ion-icon {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
      }
    }
  }

  ion-label {
    text-align: left;
  }

  .attendee {
    border-bottom: 0.1rem solid $grey-light-1;
    margin: 0 2rem 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    color: $grey-dark-2;

    &:last-of-type {
      border-bottom: 0.1rem solid $grey-light-2;
    }

    .bold {
      text-align: right;
      font-weight: 600;
    }

    .link {
      display: flex;
      justify-content: flex-end;
      text-decoration: underline;

      ion-icon {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
      }
    }
  }
}
