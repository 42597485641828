@import "../../scss/index.scss";

.noResult {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
  .icon {
    font-size: 1.7rem;
    margin-right: 0.5rem;
  }
}
