@import "../../../scss/app.scss";

.toolbar {
  --background: #f4f4f7;
  .title {
    font-size: 2rem;
    color: $grey-dark-2;
  }

  .btn {
    --color: #707070;
    --icon-font-size: 1.8rem;
    --icon-padding-end: 0.8rem;
    font-size: 1.5rem;
    .label {
      font-size: 1.5rem;
    }
  }
}

ion-list {
  height: 100%;
  overflow: scroll;
}
.content {
  overflow-y: scroll;

  .resultsBtnContainer {
    padding: 2rem 0.5rem;
    position: sticky;
    bottom: 0;
    left: 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: stretch;
    // justify-content: center;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 2rem;
    margin: 0 auto;
    align-items: center;
    z-index: 150;
    background-color: white;
    box-shadow: $box-shadow;
  }

  .segments {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: 10;
    background-color: white;
    box-shadow: $box-shadow;

    .segment {
      border: 1px solid #7158ad;
      --background: transparent;

      ion-segment-button {
        --color: #7158ad;
        --color-checked: #7158ad;
        font-size: 1.5rem;
        --indicator-color: #e9e3f2;
        --indicator-box-shadow: none;
        --border-radius: 0;
        --border-width: 1px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        &:first-of-type {
          border-right: 1px solid #7158ad;
        }
      }
    }
  }

  .boxshadow {
    box-shadow: $box-shadow;
  }

  .item {
    display: flex;
    flex-direction: column;

    .label {
      color: $grey-dark-2;
      font-weight: 600;
      font-size: 1.5rem;
    }

    .labelTag {
      color: $purple-medium-1;
      font-weight: 400;
      font-size: 1.5rem;
      text-align: right;
      min-width: 12rem;
    }
  }

  .activeNeighbourhood {
    border-left: 3px solid $purple-medium-2;
  }
}
