@import "../../../scss/index.scss";

.page {
  --background: none;
  background-color: $primary-bg-color;
}
.content {
  --background: #f4f4f7;

  .appAgentTours {
    background-color: $purple-light-1;
    height: 100%;

    .body {
      padding: 2rem;
      background-color: $purple-light-1;
      .cards {
        height: 100%;
        display: grid;
        grid-template-rows: auto;
        row-gap: 2rem;

        .archiveSubtitle {
          margin: 0rem 1rem 0;
          font-size: 1.5rem;
          color: $grey-dark-1;
        }

        .card {
          background-color: white;
          border-radius: $radius-md;
          box-shadow: $box-shadow-grey;
          padding: 1rem 1.5rem;
          margin: 0 1rem;
          // h3 {
          //   font-size: 1.8rem;
          //   font-weight: 600;
          //   margin-bottom: -0.5rem;
          // }
          p {
            font-size: 1.5rem;
            color: $grey-dark-1;
          }
        }
      }
    }
  }
}
.modal {
  background-color: white;
  height: 100%;
}
