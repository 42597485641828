@import "../../../scss/app.scss";

.clientTourDetails {
  .loading {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .appHeader {
    position: sticky;
    top: 0;

    .toolbar {
      --background: #f4f4f7;

      .title {
        display: flex;
        justify-content: center;
        font-size: 2.2rem;
        color: $grey-dark-2;
        font-weight: 600;
      }

      .button {
        --color: #707070;
        --icon-font-size: 1.8rem;
        --icon-padding-end: 0.8rem;
        font-size: 1.5rem;
        margin-left: 1rem;
        text-transform: initial;

        .label {
          font-size: 1.5rem;
        }
      }

      .hidden {
        --color: #f4f4f7 !important ;
        ion-icon {
          color: $purple-light-1 !important;
        }
      }
    }
  }

  .details {
    margin-top: 2rem;

    .agentNote {
      .label {
        position: relative;
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem 1rem;
        margin-bottom: 2rem;
        border-bottom: 0.1rem solid $grey-light-2;
      }
      .note {
        font-size: 1.5rem;
        color: $grey-dark-1;
        margin: 0 1rem 0 2rem;
      }
    }

    .info {
      margin-left: 2rem;

      .rating {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0.5rem;
      }

      .address {
        font-size: 2.2rem;
        font-weight: 600;
        color: $grey-dark-2;
      }

      .postalCode {
        font-size: 1.5rem;
        color: $grey-dark-1;
      }
    }

    .directionBtn {
      margin: 1rem 2rem;
      --border-color: #007b94;
      --border-width: 0.1rem;
      --border-style: solid;
      --background: #e0faff;
      --background-activated: #007b94;
      --color: #007b94;
      --color-activated: #e0faff;
      display: flex;
      align-items: center;

      ion-icon {
        margin-right: 0.5rem;
      }
    }

    .mediaElements {
      margin-top: 3rem;

      .label {
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem 1rem;
        margin-bottom: 2rem;
        border-bottom: 0.1rem solid $grey-light-2;
      }

      .elements {
        margin: 0 1rem;
      }
    }

    .overview {
      margin-top: 2rem;

      .label {
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem 1rem;
        border-bottom: 0.1rem solid $grey-light-2;
      }

      .item {
        border-bottom: 0.1rem solid $grey-light-2;
        margin: 0 1rem;
        padding: 1rem 0;
        font-size: 1.5rem;
        color: $grey-dark-2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bold {
          display: flex;
          font-weight: 600;

          ion-icon {
            display: flex;
            align-self: center;
            margin-left: 0.5rem;
            height: 2rem;
            width: 2rem;
          }
        }
        .underline {
          text-decoration: underline;
        }
      }
    }

    .overalRating {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 2rem 1rem;
      height: 12rem;
      background-color: $grey-light-1;

      .label {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $grey-dark-2;
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
      margin-bottom: 1.5rem;
      color: $grey-dark-1;
      border-bottom: 0.1rem solid $grey-light-2;
      font-size: 1.5rem;

      .link {
        color: $grey-dark-2;
        flex: 1;
      }

      .previous {
        display: flex;
        align-items: center;

        ion-icon {
          display: flex;
          align-self: center;
          margin-right: 0.5rem;
          width: 2.2rem;
          height: 2.2rem;
        }
      }

      .next {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        ion-icon {
          display: flex;
          align-self: center;
          margin-left: 0.5rem;
          width: 2.2rem;
          height: 2.2rem;
        }
      }
    }
  }
}
