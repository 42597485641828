@import "../../scss/index.scss";

.tours {
  display: grid;
  grid-column: 2/-1;
  grid-row: 2/-1;
  grid-template-rows: min-content 1fr;
  overflow-y: scroll;
  @include show-scrollbar;
  height: 100%;

  .cardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .header {
    display: grid;
    grid-template-columns: 10% 15% 15% 10% 15% 5% 10% 6% 6%;
    column-gap: 1rem;
    margin: 1.5rem 0;
    color: $grey-dark-2;

    div {
      display: flex;
      align-self: center;
      font-size: 1.5rem;
      font-weight: 500;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .icon {
      font-size: 1.5rem;
      margin-left: 1rem;
    }
  }

  .archiveSubtitle {
    margin-left: 4rem;
    color: $grey-dark-1;
    font-size: 1.5rem;
  }
}
