.MuiFormControl-root {
  // border: 1px solid #e5e5e5 !important;
  background-color: #f9f9f9 !important;
  border-radius: 5px !important;
  // width: 20rem !important;
}
.MuiOutlinedInput-input {
  padding: 1.5rem !important;
  font-size: 1.5rem !important;
  background-color: white !important;
  color: #363636 !important;
  height: 1.5rem !important;
}
.MuiIconButton-root {
  padding: 0 !important;
}
.MuiInputBase-adornedStart {
  padding-left: 5px !important;
}
.MuiIconButton-root {
  padding: 0 !important;
}
.MuiSvgIcon-root {
  height: 2.4rem !important;
  width: 4rem !important;
}
.MuiPickersDay-current {
  color: #7158ad !important;
}
.MuiPickersDay-daySelected {
  background-color: #7158ad !important;
  color: white !important;
}
