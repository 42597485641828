@import "../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .iconContainer {
    position: relative;
    height: 15rem;
    width: 15rem;
    border-radius: 50%;
    background-color: $purple-light-3;

    .icon {
      position: absolute;
      @include center-horizontally;
      font-size: 9rem;
      color: $purple-medium-2;
      --ionicon-stroke-width: 1rem;
    }
  }

  .textContainer {
    color: $grey-dark-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      margin-top: 3rem;
      font-weight: 600;
    }
    .text {
      text-align: center;
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
}
