@import "../../../scss/index.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  background-color: white;
  cursor: pointer;

  .outerCircle {
    position: absolute;
    @include center-vertically;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid $grey-dark-1;
    background-color: white;
    border-radius: 50%;
  }

  .innerCircle {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: white;
    border-radius: 50%;
    transition: all 0.3s;
    transform: scale(0);
  }

  .selected {
    background-color: $purple-medium-2;
    transform: scale(1);
    transition: all 0.1s;
  }
}
