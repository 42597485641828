@import "../../../../../scss/app.scss";

.tourDetails {
  height: 100%;
  margin: 2rem;

  .row {
    display: flex;
    align-items: center;
  }

  .item {
    border: $input-border;
    border-radius: $radius-sm;
    --inner-padding-top: 0;
    --padding-top: 0;
    font-size: 1.5rem;
    box-shadow: none !important;

    ion-label {
      --color: #707070;
    }
  }

  .picker {
    color: $grey-dark-1;
  }

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
    margin-bottom: 2rem;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;

    .title {
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  .textarea {
    margin: 2rem 0;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 12rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .button {
    height: 5rem;
    --background: #e9e3f2;
    --background-activated: #7158ad;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: 0;
    text-transform: initial;
    margin-bottom: 10rem;
  }

  ion-datetime::part(text) {
    color: $grey-dark-2 !important;
    font-weight: 600;
  }
}
