@import "../../scss/index.scss";

.datepicker {
  display: grid;
  grid-template-columns: max-content max-content auto max-content auto;
  column-gap: 2rem;
  margin-bottom: 2rem;

  .closeIcon {
    display: grid;
    align-items: center;
    width: 2rem;

    ion-icon {
      height: 2rem;
      width: 2rem;
      color: $grey-dark-1;
      cursor: pointer;
    }
  }

  .range {
    padding: 0.5rem 2rem;
    cursor: pointer;

    &:hover {
      border-radius: 5px;
      background-color: $grey-light-1;
    }
  }
  .to {
    display: grid;
    justify-content: center;
    align-self: center;
    color: $grey-dark-1;
  }
  .text {
    width: 11rem;
    padding: 1rem;
    text-align: center;
  }
}

.hide {
  display: none;
}
