@import "../../../scss/app.scss";
.page {
  --background: #e9e3f2;
  .home {
    height: 100%;
    width: 100%;
    --background: #e9e3f2;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      display: flex;
      align-items: center;
      border-radius: $radius-lg;
      border: 1px solid $grey-dark-2;
    }

    .transparent {
      background-color: transparent;
      padding: 1rem 2.5rem;
      color: $purple-medium-2;
      font-size: 1.5rem;
      font-weight: 600;
      border-color: $purple-medium-2;

      ion-icon {
        font-size: 1.8rem;
        margin-right: 0.5rem;
      }
    }

    .white {
      position: relative;
      justify-content: center;
      width: 60%;
      height: 5rem;
      background-color: white;
      padding: 1rem;
      color: $purple-dark-1;
      font-size: 1.5rem;
      font-weight: 500;
      box-shadow: 1px 1px 15px #33244c0d;
      border: none;

      ion-icon {
        // position: absolute;
        // left: 10%;
        margin-right: 1rem;
        color: $purple-medium-1;
        font-size: 2.2rem;
      }

      .homeSale {
        display: grid;
        width: max-content;
        @media only screen and (max-width: $medium) {
          width: 55%;
        }
      }
    }

    .top {
      height: 100%;
      width: 100%;
      background-color: $purple-light-2;
      display: flex;
      flex-direction: column;
      background: url("../../../assets/img/apphomepage.jpg") bottom center
        $purple-light-2;
      background-size: auto 25rem;
      background-repeat: repeat-x;
      @include safe-top-padding;

      .header {
        // margin-top: 1rem;
        padding: 1rem 2.5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          display: flex;
          justify-content: flex-start;

          ion-icon {
            height: 8rem;
            width: 12rem;
          }
        }

        .welcome {
          flex: 1;
          padding-top: 1rem;
          height: 5rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 2rem;
          color: $purple-medium-2;
          font-weight: 500;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        margin: auto 0;
      }

      .title {
        // margin-top: 4rem;
        font-size: 3rem;
        font-weight: 600;
        color: $purple-dark-1;
        text-align: center;
      }

      .subtitle {
        text-align: center;
        font-size: 1.8rem;
        color: $purple-medium-2;
        font-weight: 500;
      }

      .btns {
        display: flex;
        margin-top: 3rem;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;

        & .white:not(:last-of-type) {
          margin-bottom: 1.4rem;
        }
      }

      .scrollContainer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 3rem 0 4rem;
        padding: 0.5rem;
        border-radius: 50%;
        padding: 2rem;
      }

      .scroll {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 3rem 0 4rem;
        font-size: 3rem;
        color: $grey-dark-2;
        padding: 0.5rem;
        background-color: white;
        border-radius: 50%;
        padding: 1rem;

        &::after {
          content: "";
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          border: 0.6rem solid #a392c9;
          position: absolute;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: ring 1.5s infinite;
        }

        @keyframes ring {
          0% {
            width: 4rem;
            height: 4rem;
            opacity: 1;
          }
          100% {
            width: 6rem;
            height: 6rem;
            opacity: 0;
          }
        }

        .chevron {
          width: 2rem;
          height: 2rem;
          color: $grey-dark-2;
        }
      }
    }

    .sections {
      background-color: $primary-bg-color;
      @include safe-top-padding;
    }

    .note {
      padding: 3rem;
      background-color: $purple-light-2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-size: 2.2rem;
        font-weight: 600;
        color: $purple-dark-1;
        text-align: center;
        line-height: 1.3;
      }

      .subtitle {
        font-size: 1.5rem;
        text-align: center;
        color: $purple-dark-1;
        margin: 1.5rem 0;
      }

      .clearBtn {
        height: 5rem;
        width: 90%;
        --border-radius: 25px;
        --backgorund: transparent;
        --background-focused: transparent;
        --background-activated: transparent;
        --background-hover: transparent;
        color: $purple-dark-2;
        --border-width: 0.1rem;
        --border-color: #110d1b;
        font-size: 1.5rem;
        text-transform: initial;
        letter-spacing: normal;
      }
    }

    .cards {
      background-color: $primary-bg-color;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      padding-top: 2.5rem;

      .textCard {
        display: flex;
        flex-direction: column;
        .title {
          text-align: center;
          font-size: 2.2rem;
          color: $grey-dark-2;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        .text {
          text-align: center;
          font-size: 1.5rem;
          color: $grey-dark-2;
          margin: 0 2rem;
          margin-bottom: 1rem;
        }
      }
    }

    .tags {
      background-color: $primary-bg-color;
      margin: 0;
      display: flex;
      flex-direction: column;
      .tag {
        display: flex;
        align-items: center;
        padding: 0 3rem;
        margin-bottom: 2rem;

        img {
          height: 75px;
          margin-right: 1.5rem;
        }

        .title {
          font-size: 1.5rem;
          color: $grey-dark-2;
          font-weight: 500;
        }
      }

      .agentBtn {
        height: 5rem;
        --border-radius: 25px;
        border-radius: 25px;
        --background: #dff7f0 !important;
        --background-activated: #dff7f0 !important;
        --background-focused: #dff7f0 !important;
        --background-hover: #dff7f0 !important;
        background-color: #e0faff;
        color: $green-dark;
        --border-width: 0.1rem;
        --border-color: #1f7a60 !important;
        border-color: #1f7a60;
        font-size: 1.5rem;
        margin: 3rem;
        margin-bottom: 4rem;
        text-transform: initial;
        letter-spacing: normal;
      }

      .commission {
        display: flex;
        padding: 0 3rem;
        font-size: 1.2rem;
        color: $grey-dark-1;
        margin-bottom: 6rem;
      }
    }
  }
}
