@import "../../../scss/index.scss";

.container {
  width: 55.4rem;

  .pic {
    display: grid;
    justify-self: center;
    justify-content: center;
    margin: 2rem auto;
    background-color: $purple-light-2;
    width: 8rem;
    height: 8rem;
    border-radius: 50px;

    ion-icon {
      height: 4.8rem;
      width: 4.8rem;
      border: 0.2rem solid $purple-medium-2;
      color: $purple-medium-2;
      border-radius: $radius-lg;
      display: grid;
      justify-self: center;
      align-self: center;
    }

    .green {
      height: 5rem;
      width: 5rem;
      border: 0.2rem solid $green-dark;
      color: $green-dark;
      background-color: $green-light;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    color: $grey-dark-2;
    font-size: 2.6rem;
    font-weight: 600;
  }

  .subtitle {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    color: $grey-dark-1;
    font-size: 1.5rem;
    font-weight: 400;
  }
  .btn {
    margin-top: 2rem;
  }
}
