@import "../../../scss/index.scss";

.container {
  width: 58rem;

  .header {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .content {
    margin: 2rem 0 2rem;
    display: flex;
    flex-direction: column;

    .details {
      color: $grey-dark-1;
      font-size: 1.5rem;
      margin: 2rem 0;

      .bold {
        color: $grey-dark-2;
      }
    }
    .schools {
      display: flex;
      flex-direction: column;
      .school {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .schoolIcon {
          display: flex;
          align-items: center;
          background-color: $green-light;
          border-radius: $radius-sm;
          color: $green-dark;
          padding: 0.3rem 0.75rem;
          margin-right: 1rem;

          .icon {
            font-size: 2rem;
          }

          .title {
            color: $green-dark;
            font-size: 1.5rem;
            font-size: 400;
            margin-left: 0.75rem;
          }
        }

        .name {
          color: $grey-dark-1;
          font-size: 1.5rem;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .label {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .notify {
      color: $grey-dark-1;

      span {
        color: $grey-dark-2;
        font-weight: 600;
      }
    }
  }

  .note {
    width: 34rem;
    margin: 1rem auto 0;
    color: $grey-dark-1;
    font-size: 1.5rem;
    text-align: center;

    a {
      color: $grey-dark-1;
      text-decoration: underline;
    }
  }
  .error {
    padding-top: 1.5rem;
    font-size: 1.3rem;
    color: $orange-medium;
  }
}
