@import "../../../../scss/index.scss";

.invite {
  margin: 2rem;

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
    margin-bottom: 1rem;
  }

  .shareButton {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    column-gap: 2rem;

    .button {
      padding: 1.5rem 0;
      margin-bottom: 2rem;
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-rows: repeat(2, 50%);
      color: $grey-dark-2;
      background-color: #f9f9f9;
      border-radius: 10px;
      width: 100%;
      height: 8rem;

      .linkIcon {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        width: 3rem;
        height: 3rem;
      }
      .mailIcon {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
      }
      div {
        font-size: 1.5rem;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    border-top: 0.1rem solid $line-color;
    padding-top: 1rem;
    margin-top: 1rem;
    color: $grey-dark-2;

    ion-col {
      display: flex;

      .icon {
        display: flex;
        align-self: center;
        height: 3rem;
        width: 3rem;
        margin-right: 2rem;
      }
    }
  }
}

.lastRow {
  border-bottom: 0.1rem solid $line-color;
  padding-bottom: 1rem;
}
