@import "../../../../../scss/app.scss";

.label {
  font-size: 1.5rem !important;
  color: $grey-dark-1 !important;
}

.selected {
  color: #7158ad !important;
}

.radio {
  --color: #7158ad !important;
  --color-checked: #7158ad !important;
}
