@import "../../../../../scss/app.scss";

.item {
  padding: 1.5rem 1rem 0 0;

  .row {
    display: flex;
    align-items: center;
  }
  .label {
    color: $grey-dark-2 !important;
    font-weight: 600 !important;
    font-size: 1.5rem !important;
  }

  .input {
    border: 1px solid $grey-light-2;
    border-radius: $radius-sm;
    height: 4rem;
    font-size: 1.5rem;
    color: $purple-dark-1;
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  .tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: auto;

    .chipContainer {
      background-color: $purple-light-3;
      border-radius: $radius-sm;
      flex: 1;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      .chip {
        padding: 0 0.5rem;
        --background: transparent;
        --color: #7158ad;
        display: flex;

        .chipLabel {
          flex: 1;
        }
        .chipIcon {
          justify-self: flex-end;
        }
      }
    }
  }
}
