@import "../../scss/index.scss";

.listings {
  grid-row: 2/-1;
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 3rem 3rem 3rem;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @include hide-scrollbar;
}

.listView {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row: 2/-1;
  grid-column: 1/-1;
  width: 100%;
  height: 100%;
  padding: 0rem 3rem 3rem 3rem;
  overflow-y: scroll;
  @include show-scrollbar;

  .cards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 2rem;
    grid-row: 2/-1;
    grid-column: 1/-1;

    @media screen and (max-width: $largest) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: $search-bp1) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $search-bp3) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $medium) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $small) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: $smallest) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.comingSoon {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .animation {
    width: 35rem;
    margin-bottom: 2rem;
  }

  .header {
    color: $grey-dark-2;
    margin-bottom: 1rem;
  }

  .content {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    color: $grey-dark-1;
  }
}

.mobileView {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row: 2/-1;
  grid-column: 1/-1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.hide {
  display: none;
}

@media screen and (max-width: $large) {
  .listings {
    display: none;
  }
}
