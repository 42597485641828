@import "../scss/app.scss";

.app {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.web {
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.tab {
  --background: #110d1b;
  //--color-selected: #927bc1;
  --color: #dedaec;
  --padding-bottom: 0.4rem;
  --padding-top: 0.4rem;
  --ripple-color: #dedaec;
  --background-focused-opacity: 0;
  --overflow: visible;
  .icon {
    font-size: 2rem;
  }

  ion-tab-button:not(.tab-selected)::part(native):hover {
    color: #dedaec;
  }

  .label {
    font-weight: 300;
  }

  .badge {
    background-color: #ff5500;
  }

  .iosBadge {
    position: absolute;
    top: 15%;
    right: 30%;
    width: 0.8rem;
    height: 0.8rem;
    background-color: #ff5500;
    border-radius: 50%;
  }
}

ion-tab-button[aria-selected="true"] {
  box-shadow: 0 -2px 0 0 #927bc1 inset;
  --color-selected: #927bc1;
}

.error {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.success {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.notification {
  --background: #7158ad;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.connectionContent {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  text-align: center;
  color: #363636;

  .svg {
    width: 30rem;
    height: 30rem;
  }
  .title {
    font-size: 2.2rem;
    color: $grey-dark-2;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
    text-align: center;
    color: $grey-dark-1;
    padding: 0 1rem;
    &:last-of-type {
      margin-bottom: 2rem;
      margin-top: 1rem;
    }
  }
}
