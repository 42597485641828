@import "../../scss/index.scss";

.tag {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  align-self: center;
  justify-self: end;
  width: max-content;
  margin-right: 1rem;

  .icon {
    margin-right: 1rem;
    display: flex;
    align-self: center;
  }
  span {
    display: flex;
    align-self: center;
    font-size: 1.2rem;
  }
}

.full {
  width: 100%;
  justify-content: center;
}

.visible {
  background-color: $yellow-light;
  color: $yellow-dark;
}
.invisible {
  background-color: $orange-light;
  color: $orange-dark;
}
