.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.exit {
  opacity: 1;
  // transform: translateY(0);
}

.exitActive {
  opacity: 0;
  // transform: translateY(100%);
  transition: opacity 200ms ease-in-out;
}
