@import "../../../scss/index.scss";

.medias {
  .appHeader {
    position: sticky;
    top: 0;

    .toolbar {
      --background: #f4f4f7;

      .title {
        display: flex;
        justify-content: center;
        font-size: 2.2rem;
        color: $grey-dark-2;
        font-weight: 600;
      }

      .backButton {
        display: flex;
        justify-content: flex-start;
        --color: #707070;
        --icon-font-size: 1.8rem;
        --icon-padding-end: 0.8rem;
        font-size: 1.5rem;
        margin-left: 1rem;
        padding: 0;
        text-transform: initial;

        .label {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.greyBackground {
  --background: #f4f4f7;
}

.addRecording {
  position: sticky;
  bottom: 0;
  background-color: $purple-light-1;
  border-top: 0.1rem solid $grey-light-2;
  height: 9rem;
  display: flex;
  align-content: center;
  transition: all 0.3s;

  .recordingBtn {
    display: flex;
    align-content: center;
    width: 100%;

    --background: #e9e3f2;
    --background-activated: #7158ad;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    --border-style: solid;
    --border-width: 0.1rem;
    --border-color: #7158ad;
    display: flex;
    align-self: center;
    align-content: center;
    margin: 0 2rem;
    font-size: 1.5rem;
    height: 5rem;

    ion-icon {
      display: flex;
      align-self: center;
      margin-right: 0.5rem;
      width: 2rem;
      height: 2rem;
    }
  }
}

.hideBtn {
  display: none;
}

.showBtn {
  display: flex;
}

.addPhoto {
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: $purple-light-1;
  border-top: 0.1rem solid $grey-light-2;
  height: 9rem;
  display: flex;
  align-content: center;

  .photoBtn {
    display: flex;
    align-content: center;
    width: 100%;
    --background: #e0faff;
    --background-activated: #007b94;
    --color: #007b94;
    --color-activated: #e0faff;
    --border-style: solid;
    --border-width: 0.1rem;
    --border-color: #007b94;
    display: flex;
    align-self: center;
    align-content: center;
    margin: 0 2rem;
    font-size: 1.5rem;
    height: 5rem;

    ion-icon {
      display: flex;
      align-self: center;
      margin-right: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }
}
