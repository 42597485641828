@import "../../../../../scss/index.scss";

.tourAttendees {
  .details {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    margin-bottom: 1rem;

    .label {
      color: $grey-dark-2;
      font-size: 2.2rem;
      font-weight: 600;
      margin-right: 2rem;
    }

    .tag {
      display: flex;
      flex-direction: row;
      background-color: $yellow-light;
      color: $yellow-dark;
      border-radius: 5px;
      padding: 0 1rem;

      .icon {
        margin-right: 1rem;
        display: flex;
        align-self: center;
      }
      span {
        display: flex;
        align-self: center;
        font-size: 1.2rem;
      }
    }
  }

  .container {
    position: relative;

    .agents {
      display: flex;
      flex-direction: column;

      div {
        margin-bottom: 1rem;
      }
    }
  }

  .attendees {
    display: grid;
    grid-template-columns: auto;
    column-gap: 4rem;

    .rows {
      display: grid;
      grid-template-columns: 48% 48%;
      column-gap: 4rem;
      margin: 0.5rem 0 1rem;
      row-gap: 1.5rem;

      .item {
        font-size: 1.5rem;
        cursor: pointer;
        margin: 1rem 1.5rem;
        background-color: $purple-light-1;
        border-radius: $radius-sm;
        padding: 0 1.5rem;
        text-align: center;
        color: $purple-dark-1;
      }

      .noClient {
        margin-top: 1.5rem;
        font-size: 1.5rem;
        padding: 1.5rem;
        font-weight: 500;
      }

      .tags {
        display: flex;
        align-items: center;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          width: 3px;
          height: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: $purple-light-3;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: $purple-medium-1;
        }

        .clientChip {
          background-color: $green-light;
          border-color: $green-dark;
          .label {
            color: $green-dark;
          }
          .icon {
            color: $green-dark;
          }
        }
      }

      .chip {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 1rem 1.5rem;
        margin-right: 2rem;
        border: 1px solid $purple-dark-1;
        background-color: $purple-light-2;
        border-radius: $radius-sm;
        height: 80%;

        .label {
          font-size: 1.3rem;
          color: $purple-dark-1;
          white-space: nowrap;
          margin-right: 1rem;
        }
        .icon {
          color: $purple-dark-1;
          cursor: pointer;
        }
      }
    }
    .guests {
      display: grid;
      grid-template-columns: 48% 48%;
      column-gap: 4rem;
      margin: 0rem 0 1rem;
      row-gap: 1.5rem;
      flex-wrap: wrap;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;

      .guest {
        display: flex;
        align-items: center;
        background-color: white;
        padding: 1rem;
        border: 1px solid $grey-light-2;
        border-radius: $radius-sm;

        .icon {
          color: $grey-dark-1;
          font-size: 2rem;
          margin-right: 1rem;
        }
        .closeIcon {
          color: $grey-dark-1;
          font-size: 2rem;
          cursor: pointer;
          justify-self: flex-end;
        }
        .label {
          flex: 1;
          font-size: 1.5rem;
          color: $grey-dark-1;
        }
      }
      .chip {
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        border: 1px solid $purple-dark-1;
        background-color: $purple-light-2;
        border-radius: $radius-sm;
        white-space: nowrap;
        .icon {
          color: $purple-dark-1;
          cursor: pointer;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }

  .btns {
    display: flex;

    .btn {
      display: grid;
      width: 100%;
      margin-top: 1rem;
      color: $grey-dark-1;
      text-transform: capitalize;
      --color-hover: $grey-dark-1;
      --background-hover: white;

      .icon {
        width: 2rem;
        height: 2rem;
        color: $grey-dark-1;
        --ionicon-stroke-width: 56px;
      }
    }

    .back {
      justify-content: start;
    }

    .close {
      justify-content: end;
    }
  }
}
