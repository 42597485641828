@import "../../../../../../../scss/index.scss";
@import "../../../../../../../scss/variables.scss";

.federatedRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  height: 8rem;

  .button {
    flex: 1;
    height: 100%;
    background-color: $purple-light-1;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &:not(:last-of-type) {
      margin-right: 2.5rem;
    }

    .label {
      margin-left: 1rem;
      font-size: 1.5rem;
      color: $grey-dark-1;
      text-align: left;
      line-height: 2rem;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
}
