@import "../../../scss/app.scss";

.speech {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 2rem;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 50%;
  color: $purple-dark-1;
}
