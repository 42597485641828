@import "./variables.scss";

@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin center-horizontally {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin input-label {
  @include center-vertically;
  margin-left: 1.35rem;
  color: $input-label-color;
  font-size: 1.2rem;
  font-weight: 500;
  transform: translateY(-2.8rem);
  background-color: white;
  padding: 0 3px;
  // text-transform: lowercase;
}

@mixin input-placeholder {
  border: 1px solid #e5e5e5;
  border-radius: $radius-sm;
  height: 4rem;
  color: $input-text-color;
  background-color: white;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0 1.35rem;
  text-align: left;
  //text-transform: lowercase;
}

@mixin button-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  cursor: pointer;
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  -ms-overflow-style: none;

  scrollbar-width: none;
}

@mixin show-scrollbar {
  -ms-overflow-style: scrollbar;
  scrollbar-width: 5px;
  &::-webkit-scrollbar {
    width: 7px !important;
  }

  &::-webkit-scrollbar-track {
    background: $purple-light-2;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 200px;
    background-color: $purple-medium-2;
  }
}

@mixin safe-top-margin {
  margin-top: 20px; /* Status bar height on iOS 10 */
  margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
  margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}
@mixin safe-top-padding {
  padding-top: 20px; /* Status bar height on iOS 10 */
  padding-top: constant(
    safe-area-inset-top
  ); /* Status bar height on iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
}

@mixin safe-bottom-margin {
  margin-bottom: 20px; /* Status bar height on iOS 10 */
  margin-bottom: constant(
    safe-area-inset-bottom
  ); /* Status bar height on iOS 11.0 */
  margin-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
}
