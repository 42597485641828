@import "../../../../scss/index.scss";

.photosSection {
  margin: 2rem 0;

  .currentUser {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;

    .disableDeleteMode {
      cursor: not-allowed;
      color: $orange-medium;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .deleteMode {
      cursor: pointer;
      color: $orange-medium;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .others {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;

    .role {
      color: $grey-dark-1;
      font-size: 1.2rem;
      margin-left: 0.5rem;
      font-weight: 400;
    }
  }

  .images {
    // display: grid;
    // grid-template-columns: repeat(4, auto);
    // row-gap: 2rem;

    .img {
      width: 12rem;
      height: 12rem;
      border-radius: 10px;
      cursor: pointer;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }
}

ion-modal {
  --background: rgba(0, 0, 0, 0.85) !important;

  // .modal-wrapper.sc-ion-modal-md,
  // .modal-shadow.sc-ion-modal-md {
  //   height: 100% !important;
  //   width: 100% !important;
  // }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .slides {
      height: 100%;

      .slide {
        img {
          height: 80%;
        }
      }
    }

    .closeContainer {
      position: absolute;
      bottom: 4rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;

      .btn {
        color: $purple-light-2;
        --border-color: #e9e3f2;
        --border-width: 1px;
        --opacity: 0.9;
        font-weight: 300;
        --background-activated: #927bca;
      }
    }

    .leftIcon {
      z-index: 4000;
      position: absolute;
      left: 5rem;
      color: white;
      height: 5rem;
      width: 5rem;
      cursor: pointer;
    }

    .rightIcon {
      z-index: 4000;
      position: absolute;
      right: 5rem;
      color: white;
      height: 5rem;
      width: 5rem;
      cursor: pointer;
    }
  }
}
