@import "../../scss/index.scss";

.detailPage {
  display: grid;
  grid-template-columns: 73% 23%;
  // column-gap: 2rem;
  grid-row: 2/-1;
  grid-column: 2/-1;
  overflow-y: scroll;

  background-color: $purple-light-1;

  @media only screen and (max-width: $large) {
    grid-template-columns: auto;
  }

  .righSection {
    position: sticky;
    top: 0;
    right: 0rem;
    height: 50rem;

    @media only screen and (max-width: $large) {
      display: none;
    }
  }

  .tabletRightSection {
    display: none;
    height: 10rem;
    background-color: white;
    box-shadow: 1px 1px 5px #00000029;
    z-index: 20;

    @media only screen and (max-width: $large) {
      display: flex;
      position: sticky;
      bottom: 0;
    }
  }

  .images {
    width: 95%;
    margin: 0 auto;
  }

  .comparables {
    background-color: $purple-light-1;
    z-index: 5;
    grid-column: 1/-1;
    margin-top: 4rem;
    margin-left: 2rem;
    width: 100%;
  }
}

.restricted {
  display: flex;
  align-items: center;
  grid-column: 1/-1;
}

.footer {
  grid-column: 1/-1;
}

.loader {
  grid-row: 1/-1;
  grid-column: 2/-1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: $purple-light-1;

  .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .message {
      font-size: 2.5rem;
      color: $orange-dark;
      margin-bottom: 2.5rem;
    }
  }
}
