@import "../../../../../scss/index.scss";

.addShowing {
  .inputs {
    margin-top: 2rem;

    ion-row {
      margin-bottom: 1rem;
    }

    .item {
      border: $input-border;
      border-radius: $radius-sm;
      --inner-padding-top: 0;
      --padding-top: 0;
      --color: $purple-medium-2;
      font-size: 1.5rem;

      .picker {
        color: $grey-dark-1;
      }

      ion-label {
        display: flex !important;
        flex-direction: row;
        align-items: center;
        color: $grey-dark-1 !important;

        ion-icon {
          display: flex;
          align-self: center;
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }

      ion-select {
        color: $grey-dark-2 !important;
      }
    }

    .favourite {
      --background: #f9f9f9;
    }

    .map {
      position: relative;
      background-color: $purple-light-1;
      height: 20rem;
      border-radius: 5px;
    }

    .mapTransparent {
      background-color: transparent;
    }
  }

  ion-datetime::part(text) {
    color: $grey-dark-2 !important;
    font-weight: 600;
  }

  .button {
    margin: 3rem 1rem 2rem;
    height: 5rem;
    text-transform: initial;
    --background: #e9e3f2;
    --background-activated: #7158ad;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    --border-style: solid;
    --border-width: 1px;
    --border-color: #7158ad;
    --box-shadow: none;

    ion-icon {
      margin-right: 0.5rem;
    }
  }
}

.appHeader {
  position: sticky;
  top: 0;

  .toolbar {
    --background: #f4f4f7;

    .title {
      display: flex;
      justify-content: center;
      font-size: 2.2rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .backButton {
      display: flex;
      justify-content: flex-start;
      --color: #707070;
      --icon-font-size: 1.8rem;
      --icon-padding-end: 0.8rem;
      font-size: 1.5rem;
      margin-left: 1rem;
      padding: 0;
      text-transform: initial;

      .label {
        font-size: 1.5rem;
      }
    }

    .clearButton {
      display: flex;
      justify-content: flex-end;
      --color: #707070;
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: initial;
    }
  }
}

.favTitle {
  font-size: 1.4rem;
  color: $purple-medium-2;
  margin-left: 0.5rem;
  font-weight: 500;
}

.noFav {
  font-size: 1.3rem;
  color: $grey-dark-1;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
}
