@import "../../../../scss/index.scss";
@import "../../../../scss/variables.scss";

.content {
  display: grid;
  grid-template-columns:
    minmax(min-content, 25%) minmax(min-content, 45%) minmax(min-content, 10%) minmax(
      min-content,
      10%
    )
    minmax(min-content, 10%);
  grid-template-rows: 1fr;

  .column {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-right-style: solid;
      border-right-width: 0.2rem;
      border-right-color: #dedaec8c;
    }

    margin: 2rem 0;
    padding: 0 3rem;
  }

  .nameContainer {
    grid-column: 1/2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      flex: 1;
      font-size: 1.5rem;
      font-weight: 600;
    }
    .loadBtn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: stretch;
      margin-right: 2rem;
    }
  }

  .filters {
    grid-column: 2/3;
    display: flex;
    align-items: center;
    overflow: hidden;

    .filter {
      font-size: 1.5rem;
      .total {
        min-width: 12rem;
        max-width: 12rem;
        font-weight: 600;

        &::after {
          content: "|";
          color: $line-color;
          font-weight: 400;
          padding: 0.8rem;
        }
      }
    }
  }

  .schools {
    grid-column: 3/4;
    .school {
      display: flex;
      flex-direction: column;
      align-items: center;

      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        padding: 0.25rem 0.75rem;
        background-color: $green-light;
        color: $green-dark;
        border-radius: $radius-sm;
        margin-bottom: 0.5rem;
        ion-icon {
          font-size: 1.75rem;
          margin-right: 0.75rem;
        }
      }

      .name {
        font-size: 1.2rem;
        text-align: center;
        color: $grey-dark-1;

        .underscore {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .noSchool {
      .tag {
        color: $grey-light-2;
        background-color: $grey-light-1;
      }
      .name {
        color: $grey-light-2;
      }
    }
  }

  .notifications {
    font-size: 1.5rem;
    grid-column: 4/5;

    .notification {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .notificationIcon {
        display: flex;
        align-items: center;
        ion-icon {
          width: 2.3rem;
          height: 2.3rem;
          color: $grey-dark-1;
        }
      }

      .active {
        color: $orange-medium;
        fill: $orange-medium;
      }

      ion-icon.active {
        color: $orange-medium;
      }

      .label {
        margin-left: 0.5rem;
      }
      ion-icon {
        fill: none;
      }

      .toggle {
        margin-top: 1rem;
      }
    }
  }

  .icons {
    grid-column: 5/6;
    display: flex;
    justify-content: space-around;

    .loader {
      margin: 0 auto;
    }

    ion-icon {
      cursor: pointer;
      width: 2.3rem;
      height: 2.3rem;
      color: $grey-dark-1;
    }

    .delete {
      margin-left: 2rem;
    }
  }
}

@media screen and (max-width: $large) {
  .notification {
    flex-direction: column;
    justify-content: center;

    & .notificationIcon {
      margin-bottom: 1rem;
    }
  }
}
