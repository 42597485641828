@import "../../../../../scss/index.scss";

.container {
  --background: transparent;
  &:hover,
  &:active {
    --background: transparent;
  }
}

.title {
  border-bottom-color: $purple-light-2;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 1.8rem;
  color: $grey-dark-2;
  font-weight: 600;
}

.details {
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-color: $purple-light-2;
    border-bottom-width: 0.1rem;
    border-bottom-style: solid;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $grey-dark-2;

    .bold {
      font-weight: 500;
    }
  }

  .lastRow {
    border: none;
  }

  .status {
    width: max-content;
    padding: 0 1.5rem;
    width: 10rem;
    text-align: center;
  }

  .ready {
    background-color: $green-light;
    color: $green-dark;
    border-radius: 5px;
  }

  .draft {
    background-color: $yellow-medium;
    color: $yellow-dark;
    border-radius: 5px;
  }

  .pending {
    color: $yellow-dark;
    background-color: $yellow-light;
    border-radius: 5px;
  }

  .cancelled {
    background-color: $orange-light;
    color: $orange-dark;
    border-radius: 5px;
  }

  .completed {
    background-color: $purple-light-2;
    color: $purple-medium-2;
    border-radius: 5px;
  }

  .archived {
    background-color: $grey-medium-1;
    color: $grey-light-1;
    border-radius: 5px;
  }
}
