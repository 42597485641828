@import "../../../scss/index.scss";

.errorToast {
  --background: #ff5500;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}
.successToast {
  --background: #1f7a60;
  --color: white;
  font-size: 1.2rem;
  --button-color: white;
}

.profileGrid {
  width: 100%;

  .items {
    width: 100%;
    margin: 0 1rem 0 0rem;
    color: #363636;

    ion-col {
      margin: 1rem 0;
    }

    .title {
      font-size: 2.2rem;
      font-weight: 600;
    }

    .date {
      font-size: 1.2rem;
    }
  }

  .profileImg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .name {
      display: flex;
      align-self: center;
      margin-left: 1rem;
      font-size: 1.5rem;
      color: #7158ad;
      font-weight: 500;
    }

    .imageContainer {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: $purple-light-2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;

      .image {
        width: 3.5rem;
        width: 3.5rem;
        border-radius: 50%;
      }

      .initials {
        color: $purple-medium-2;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }

  .label {
    font-size: 1.2rem;
    color: $grey-dark-1;
  }

  .value {
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .icon {
    display: flex;
    justify-content: flex-start;
    align-self: flex-end;

    ion-icon {
      color: $grey-dark-1 !important;
      width: 2rem;
      height: 2rem;
    }
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    color: #7158ad;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .editbtn {
    text-transform: capitalize;
    --color: #7158ad;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .button {
    display: flex;
    width: 50%;
    margin: 2rem auto;
    --background: #ffeee5;
    --border-color: #c24100;
    --border-style: solid;
    --border-width: 1px;
    --color: #c24100;
    --background-focused: transparent;
    --background-activated: transparent;
    --background-hover: transparent;
    text-transform: initial;
  }

  .line {
    border-bottom-color: $grey-light-2;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 2rem;
  }

  .lastOption {
    --border-style: none;
  }
}
