@import "../../../../../../scss/index.scss";

.tourSchedule {
  border-top-style: solid;
  border-top-color: $purple-light-2;
  border-top-width: 0.1rem;
  padding: 0 1rem;

  .header {
    padding: 2rem 0.5rem;
    display: grid;
    grid-template-columns: 5% 10% 10% 15% 10% 10% 15% 10%;
    column-gap: 2rem;
    border-bottom-style: solid;
    border-bottom-color: $purple-light-2;
    border-bottom-width: 0.1rem;
    font-size: 1.5rem;

    .order {
      display: flex;
      justify-content: center;
    }
    .map {
      display: flex;
      justify-content: center;
    }
    .status {
      display: flex;
      justify-content: center;
    }
    .review {
      display: flex;
      justify-content: center;
    }

    .details {
      display: flex;
      justify-content: center;
    }
  }

  .noItem {
    margin: 2rem;
    color: $grey-dark-1;
  }
}
