@import "../../scss/index.scss";

.recordingDetails {
  margin: 0 2rem 4rem;

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
  }
}
