@import "../../../scss/index.scss";

.container {
  width: 58rem;

  .header {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subtitle {
    display: grid;
    justify-content: center;
    color: $grey-dark-1;
    font-size: 1.5rem;

    span {
      width: 40rem;
      text-align: center;
    }
  }

  .content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 3rem;

    .label {
      margin-bottom: 1rem;
      font-size: 1.8rem;
      font-weight: 600;
      color: $grey-dark-2;
    }

    .options {
      display: grid;
      grid-template-columns: auto auto auto;
      font-size: 1.5rem;
    }
  }
}
