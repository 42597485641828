@import "../../../../scss/index.scss";

.rightSection {
  display: flex;
  width: 100%;
  justify-content: space-around;

  .shareBtn {
    justify-self: center;
    align-self: center;
  }

  div {
    align-self: center;
  }

  .status {
    background-color: $green-light;
    color: $green-dark;
    padding: 0.3rem 0.6rem;
    border-radius: $radius-sm;
    margin: 0 0.5rem;
  }

  .daysNum {
    color: $grey-dark-1;
    padding: 0.3rem 0.6rem;
    justify-self: center;
  }

  .price {
    font-size: 4rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .soldStatus {
    border-radius: $radius-sm;
    padding: 0.3rem 0.6rem;
    color: $orange-dark;
    background-color: $orange-light;
    align-self: center;
  }

  .soldDate {
    align-items: center;
    color: $grey-dark-1;
    margin: 0 1rem;
    justify-self: center;
  }

  .soldFirstCol {
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 1.5rem;
    margin-top: 1.5rem;
  }

  .onMarket {
    color: $grey-dark-2;
    span {
      font-weight: 600;
    }
  }

  .priceDiff {
    display: flex;
    color: $grey-dark-2;
    font-weight: 400;

    .asking,
    .increased,
    .decreased {
      display: flex;
      align-items: center;
    }

    .increased {
      color: $green-dark;
    }

    .decreased {
      color: $orange-dark;
    }

    .value {
      margin-left: 0.2rem;
      font-weight: 500;
    }

    .text {
      font-style: italic;
      color: $grey-dark-1;
      margin-left: 0.3rem;
    }
  }

  .contact {
    display: grid;
    justify-content: center;
    align-self: center;
  }
}
