@import "../../scss/index.scss";

.header {
  grid-row: 1/2;
  grid-column: 2/-1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.5rem;
  border-bottom: 1px solid $line-color;

  @media screen and (max-width: $small) {
    display: none;
  }

  .greeting {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .greetingText {
      padding: 0;
      margin: 0;
      position: relative;
    }

    .shareStatus {
      flex: 1;
      display: flex;
      align-items: center;
      .shared {
        margin-left: 2rem;
        padding: 0.2rem 0.75rem;
        display: flex;
        align-self: center;
        color: $green-dark;
        background-color: $green-light;
        border-radius: $radius-sm;
        font-size: 1.5rem;
      }

      .notShared {
        margin-left: 2rem;
        padding: 0.2rem 0.75rem;
        display: flex;
        align-self: center;
        color: $orange-dark;
        background-color: #ffebe5;
        border-radius: $radius-sm;
        font-size: 1.5rem;
      }
    }
  }

  .listings,
  .schools {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    // display: flex;
    // justify-content: space-between;
    width: 80%;
    .return {
      display: flex;
      align-self: center;
      color: $grey-dark-1;
      font-size: 1.5rem;
      font-weight: 400;
      cursor: pointer;

      ion-icon {
        margin-right: 1rem;
        height: 2rem;
        width: 2rem;
        align-self: center;
      }
    }

    .open {
      display: flex;
      align-self: center;
      font-size: 1.5rem;
      cursor: pointer;
      margin-right: 20%;

      a {
        display: flex;
        color: $grey-dark-1;
        font-weight: 400;
      }

      ion-icon {
        margin-right: 1rem;
        height: 2rem;
        width: 2rem;
        align-self: center;
      }
    }
  }

  .notification {
    color: $secondary-text-color;
    .link {
      color: $secondary-text-color;
      transition: color 0.2s ease-in;

      &:hover {
        color: $purple-medium-2;
      }
    }
  }

  .btns {
    display: grid;
    grid-template-columns: auto auto;
  }

  .authenticatedBtns {
    display: flex;
    align-items: center;

    .notifBtnContainer {
      display: flex;
      &::after {
        content: "|";
        padding: 0 1rem;
        color: $line-color;
      }
      ion-icon {
        color: $orange-medium !important;
      }
      margin-right: 0.5rem;
    }

    .btnContainer {
      display: flex;
      margin-right: 1.5rem;
      padding-right: 1.5rem;
      border-right: 0.1rem solid $line-color;
    }
  }
}
