@import "../../../../scss/index.scss";

.newTour {
  margin: 2rem;

  .title {
    font-size: 2.6rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .subtitle {
    font-size: 1.5rem;
    color: $grey-dark-1;
    width: 70%;
  }
}
