@import "../../../../../../../../scss/index.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border: $cluster-border;
  border-top-style: none;
  border-radius: 0px 0px 5px 5px;
  padding: 2rem;

  .disclaimer {
    color: $grey-dark-1;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1.5rem;
  }
  .header {
    font-size: 2.2rem;
    color: $purple-medium-2;
    font-weight: 500;
  }

  .payment {
    background-color: #f4f4f7;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-sm;
    .label {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
    }
    .amount {
      font-size: 1.8rem;
      color: $purple-medium-2;
      font-weight: 600;
      margin-left: 1rem;
    }
  }
  .mortgage {
    display: flex;
    flex-direction: column;
    .calculator {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        &:first-of-type {
          margin-top: 3rem;
        }

        .inputContainer {
          display: flex;
          width: 25rem;
        }

        .rangeContainer {
          position: relative;
          display: flex;
          flex: 1;
          margin-left: -1rem;
          .label {
            position: absolute;
            top: -1rem;
            left: 1.5rem;
            font-size: 1.5rem;
            font-weight: 600;
            color: $purple-dark-1;
          }

          .range {
            margin-right: 2rem;
            --bar-background: #e9e3f2;
            --bar-background-active: #7158ad;
            --bar-height: 5px;
            --knob-background: #e9e3f2;
            --knob-box-shadow: 0px 0px 0px 7px #7158ad;
            --knob-border-radius: 50%;
            --knob-size: 9px;
          }
        }
      }
    }
  }

  .transfer {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;

    .item {
      width: 100%;
      padding: 2rem 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid $grey-light-2;
      }

      &:last-of-type {
        margin-bottom: 3rem;
      }

      .inputContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .input {
          position: relative;

          .icon {
            position: absolute;
            font-size: 2rem;
            color: $grey-dark-1;
            @include center-vertically;
            left: -3rem;
            cursor: pointer;
          }
        }

        .label {
          display: flex;
          width: 100%;
          font-size: 1.5rem;
          font-weight: 600;
          color: $purple-dark-1;

          .subLabel {
            margin-left: 0.5rem;
            font-weight: 400;
            color: $grey-dark-1;
          }

          .toggle {
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

.appContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  border: $cluster-border;
  border-top-style: none;
  border-radius: 0px 0px 5px 5px;
  padding: 2rem;

  .disclaimer {
    color: $grey-dark-1;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1.5rem;
  }
  .header {
    font-size: 1.5rem;
    color: $purple-medium-2;
    font-weight: 600;
  }

  .payment {
    background-color: #f4f4f7;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-sm;
    .label {
      font-size: 1.5rem;
      color: $grey-dark-2;
      font-weight: 600;
    }
    .amount {
      font-size: 1.5rem;
      color: $purple-medium-2;
      font-weight: 600;
      margin-left: 1rem;
    }
  }
  .mortgage {
    display: flex;
    flex-direction: column;
    .calculator {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        align-items: center;
        margin: 1rem 0;

        &:first-of-type {
          margin-top: 2rem;
        }

        .inputContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          .inputs {
            display: flex;
            align-items: center;
            width: 100%;

            .amount {
              flex: 2;
              margin-right: 1rem;
            }
            .percentage {
              flex: 1;
            }
          }
          .label {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: $grey-dark-2;
          }
        }

        .selectItem {
          display: flex;
          width: 103%;
          margin: 0;
          --padding-start: 0;
          --padding-top: 0;
          --padding-end: 0;
          --padding-bottom: 0;
          --inner-padding-bottom: 0;
          --inner-padding-top: 0;
          --background: transparent;

          .select {
            font-size: 1.5rem;
            color: $grey-dark-2;
            font-weight: 600;
            background-color: white;
            border: 1px solid $grey-light-2;
            border-radius: $radius-sm;
            --padding-start: 1rem;
            width: 100% !important;
          }
        }
      }
    }
  }

  .transfer {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    border-top: 1px solid $purple-medium-2;

    .header {
      margin-top: 1.5rem;
    }

    .item {
      width: 100%;
      padding: 1rem 0;

      &:last-of-type {
        margin-bottom: 3rem;
      }

      .inputContainer {
        display: flex;
        flex-direction: column;

        .input {
          position: relative;
          display: flex;
          align-items: center;

          .toggle {
            margin-left: 1rem;
          }

          .icon {
            position: absolute;
            font-size: 2rem;
            color: $grey-dark-1;
            @include center-vertically;
            right: 0;
            cursor: pointer;
          }
          .amount {
            color: $grey-dark-2;
            font-size: 1.5rem;
            font-weight: 500;
          }
        }

        .label {
          display: flex;
          width: 100%;
          font-size: 1.5rem;
          font-weight: 600;
          color: $grey-dark-2;
          margin-bottom: 0.5rem;

          .subLabel {
            margin-left: 0.5rem;
            font-weight: 400;
            color: $grey-dark-1;
          }
        }
      }
    }
  }
}

.popover {
  --width: 50rem;
  --height: auto;
  --box-shadow: none;
  --background: transparent;
  .content {
    padding: 2.5rem;
    font-size: 1.5rem;
    color: $grey-dark-2;
    background-color: white;
    border-radius: $radius-lg;
    border: 1px solid $purple-light-3;
    box-shadow: 0px 2px 6px $purple-light-3;
  }
}

.appPopover {
  --width: auto;
  --height: auto;
  --background: transparent;
  .content {
    padding: 2.5rem;
    font-size: 1.2rem;
    color: $grey-dark-2;
    background-color: white;
    border-radius: $radius-lg;
    border: 1px solid $purple-light-3;
    box-shadow: 0px 2px 6px $purple-light-3;
  }
}
