@import "./variables.scss";
@import "./utils.scss";

* {
  font-family: $base-font-family;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
  @include hide-scrollbar;
}

html {
  height: 100%;
  font-size: $base-font-size;
  font-family: $base-font-family;
}

body {
  font-size: 1.8rem;
  font-weight: 400;
  ::selection {
    background: $purple-light-1;
    color: $purple-medium-2;
  }
}

a {
  text-decoration: none;
  color: black;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2.6rem;
  font-weight: 500;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

h5 {
  font-size: 1.5rem;
  font-weight: 500;
}

h6 {
  font-size: 1.2rem;
  font-weight: 500;
}

@media screen and (max-width: $large) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: $medium) {
  html {
    font-size: 45%;
  }
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

ion-loading {
  --spinner-color: #7158ad !important;
  --background: #f4f4f7;
}

ion-back-button {
  --ripple-color: #e9e3f2 !important;
}
