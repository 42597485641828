@import "../../../scss/index.scss";

.container {
  width: 58rem;

  .header {
    display: grid;
    justify-content: center;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
  .subtitle {
    display: grid;
    justify-content: center;
    color: $grey-dark-1;
    font-size: 1.5rem;
  }

  .content {
    margin: 4rem 0 2rem;
    display: grid;
    grid-template-rows: auto auto;
    row-gap: 2rem;

    .error {
      padding-top: 1.5rem;
      font-size: 1.3rem;
      color: $orange-medium;
    }

    .label {
      font-size: 1.5rem;
      color: $grey-dark-1;
      span {
        color: $grey-dark-2;
        font-weight: 600;
      }
    }
  }
}
