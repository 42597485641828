@import "../../scss/index.scss";

.rangesContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 1.5rem;
}

.menuItem {
  // border-bottom: 1px solid $line-color;
}
