@import "../../scss/index.scss";
.container {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 3fr;

  .listings {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-row: 2/-1;
    grid-column: 1/-1;
    overflow-y: hidden;

    .cardsContainer {
      overflow-y: scroll;
      @include show-scrollbar;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      height: 100%;

      .cards {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 50rem;
        column-gap: 2rem;
        row-gap: 2rem;
        padding: 0 3rem;
        margin-bottom: 3rem;

        @media screen and (max-width: $largest) {
          grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (min-width: 1120px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 1120px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: $medium) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: $small) {
          grid-template-columns: repeat(1, 1fr);
        }
        @media screen and (max-width: $smallest) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
