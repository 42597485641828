@import "../../../../scss/app.scss";

.container {
  position: absolute;
  width: 99.75%;
  margin-left: 0.125%;
  bottom: 0;
  height: auto;
  display: flex;
  z-index: 2500;

  .card {
    background-color: $grey-light-1;
    flex: 1;
    box-shadow: $box-shadow-grey;
    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2.5rem;

    .handle {
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .chevron {
        margin-top: 0.5rem;
        height: 2rem;
        width: 4rem;
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $grey-light-2;
      padding-bottom: 1.5rem;
      .title {
        .link {
          font-size: 1.8rem;
          color: $grey-dark-2;
          font-weight: 600;
          text-decoration: underline;
        }
      }

      .info {
        font-size: 1.5rem;
        color: $grey-dark-1;
        font-weight: 300;
        span {
          &:not(:last-of-type) {
            &::after {
              content: "|";
              padding: 0 1rem;
              color: $grey-light-2;
            }
          }
        }
      }

      .programs {
        font-size: 1.5rem;
        color: $grey-dark-1;
        font-weight: 300;
      }
    }

    .schoolDetails {
      padding: 1.5rem 0;
      border-bottom: 1px solid $grey-light-2;
      font-size: 1.5rem;
      color: $grey-dark-1;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      width: 100%;

      .address {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .website {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: underline;
      }
    }

    .rating {
      padding: 1.5rem 0;
      border-bottom: 1px solid $grey-light-2;
      display: flex;

      .rate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 3rem;

        .score {
          text-align: center;
          font-size: 3rem;
          font-weight: 600;
          color: $orange-medium2;
          padding: 0;
          margin: 0;
        }

        .outOf {
          margin-top: -1rem;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 300;
          color: $grey-dark-2;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        color: $grey-dark-1;
        font-size: 1.5rem;
        .text {
          font-weight: 600;
        }

        .yearly .average {
          font-weight: 300;
        }
      }

      .scores,
      .averages {
        display: flex;
        flex-direction: column;
      }
    }

    .boundary {
      padding: 1.5rem 0;
      border-bottom: 1px solid $grey-light-2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 400;
      color: $grey-dark-1;

      .noText {
        color: $orange-dark;
      }

      .toggle {
        margin-left: 1rem;
        min-width: 6rem;
        --background: #707070;
        --background-checked: #ff5f0f;
        --handle-spacing: 5px;
        --handle-height: 20px;
        --handle-width: 20px;
      }
    }

    .footer {
      padding: 1.5rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.2rem;
      font-weight: 400;
      color: $grey-dark-1;
    }
  }
}
