@import "../../../scss/index.scss";

.aboutus {
  grid-row: 2/-1;
  grid-column: 2/-1;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 1fr;
  overflow-y: scroll;

  .cards {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    height: 100%;

    .leftCards {
      display: grid;
    }
  }
}
