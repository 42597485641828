@import "../../../scss/app.scss";

.mapContainer {
  width: 100%;
  height: 100%;
}

.comingSoon {
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem auto;
  position: relative;

  .btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2rem;
    right: 2rem;
    width: 4rem;
    height: 4rem;
    background-color: $purple-medium-1;
    border-radius: 50%;
    box-shadow: $box-shadow-grey;

    ion-icon {
      font-size: 3.5rem;
      color: $grey-light-1;
    }
  }

  .animation {
    width: 35rem;
    margin-bottom: 2rem;
  }

  .header {
    color: $grey-dark-2;
    margin-bottom: 1rem;
  }

  .content {
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    color: $grey-dark-1;
  }
}
