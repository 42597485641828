@import "../../../../../../scss/index.scss";

.details {
  margin-top: 2rem;
  ion-row {
    display: flex;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 0.1rem;
    border-top-color: $purple-light-2;
    padding-top: 1rem;
    margin: 0 1rem 1rem;
    font-size: 1.5rem;
    color: $grey-dark-2;

    .bold {
      text-align: end;
      font-weight: 600;
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ion-icon {
        display: flex;
        align-self: center;
        margin-left: 0.5rem;
      }
    }

    .underline {
      text-decoration: underline;
    }
  }
}
