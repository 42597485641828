@import "../../../../../scss/index.scss";

.authGrid {
  margin: 2rem 2rem 0 2rem;

  .img {
    display: grid;
    justify-content: start;
    margin-bottom: 2rem;

    img {
      height: 5rem;
    }
  }

  .greeting {
    font-size: 2.2rem;
    color: $grey-dark-1;
  }
  .question {
    font-size: 2.2rem;
    font-weight: 600;
    color: $grey-dark-2;
  }
}
