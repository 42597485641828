@import "../../../../../../scss/index.scss";

.container {
  padding: 0 3rem;
  .infoTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    font-size: 3rem;
    font-weight: 600;
    color: $grey-dark-2;

    & > * {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .roomdetails {
    height: 6rem;
    padding-left: 2rem;
    display: grid;
    grid-template-columns: 30% 30% 40%;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $grey-light-2;
    margin-bottom: 2rem;
    color: #666666;
    font-size: 1.8rem;
  }

  .priceDetails {
    display: grid;
    grid-template-columns: 15% 15% 17% 17% 10% 15%;
    column-gap: 2rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $grey-light-2;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    color: #666666;
    font-size: 1.5rem;
    text-align: center;
  }

  .noData {
    display: grid;
    justify-content: center;
    color: #666666;
    font-size: 2rem;
    font-weight: 600;
  }
}
