@import "../../../../../../scss/app.scss";

.container {
  width: 100%;
  height: 100%;
  background-color: white;

  .content {
    padding: 2rem;
    overflow-y: scroll;

    .text {
      font-size: 1.5rem;
      color: $grey-dark-1;
    }

    .form {
      margin: 1rem 0;

      & > div {
        padding: 2rem 0;
        border-bottom: 1px solid $line-color;
      }

      .search {
        display: flex;
        flex-direction: column;

        .name {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
        }
        .title {
          font-size: 1.8rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
          color: $grey-dark-2;
        }
        .error {
          padding-top: 1.5rem;
          font-size: 1.3rem;
          color: $orange-medium;
        }
      }
      .params {
        .filters {
          display: grid;
          grid-auto-columns: min-content;
          grid-auto-rows: min-content;
          font-size: 1.5rem;
          row-gap: 0.5rem;

          .item {
            display: flex;
            flex-wrap: nowrap;
            &:first-of-type {
              margin-top: 0.5rem;
            }
            .label {
              color: $grey-dark-2;
              font-weight: 500;
              margin-right: 0.5rem;
              white-space: nowrap;
            }
            .filter {
              color: $grey-dark-1;
              white-space: nowrap;
            }
          }
        }
      }
      .schools {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0;
        .school {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .iconContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $green-light;
            padding: 0.35rem 0.8rem;
            border-radius: $radius-sm;
          }
          .label {
            color: $grey-dark-1;
            .schoolName {
              ion-label {
                overflow-x: hidden;
                max-width: 18rem;
                color: $grey-dark-2;
                font-size: 1.5rem;
              }
            }
          }
        }
      }

      .alerts {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5rem;

        .toggleContainer {
          --padding-top: 0;
          --padding-bottom: 0;

          .toggle {
            --background-checked: #ff5f0f;
            --handle-background: white;
            --handle-background-checked: white;
          }
        }
      }
      .iconContainer {
        display: flex;
        align-items: center;
        .icon {
          font-size: 2rem;
          color: $grey-dark-2;
          margin-right: 0.5rem;
        }
        .title {
          font-size: 1.5rem;
          color: $grey-dark-2;
        }
      }
    }

    .input {
      border: 1px solid #e5e5e5;
      border-radius: $radius-sm;
      font-size: 1.5rem;
      font-weight: 500;
      color: $grey-dark-2;
      --padding-start: 1rem;
      --padding-top: 1rem;
      --padding-bottom: 1rem;
    }
  }
}
