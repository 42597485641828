@import "../../../../../../scss/index.scss";

.signupOptions {
  .title {
    margin: 2rem 0 0 2rem;
    color: $grey-dark-2;
    font-size: 2.2rem;
    font-weight: 600;
  }
  .subtitle {
    margin: 0 0 2rem 2rem;
    color: $grey-dark-1;
    font-size: 1.5rem;
  }

  .line {
    border-bottom-color: $grey-light-2;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .label {
    margin: 2rem 0 0 2rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .textareaCol {
    margin: 0 0.5rem 0 2rem;

    .textarea {
      --color: #363636;
      border: 1px solid $grey-dark-2;
      font-size: 1.2rem;
      padding: 0rem 1rem 1rem 1.2rem;
    }
  }

  .agree {
    ion-checkbox {
      --background-checked: #7158ad;
      --checkmark-color: white;
      --border-color-checked: #707070;
      margin-right: 1rem;
    }

    ion-label {
      white-space: unset;
      font-size: 1.5rem;
      color: $grey-dark-2;
    }
  }

  .option {
    ion-item {
      margin-right: 4rem;
    }

    // ion-radio {
    //   --color-checked: #7158ad;
    //   display: grid;
    //   margin: 0;
    //   margin-right: 1rem;
    // }
    ion-checkbox {
      --background-checked: #7158ad;
      --checkmark-color: white;
      --border-color-checked: #707070;
      margin-right: 1rem;
    }

    ion-label {
      white-space: unset;
      font-size: 1.5rem;
      color: $grey-dark-2;
    }
  }

  .lastOption {
    --border-style: none;
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    margin: 2rem auto 0 auto;
    width: 100%;
    height: 5rem;
  }
  .btntitle {
    display: flex;
    // height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    .spinner {
      transform: scale(0.8);
      margin-right: 1rem;
      --color: #7158ad;
    }
  }
  .signOut {
    text-align: center;
    font-size: 1.5rem;
    margin: 1rem auto;
    color: $purple-medium-2;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
