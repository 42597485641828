@import "../../scss/index.scss";
@import "../../scss/variables.scss";

.container {
  display: flex;
  position: relative;
  z-index: 1000;

  .profile {
    display: flex;
    align-items: center;

    .imageContainer {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: $purple-light-2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .image {
        width: 3.5rem;
        width: 3.5rem;
        border-radius: 50%;
      }

      .initials {
        color: $purple-medium-2;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .name {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 0.75rem;
      cursor: pointer;
      text-align: right;
      line-height: 1.5rem;

      .agentProfile {
        color: $grey-dark-1 !important;
        font-size: 1.2rem !important;
      }
    }

    .iconContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;

      .icon {
        right: 1.35rem;
        color: $input-icon-color;
        --ionicon-stroke-width: 4.8rem;
        cursor: pointer;
        transition: all 0.2s;
      }

      .iconReversed {
        transform: rotate(180deg);
      }
    }
  }

  .profileDropdownContainer {
    margin: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-dark-2;

    .firstRow {
      border-bottom-style: solid;
      border-bottom-width: 0.1rem;
      border-bottom-color: $grey-light-2;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      cursor: pointer;
    }
    .secondRow {
      display: flex;
      align-items: center;
      cursor: pointer;

      ion-icon {
        margin-left: 1rem;
        font-size: initial;
      }
    }

    .homePageFont {
      font-size: 1.5rem !important;
    }
  }
}
