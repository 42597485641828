@import "../../../../../scss/app.scss";

.item {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;

  .input {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1rem;

    .range {
      --bar-background: #e9e3f2;
      --bar-background-active: #7158ad;
      --bar-height: 5px;
      --knob-background: white;
      --knob-box-shadow: 0px 0px 0px 4px #7158ad;
      --knob-border-radius: 50%;
      --knob-size: 15px;
    }
    .value {
      --color: #363636;
    }
    .label {
      color: $grey-dark-1;
    }

    .pins {
      display: flex;
      justify-content: center;
      margin-top: -1rem;
      margin-bottom: 1rem;
      padding: 0 1rem;
      .pin {
        color: $purple-medium-2;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
}
