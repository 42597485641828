@import "../../../../../../../../scss/index.scss";

.blurMsg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  top: 15%;
  left: 10%;
}
.listingCard {
  .card {
    padding: 0;
    background-color: white;
    margin-bottom: 1rem;
    border-radius: $radius-normal;
    height: 100%;
    width: 95%;
    min-width: 95%;

    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    filter: blur(4px);

    .header {
      position: relative;
      padding: 0;
      height: 12rem;
      width: 100%;

      background-color: $purple-light-1;

      .spinner {
        position: absolute;
        @include center-vertically;
        @include center-horizontally;
        color: $purple-medium-2;
      }

      .image {
        object-fit: cover;
        width: 100%;

        height: 100%;
        padding: 0;
      }

      .tag {
        display: flex;
        width: auto;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: center;
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        background-color: white;
        border: 1px solid $grey-light-2;

        border-radius: $radius-sm;
        font-size: 1.2rem;
        font-weight: 300;
        color: $grey-dark-1;

        .inactive {
          background-color: $orange-light;
          color: $orange-dark;
          flex: 1;
          border-top-left-radius: $radius-sm;
          border-bottom-left-radius: $radius-sm;
          padding: 0.25rem 0.5rem;
        }
        .date {
          flex: 1;
          border-top-right-radius: $radius-sm;
          border-bottom-right-radius: $radius-sm;
          padding: 0.25rem 0.5rem;
        }
      }
    }

    .content {
      width: 100%;
      padding: 1.5rem;
      padding-bottom: 1rem;

      .icons {
        position: absolute;
        top: 1.5rem;
        right: 1rem;
        display: flex;
      }

      .price {
        font-size: 1.8rem;
        font-weight: 600;
        color: $grey-dark-2;
        margin-bottom: 0.3rem;
        text-align: left;
      }

      .address {
        font-size: 1.2rem;
        color: $grey-dark-1;
        text-align: left;
      }

      .info {
        font-size: 1.2rem;
        color: $grey-dark-1;
        text-align: left;

        & span:not(:last-of-type)::after {
          content: "|";
          padding: 0 0.5rem;
          color: $grey-light-2;
        }
      }
    }
  }
}
