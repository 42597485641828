@import "../../../../scss/index.scss";

.notesSection {
  margin: 2rem 0;

  .currentUser {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;

    .edit {
      font-size: 1.5rem;
      color: $grey-dark-1;
    }
  }

  .others {
    font-size: 1.8rem;
    font-weight: 600;
    color: $grey-dark-2;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom-style: solid;
    border-bottom-width: 0.1rem;
    border-bottom-color: $purple-light-2;

    .role {
      color: $grey-dark-1;
      font-size: 1.2rem;
      margin-left: 0.5rem;
      font-weight: 400;
    }
  }

  .textarea {
    margin: 0 0 2rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 24rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
    color: $grey-dark-1;
    overflow-y: scroll;
    @include show-scrollbar;
    border-radius: $radius-sm;
  }

  .emptyTextarea {
    margin: 0 0 2rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: auto;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
    color: $grey-dark-1;
    overflow-y: scroll;
    @include show-scrollbar;
    border-radius: $radius-sm;
  }

  .othersNote {
    position: relative;
    margin: 0 0 2rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
    border: 1px solid $grey-light-2;
    background-color: white;
    padding: 1.5rem;
    padding-bottom: 3.5rem;
    width: 100%;
    height: auto;
    word-wrap: break-word;
    max-height: 12rem;
    overflow-y: scroll;
    @include show-scrollbar;
    border-radius: $radius-sm;
  }
  .paddingBottom {
    padding-bottom: 3.5rem;
  }

  .emptyTextarea:disabled {
    background-color: #ffffff;
    color: $grey-dark-2;
    max-height: 20rem;
  }
}
