@import "../../scss/index.scss";

.fullPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary-bg-color;

  .loader {
    width: 15rem;
    height: 18rem;
    stroke: $purple-medium-2;
    stroke-width: 1.5rem;
  }

  .msg {
    text-align: center;
    font-size: 1.3rem;
    color: $grey-dark-1;
    padding: 2rem 3rem;

    .reload {
      color: $purple-medium-2;
      font-size: 1.4rem;
      margin-top: 1rem;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
