@import "../../../../../../../../scss//index.scss";

.soldRightNavbar {
  margin-top: 14rem;

  .soldInfo {
    display: flex;

    .soldStatus {
      color: $orange-dark;
      background-color: $orange-light;
      padding: 0.3rem 0.6rem;
      border-radius: $radius-sm;
      margin-right: 2rem;
    }

    .soldDate {
      display: grid;
      align-items: center;
      color: $grey-dark-1;
    }
  }

  .soldPrice {
    font-size: 4.2rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .onMarket {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 0.1rem solid $purple-light-3;
    color: $grey-dark-1;
    span {
      font-weight: 600;
    }
  }

  .priceDiff {
    display: grid;
    color: $grey-dark-2;
    font-weight: 400;
    margin-top: 1rem;

    // .asking,
    // .increased,
    // .decreased {
    //   display: flex;
    //   align-items: center;
    // }

    // .increased {
    //   color: $green-dark;
    // }

    // .decreased {
    //   color: $orange-dark;
    // }

    // .value {
    //   margin-left: 0.2rem;
    //   font-weight: 500;
    // }

    // .text {
    //   font-style: italic;
    //   color: $grey-dark-1;
    //   margin-left: 0.3rem;
    // }
  }
}
