@import "../../../scss/app.scss";
.content {
  .search {
    position: relative;
    &:first-of-type {
      border-top: 1px solid #c8c7cc;
    }

    .icon {
      font-size: 2.2rem;
      color: $green-dark;
    }
    .label {
      color: $grey-dark-2;

      font-weight: 600;
    }
  }
}
