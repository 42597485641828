@import "../../../scss/index.scss";

.filters {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 1rem;
  column-gap: 1.5rem;
  margin-bottom: 2rem;
  // z-index: 1001;

  .locationSearch {
    grid-row: 1/2;
    grid-column: 1/5;
  }

  .categoryFilter {
    grid-row: 1/2;
    grid-column: 5/8;
  }

  .priceFilter {
    grid-row: 1/2;
    grid-column: 8/11;
  }

  .statusFilter {
    grid-row: 2/3;
    grid-column: 1/5;
  }

  .dateFilter {
    grid-row: 2/3;
    grid-column: 5/7;
  }

  .bedsFilter {
    grid-row: 1/2;
    grid-column: 11/-1;
  }

  .typeFilter {
    grid-row: 2/3;
    grid-column: 7/11;
  }

  .moreFilters {
    grid-row: 2/3;
    grid-column: 11/-1;
  }
}

.hide {
  display: none;
}
