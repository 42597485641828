@import "../../scss/index.scss";
@import "../../scss/variables.scss";

.favouriteheader {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: $grey-dark-2;
  padding: 2rem 5rem;
  height: 10rem;

  .add {
    display: flex;
    align-items: center;
    cursor: pointer;

    .addIcon {
      display: flex;
      align-items: center;
      font-size: 2rem;
      margin-right: 1rem;
    }

    .label {
      display: flex;
      align-items: center;
    }
    .disabled {
      color: $grey-dark-1;
      cursor: not-allowed;
    }
  }

  .sort {
    display: flex;
  }
}
