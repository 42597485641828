@import "../../../../../scss/app.scss";

.filter {
  width: 100%;
  border-bottom: 1px solid $border-color;
}

.item {
  display: flex;
  flex-direction: column;
  //border-left: 3px solid transparent;
  .label {
    display: flex;
    align-items: center;
    color: $grey-dark-2;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .tag {
    display: flex;
    align-items: center;
    color: $purple-medium-1;
    font-weight: 400;
    font-size: 1.5rem;
    justify-content: flex-end;
  }
}

.maxWidth {
  max-width: 12rem;
}

.active {
  //border-left-color: $purple-medium-2;
}
