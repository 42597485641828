@import "../../../../../scss/app.scss";

.header {
  position: fixed;
  @include safe-top-margin;
  display: flex;
  justify-content: space-between;
  left: 0;
  top: 0;
  width: 100%;
  height: 3.5rem;
  background-color: transparent;
  z-index: 100;
  padding: 0 2rem;
  margin-top: 5rem;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 3.8rem;
    background-color: white;
    z-index: 1500;
    border-radius: $radius-sm;
    .icon {
      font-size: 2.5rem;
    }
  }
}
