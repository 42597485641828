@import "../../scss/index.scss";

.tag {
  font-size: inherit;
  border-radius: $radius-sm;
  padding: 2px 10px;
  display: flex;
  align-items: center;
}

.active {
  background-color: $green-light;
  color: $green-dark;
}

.inactive {
  background-color: $orange-light;
  color: $orange-dark;
}
