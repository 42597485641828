@import "../../../../../../../scss/index.scss";

.quickContact {
  margin-top: 2rem;

  .tag {
    margin: 0 1rem;
  }

  .contactEveryone {
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;

    .email {
      display: grid;
      grid-template-columns: auto;
      justify-items: center;
      background-color: $blue-light;
      color: $blue-dark;
      border: 1px solid $blue-dark;
      border-radius: 15px;
      font-size: 1.5rem;
      padding: 1rem;

      ion-icon {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .sms {
      display: grid;
      grid-template-columns: auto;
      justify-items: center;
      background-color: $green-light;
      color: $green-dark;
      border: 1px solid $green-dark;
      border-radius: 15px;
      font-size: 1.5rem;
      padding: 1rem;

      ion-icon {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
  .contact {
    border-top: 0.1rem solid $purple-light-2;
    padding: 1rem 2rem;
    margin-top: 1rem;

    .name {
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;
    }

    .role {
      color: $grey-dark-1;
      font-size: 1.2rem;
      margin-left: 0.5rem;
    }

    .phone {
      font-size: 1.2rem;
      color: $grey-dark-1;
    }

    .email {
      font-size: 1.2rem;
      color: $grey-dark-1;
    }

    .icons {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(3, auto);
      column-gap: 1rem;

      .mailIcon {
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue-light;
        color: $blue-dark;
        border: 0.1rem solid $blue-dark;
        border-radius: 15px;

        ion-icon {
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .smsIcon {
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $green-light;
        color: $green-dark;
        border: 0.1rem solid $green-dark;
        border-radius: 15px;

        ion-icon {
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .callIcon {
        height: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $purple-light-2;
        color: $purple-medium-2;
        border: 0.1rem solid $purple-medium-2;
        border-radius: 15px;

        ion-icon {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
  }
}

.disabled {
  background-color: $grey-light-1 !important;
  border-color: $grey-medium-1 !important;
  color: $grey-medium-1 !important;
  ion-icon {
    color: $grey-medium-1;
  }
}
