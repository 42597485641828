@import "../../../scss/app.scss";

.agentTourDetails {
  .appHeader {
    position: sticky;
    top: 0;

    .toolbar {
      --background: #f4f4f7;

      .title {
        display: flex;
        justify-content: center;
        font-size: 2.2rem;
        color: $grey-dark-2;
        font-weight: 600;
      }

      .btn {
        --color: #707070;
        --icon-font-size: 1.8rem;
        --icon-padding-end: 0.8rem;
        font-size: 1.5rem;
        margin-left: 1rem;
        text-transform: initial;

        .label {
          font-size: 1.5rem;
        }
      }
    }
  }

  .details {
    margin-top: 2rem;

    .address {
      margin: 0 2rem;
      font-size: 2.2rem;
      font-weight: 500;
      color: $grey-dark-2;

      .postalCode {
        color: $grey-dark-1;
        font-size: 1.5rem;
        font-weight: 400;
      }
    }

    .directionBtn {
      margin: 1rem 2rem;
      --border-color: #007b94;
      --border-width: 0.1rem;
      --border-style: solid;
      --background: #e0faff;
      --background-activated: #007b94;
      --color: #007b94;
      --color-activated: #e0faff;
      display: flex;
      align-items: center;

      ion-icon {
        margin-right: 0.5rem;
      }
    }

    .showingDetails {
      .label {
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem 1rem;
        margin-bottom: 1rem;
        border-bottom: 0.1rem solid $grey-light-2;
      }

      .item {
        font-size: 1.5rem;

        ion-input {
          display: flex;
          text-align: end;
          color: $grey-dark-2;
        }

        ion-label {
          display: flex !important;
          flex-direction: row;
          align-items: center;
          color: $grey-dark-2 !important;

          ion-icon {
            display: flex;
            align-self: center;
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
          }
        }

        ion-select {
          color: $grey-dark-2 !important;
          font-weight: 600;
        }

        .timeBtn {
          display: flex;
          justify-content: flex-end;
          color: $grey-dark-2;
          font-weight: 600;

          ion-icon {
            margin-left: 1rem;
            height: 1.2rem;
            width: 1.2rem;
            color: $grey-medium-1;
          }
        }
      }

      .entryInfo {
        .lable {
          white-space: pre-line;
          margin-right: 1rem;
        }

        .info {
          display: grid !important;
          color: $grey-dark-2;
          font-weight: 600;
          text-align: end;
        }

        .icon {
          color: $grey-dark-2;
          font-size: 2rem;
          font-weight: 300;
        }
      }
    }

    .notes {
      margin-top: 2rem;

      .label {
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem;
      }

      .textarea {
        margin: 2rem 2rem 1rem;
        border: 1px solid $grey-light-2;
        width: 90%;
        height: 12rem;
        padding: 1rem 1.5rem;
        font-size: 1.5rem;
        resize: none;
      }

      .saveBtn {
        font-size: 1.5rem;
        height: 5rem;
        --border-style: solid;
        --border-width: 0.1rem;
        --border-color: #1f7a60;
        --background: #dff7f0;
        --background-activated: #1f7a60;
        --color: #1f7a60;
        --color-activated: #dff7f0;
      }
    }

    .mediaElements {
      margin-top: 3rem;

      .label {
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem 1rem;
        margin-bottom: 2rem;
        border-bottom: 0.1rem solid $grey-light-2;
      }

      .elements {
        margin: 0 1rem;
      }
    }

    .overview {
      margin-top: 2rem;

      .label {
        font-size: 1.8rem;
        color: $grey-dark-2;
        font-weight: 600;
        padding: 0 2rem 1rem;
        border-bottom: 0.1rem solid $grey-light-2;
      }

      .item {
        border-bottom: 0.1rem solid $grey-light-2;
        margin: 0 1rem;
        padding: 1rem 0;
        font-size: 1.5rem;
        color: $grey-dark-2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bold {
          display: flex;
          font-weight: 600;

          ion-icon {
            display: flex;
            align-self: center;
            margin-left: 0.5rem;
            height: 2rem;
            width: 2rem;
          }
        }
        .underline {
          text-decoration: underline;
        }
      }
    }

    .navigation {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
      margin-bottom: 1.5rem;
      color: $grey-dark-1;
      border-bottom: 0.1rem solid $grey-light-2;
      font-size: 1.5rem;

      .link {
        color: $grey-dark-2;
        flex: 1;
      }

      .previous {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;
        }
        ion-icon {
          display: flex;
          align-self: center;
          margin-right: 0.5rem;
          font-size: 2.2rem;
        }
      }

      .next {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          display: flex;
          align-items: center;
          color: $grey-dark-2;
        }
        ion-icon {
          color: $grey-dark-2;
          display: flex;
          align-self: center;
          margin-left: 0.5rem;
          width: 2.2rem;
          height: 2.2rem;
        }
      }
    }

    .removeBtn {
      --border-style: solid;
      --border-width: 0.1rem;
      --border-color: #c24100;
      --color: #c24100;
      --color-activated: #ffeee5;
      --background: #ffeee5;
      --background-activated: #c24100;
    }
  }
}

.alertInput {
  height: 8rem;
  width: 22rem !important;
}

.loading {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.picker {
  font-weight: 600;
}

ion-datetime::part(text) {
  color: $grey-dark-2 !important;
}

ion-select::part(icon) {
  display: none !important;
}

ion-item::part(detail-icon) {
  color: $grey-medium-1;
  font-size: 2.2rem;
  font-weight: 300;
}

.cancel {
  color: $purple-medium-2 !important;
  font-weight: 600 !important;
}

.remove {
  color: $orange-medium !important;
}
