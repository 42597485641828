@import "../../scss/index.scss";

.singleListing {
  position: absolute;
  width: 99.75%;
  margin-left: 0.125%;
  bottom: 0;
  height: 33rem;
  display: flex;
  z-index: 2500;

  .blur {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: #f9f9f979;
    opacity: 0;
  }

  .singleHeader {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    color: $grey-dark-2;
    font-weight: 600;
    font-size: 1.5rem;
    z-index: 2800;
    padding-left: 1rem;
  }

  .card {
    flex: 1;
    background-color: #f9f9f9;
    box-shadow: $box-shadow-grey;
    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
    display: flex;
    flex-direction: column;
    width: 100%;

    .handle {
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .bar {
        height: 4px;
        width: 6rem;
        background-color: $grey-light-2;
        border-radius: $radius-lg;
        &:hover {
          cursor: grab;
        }

        &:active {
          cursor: grabbing;
        }
      }

      .chevron {
        margin-top: 0.5rem;
        height: 2rem;
        width: 4rem;
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      height: 30rem;
      overflow: scroll;
      padding: 0 1rem;
      .item {
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .item:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.wrapper {
  position: absolute;
  bottom: 0;
  width: 99.75%;
  margin: 0 0.125%;
  height: 35rem;
  display: flex;
  z-index: 2500;

  .header {
    height: 3rem;
    display: flex;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 3px 7px -6px black;
    z-index: 50;

    .title {
      color: $grey-dark-2;
      font-weight: 600;
      font-size: 1.5rem;
      padding-left: 1rem;
    }

    .sortContainer {
      display: flex;
      align-items: center;
      --background: #f9f9f9;

      .title {
        color: $grey-dark-1;
        font-size: 1.5rem;
        margin-right: 0.5rem;
        font-weight: 400;
      }
      .value {
        color: $grey-dark-2;
        font-weight: 600;
      }
      .select {
        opacity: 0;
        width: 0;
      }

      .icon {
        font-size: 2.2rem;
        color: $grey-dark-2;
        z-index: 100;
        margin-left: 0.5rem;
      }
    }
  }

  .count {
    padding: 0 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: $grey-dark-1;
  }

  .card {
    flex: 1;
    background-color: #f9f9f9;
    box-shadow: $box-shadow-grey;
    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;
    display: flex;
    flex-direction: column;
    width: 100%;

    .handle {
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .bar {
        height: 4px;
        width: 6rem;
        background-color: $grey-light-2;
        border-radius: $radius-lg;
        &:hover {
          cursor: grab;
        }

        &:active {
          cursor: grabbing;
        }
      }
    }

    .content {
      display: grid;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 1rem;
      margin: 0;

      .item {
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
      }

      .item:last-of-type {
        padding-bottom: 1.5rem;
      }
    }
  }
}

.fullscreen {
  top: 0% !important;
  height: 100% !important;

  .card {
    border-radius: 0;
  }
}

.allListings {
  top: 14rem;
  height: 100%;
}

.allListingsContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 1rem;

  .item {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .item:last-of-type {
    margin-bottom: 14rem;
  }
}

.animation {
  animation: animateElement 0.25s forwards;
}

.animationCollapse {
  animation: animateElement2 0.5s forwards;
}

@keyframes animateElement {
  0% {
  }

  100% {
    top: 100%;
  }
}

@keyframes animateElement2 {
  0% {
  }

  100% {
    top: 50%;
  }
}
