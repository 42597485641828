@import "../../../../scss/index.scss";

.noImage {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: $grey-light-1;
  color: $grey-dark-1;
  font-size: 1.5rem;
  border: 1px $grey-light-2;
  border-style: dashed;
  border-radius: 10px;
  height: 12rem;
  width: 12rem;

  span {
    height: max-content;
    padding: 0 2rem;
    display: flex;
    align-self: center;
    text-align: center;
  }
}
