@import "../../../../../scss/app.scss";

.images {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  background-color: $primary-bg-color;

  .spinner {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    --color: #7158ad;
    transform: scale(2);
  }

  .list {
    padding: 0;
    background-color: $primary-bg-color;
    width: 100%;
    margin-bottom: 10rem;

    & .end {
      font-size: 1.2rem;
      color: $purple-medium-2;
      font-weight: 600;
      text-align: center;
      display: flex;
      justify-content: center;
      background-color: inherit;
      padding: 1.5rem 0;
    }

    .img {
      visibility: hidden;
      animation: popIn 0.2s calc(var(--animation-order) * 2ms) both ease-in;
    }
    .img:not(:last-of-type) {
      margin-bottom: 3px;
    }

    // .img:last-of-type {
    //   -webkit-mask-image: -webkit-gradient(
    //     linear,
    //     left top,
    //     left bottom,
    //     from(rgba(0, 0, 0, 10)),
    //     to(rgba(0, 0, 0, 0))
    //   );
    //   mask-image: linear-gradient(
    //     to bottom,
    //     rgba(0, 0, 0, 10),
    //     rgba(0, 0, 0, 0)
    //   );
    // }

    .defaultContainer {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: $primary-bg-color;
      .default {
        margin-top: 50%;
      }
    }
  }
}

.modal {
  height: 100%;
  --backdrop-opacity: 0 !important;

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .counter {
      position: absolute;
      top: 1rem;
      left: 0;
      width: 100%;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 500;
    }

    .slides {
      height: 100%;
    }

    .closeContainer {
      position: absolute;
      bottom: 5rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;

      .btn {
        color: $purple-light-2;
        --border-color: #e9e3f2;
        --border-width: 1px;
        --opacity: 0.9;
        font-weight: 300;
        --background-activated: #927bca;
      }

      .landscapeBtn {
        color: $purple-dark-1;
        --border-color: #33244c;
        --border-width: 2px;
        --opacity: 0.9;
        font-weight: 500;
        --background: #e9e3f2a1;
        --background-activated: #e9e3f2;
      }
    }
  }
}

.purpleBackground {
  background-color: $primary-bg-color;
}

@keyframes popIn {
  0% {
    opacity: 0;
    // transform: scale(0.6) translateY(-8px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
