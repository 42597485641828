@import "../../../../../../scss/index.scss";

.tourDetails {
  div {
    border-bottom-style: solid;
    border-bottom-color: $purple-light-2;
    border-bottom-width: 0.1rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
    padding: 1rem 0;

    span {
      display: flex;
      flex-direction: column;
    }
  }
  .time {
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 2rem;

    span {
      display: flex;
      flex-direction: column;
    }
  }

  .address {
    display: flex;
    flex-direction: row;
    text-decoration: underline;
    cursor: pointer;

    ion-icon {
      width: 2rem;
      height: 2rem;
      display: flex;
      margin-left: 0.5rem;
      align-self: center;
    }
  }
  .bold {
    color: $grey-dark-2;
    font-weight: 500;
  }
}

.button {
  --background: #e9e3f2;
  --border-color: #7158ad;
  --color: #7158ad;
  --border-style: solid;
  --border-width: 0.1rem;
  --box-shadow: 0;
  height: 4.5rem;
  text-transform: none;
  --border-radius: 10px;
  font-size: 1.5rem;
  width: 100%;

  ion-icon {
    margin-right: 0.5rem;
  }
}
.directionsBtn {
  --background: #e0faff;
  --border-color: #007b94;
  --color: #007b94;

  ion-icon {
    margin-right: 0.5rem;
  }
  margin-top: 2rem;
}
