@import "../../../scss/app.scss";

.content {
  --background: none;
  background-color: #f4f4f7;
}
.container {
  padding: 0rem 1.5rem;
  margin: 0;
  background-color: $primary-bg-color;
}
