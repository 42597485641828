@import "../../../scss/index.scss";

.selectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  flex: 1;
  width: 100%;

  .select {
    @include input-placeholder;
    cursor: pointer;
    width: 100%;

    &:disabled {
      color: $grey-light-2;
      cursor: default;
    }
  }

  .label {
    @include input-label;
    z-index: 15;
  }

  .disabledLabel {
    color: #7158ad85;
  }

  .title {
    padding-right: 1.5rem;
  }
  .subtitle {
    font-size: 1.5rem;
    font-weight: 400;
    color: $grey-dark-1;
    text-transform: lowercase;
    margin-left: 0.2rem;
  }

  .invertedLabel {
    font-weight: 400;
  }

  .icon {
    @include center-vertically;
    right: 1.35rem;
    color: $input-icon-color;
    --ionicon-stroke-width: 4.8rem;
    cursor: pointer;
    transition: all 0.2s;
  }

  .disabledIcon {
    color: $grey-light-2;
  }

  .invertedIcon {
    color: white;
  }

  .iconReversed {
    transform: rotate(180deg) translateY(50%);
  }

  .removeBorder {
    border-bottom: 0;
  }

  .inverted {
    background-color: transparent;
    color: white;
    border-color: $purple-medium-1;
    font-weight: 400;
    font-size: 1.4rem;
  }

  .embedded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media screen and (max-width: $small) {
  .inverted,
  .subtitle {
    font-size: 1.2rem;
  }
}
