@import "../../../../../scss/index.scss";

.questions {
  margin: 2rem 1.5rem;
  color: $grey-dark-2;

  .title {
    margin-bottom: 1rem;
    font-size: 2.6rem;
    font-weight: 600;
    color: $grey-dark-2;
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .row {
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;

    .question {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: underline;
      color: $grey-dark-2;
    }
  }

  .lastRow {
    border-bottom: none;
    margin-bottom: 0;
  }
}
