@import "../../../scss/app.scss";

.header {
  position: sticky;
  top: 0;
  .toolbar {
    --background: #f4f4f7;
    .btn {
      --color: #707070;
      font-size: 1.5rem;
    }

    .title {
      font-size: 2.2rem;
      font-weight: 600;
      color: $grey-dark-2;
    }
  }
}
