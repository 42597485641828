@import "../../../../scss/index.scss";

.recording {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $grey-dark-1;
  height: 5rem;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
  border-bottom-color: $purple-light-2;
  padding: 1rem 0.5rem;

  @media only screen and (max-width: $small) {
    padding: 1rem 2rem;
  }
  .deleteIcon {
    display: flex;
    align-self: center;
    justify-self: center;
    color: white;
    background-color: $orange-medium;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .playIcon {
    display: flex;
    align-self: center;
    justify-self: center;
    background-color: $grey-light-1;
    color: $grey-dark-1;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }

  .pauseIcon {
    display: flex;
    align-self: center;
    justify-self: center;
    background-color: $purple-medium-2;
    color: $purple-light-2;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }

  .name {
    flex: 1;
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .playingName {
    color: $purple-medium-2;
    font-weight: 500;
  }
  .duration {
    justify-self: flex-end;
    width: 5rem;
    text-align: center;
    font-size: 1.5rem;
  }

  .playingDuration {
    color: $purple-medium-2;
    font-weight: 500;
  }
}

.purpleBackground {
  background-color: $purple-light-2;
}

.player {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12rem;
  z-index: 100;
  --background: #7158ad;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  transition: 0.5s;

  ion-item {
    --background: #7158ad;
  }

  .title {
    display: flex;
    align-items: baseline;

    h2 {
      color: $purple-light-1;
    }
    .time {
      font-size: 1.2rem;
      color: $purple-light-1;
      margin-left: 1rem;
    }
  }

  p {
    color: $purple-light-1;
    font-weight: 300;
  }

  .icon {
    color: $purple-light-1;
    font-size: 2rem;
    cursor: pointer;
  }

  .rangeContainer {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    display: flex;
    align-items: center;

    ion-range {
      z-index: 500;
      --knob-size: 0.8rem;
      --height: 2rem;
      --bar-height: 0.5rem;
      margin-top: -3rem;
      --knob-background: #ffeee5;
      --knob-border-radius: 2px;
      --bar-background-active: #ff5500;
      --padding: 0;
    }

    ion-label {
      font-size: 1.2rem;
      color: $purple-light-1;
    }
  }
}

@media screen and (min-width: 960px) {
  .player {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}
