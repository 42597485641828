@import "../../scss/index.scss";

.doughnut {
  .container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      font-size: 1.8rem;
      color: $grey-dark-1;
      text-align: center;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }
    }

    .chartContainer {
      position: relative;
      display: flex;
      align-items: center;
      padding: 1.5rem;
      margin: 0;

      .centerLabel {
        position: absolute;
        @include center-horizontally;
        font-size: 2.4rem;
        color: $purple-medium-2;
        font-weight: 500;
      }
    }
  }
}

.line {
  .container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      font-size: 1.8rem;
      color: $grey-dark-1;
      text-align: center;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }
    }

    .chartContainer {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  }
}

.bar {
  .container {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      font-size: 1.8rem;
      color: $grey-dark-1;
      text-align: center;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }
    }

    .chartContainer {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  }
}

.scatter {
  .container {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      font-size: 1.8rem;
      color: $grey-dark-1;
      text-align: center;

      @media only screen and (max-width: $medium) {
        font-size: 1.5rem;
      }
    }

    .chartContainer {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  }
}
