@import "../../../../scss/index.scss";

.welcome {
  background-color: white;

  .title {
    display: grid;
    margin: 2rem 0 0 2rem;
    color: $grey-dark-1;
    font-size: 2.2rem;

    span {
      color: $grey-dark-2;
      font-weight: 500;
    }
  }

  .subtitle {
    margin: 1rem 0 2rem 2rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
  }

  .line {
    margin: 1rem 2rem;
    border-bottom-color: $grey-light-2;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  .infos {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    margin-right: 1rem;

    .icon {
      display: flex;
      align-self: center;

      ion-icon {
        color: $purple-medium-2;
        width: 3.5rem;
        height: 3.5rem;
        margin: 0 1rem 0 3rem;
      }
    }

    .content {
      align-self: center;
      margin-right: 2rem;
      color: $grey-dark-2;
      font-size: 1.5rem;

      a {
        color: $grey-dark-2;
        text-decoration: underline;
        margin: 0 0.5rem;
      }
    }
  }

  .button {
    --background: #e9e3f2;
    --background-activated: #e9e3f2;
    --border-color: #7158ad;
    --border-width: 1px;
    --border-style: solid;
    --box-shadow: 0;
    --color: #7158ad;
    text-transform: initial;
    margin: 2rem auto;
    width: 100%;
    height: 5rem;
  }
}
