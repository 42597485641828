@import "../../scss/index.scss";

.error {
  grid-row: 1/-1;
  grid-column: 2/-1;
  @include hide-scrollbar;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 10rem 1fr;
  z-index: 10;
  background-color: $primary-bg-color;
  overflow-y: scroll;

  .header {
    grid-row: 1/2;
    grid-column: 1/-1;
    border-bottom: 1px solid $line-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    font-size: 1.5rem;
    color: $grey-dark-1;

    .btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        margin-right: 1rem;
        font-size: 1.8rem;
      }
    }

    .profile {
      display: flex;
      .btns {
        display: flex;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey-dark-2;
    margin-bottom: 4rem;

    .svg {
      width: 42rem;
      height: 42rem;
    }
    .title {
      font-size: 4rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .subtitle {
      font-size: 1.8rem;
      font-weight: 600;
      &:last-of-type {
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
    }

    .links {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: $purple-medium-2;

      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.appError {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $primary-bg-color;

  .header {
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $grey-dark-2;
    background-color: white;

    .svg {
      width: 30rem;
      height: 30rem;
    }
    .title {
      font-size: 2.2rem;
      color: $grey-dark-2;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .subtitle {
      font-size: 1.5rem;
      text-align: center;
      color: $grey-dark-1;
      padding: 0 1rem;
      &:last-of-type {
        margin-bottom: 2rem;
        margin-top: 1rem;
      }
    }

    .links {
      margin-top: 3rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: $purple-medium-2;

      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
