@import "../../../../../../../scss/index.scss";

.tourDetails {
  margin: 2rem 1rem;

  ion-row {
    margin-bottom: 1rem;
  }

  .item {
    border: $input-border;
    border-radius: $radius-sm;
    --inner-padding-top: 0;
    --padding-top: 0;
    font-size: 1.5rem;

    ion-label {
      --color: #707070;
    }
  }

  .picker {
    color: $grey-dark-1;
  }

  .textarea {
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 20rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .button {
    height: 5rem;
    --background: #dff7f0;
    --background-activated: #1f7a60;
    --color: #1f7a60;
    --color-activated: #dff7f0;
    --border-color: #1f7a60;
    --border-style: solid;
    --border-width: 1px;
    --box-shadow: 0;
    text-transform: initial;
  }

  .note {
    color: $grey-dark-1;
    font-size: 1.2rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
