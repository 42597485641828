@import "../../../../scss/index.scss";

.showingDetails {
  margin: 2rem;

  .title {
    font-size: 2.6rem;
    color: $grey-dark-2;
    font-weight: 600;
  }

  .address {
    margin: 1rem 0 2rem;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    color: $grey-dark-1;

    .bold {
      font-weight: 600;
    }
  }

  .container {
    position: relative;
    margin-bottom: 2rem;
  }

  .inputContainer {
    position: relative;
    margin-top: 2rem;
  }

  .times {
    display: grid;
    grid-template-columns: 45% 10% 45%;
    margin: 2rem 0;
    width: 100%;

    .container {
      position: relative;
      margin-bottom: 0;
    }
    .hyphen {
      display: flex;
      justify-content: center;
      align-self: center;
    }
  }

  .statusDropdown {
    div {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;

      &:hover {
        background-color: $purple-light-2;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .range {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;

    &:hover {
      background-color: $purple-light-2;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .textareaLabel {
    font-size: 1.2rem;
    position: relative;
    top: 1rem;
    left: 1rem;
    color: $purple-medium-2;
  }
  .textarea {
    margin: 0 0 2rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 14rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
    color: $grey-dark-1;
  }
}
