@import "../../../scss/index.scss";

.container {
  display: grid;

  .icon {
    display: grid;
    justify-content: center;

    ion-icon {
      height: 6rem;
      width: 6rem;
      color: $purple-medium-2;
      background-color: $purple-light-2;
      border-radius: 50%;
      padding: 0.5rem;
      font-weight: 300;
    }
  }

  .title {
    display: grid;
    justify-content: center;
    color: $grey-dark-2;
    font-size: 2.6rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .info {
    justify-content: center;
    margin-bottom: 4rem;
    width: 50rem;
    color: $grey-dark-1;
    font-size: 1.5rem;
    text-align: center;

    span {
      text-decoration: underline;
    }
  }

  .btn {
    display: grid;
    justify-content: center;
  }

  .subtitle {
    margin: 1.6rem auto 0;
    color: $grey-dark-1;
    font-size: 1.2rem;

    span {
      text-decoration: underline;
    }
  }
}
