@import "../../../scss/index.scss";

.marketdata {
  margin: 2rem;

  .image {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 2rem;

    .img {
      width: 25rem;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    font-size: 2.2rem;
    font-weight: 600;
    color: $grey-dark-2;
  }

  .subtitle {
    margin: 1rem auto;
    font-size: 1.5rem;
    color: $grey-dark-1;
    text-align: center;
  }

  .btn {
    display: flex;
    margin: 1rem auto;
    width: 22rem;
    height: 5rem;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    --background: #e9e3f2;
    --background-activated: #7158ad;
    --border-color: #7158ad;
    --border-width: 0.1rem;
    --border-style: solid;
    --box-shadow: 0;
    text-transform: initial;
  }
}
