@import "../../../../scss/index.scss";

.overview {
  .title {
    font-size: 2.6rem;
    color: $grey-dark-2;
    font-weight: 600;
    margin: 2rem;
  }

  .details {
    margin: 0 2rem;

    .detail {
      border-top-color: $purple-light-2;
      border-top-width: 0.1rem;
      border-top-style: solid;
      margin-top: 1rem;
      padding-top: 1rem;
      display: flex;
      justify-content: space-between;
      color: $grey-dark-2;
      font-size: 1.5rem;

      .bold {
        display: flex;
        font-weight: 600;

        .icon {
          display: flex;
          align-self: center;
          margin-left: 0.5rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      .underline {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .lastDetail {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom-color: $purple-light-2;
      border-bottom-width: 0.1rem;
      border-bottom-style: solid;
    }
  }
}
