@import "./../../../../../../../scss/app.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0rem;

  .topBtns {
    display: flex;
    justify-content: space-evenly;

    .btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $grey-light-2;
      border-radius: $radius-md;
      padding: 1rem;
      background-color: $grey-light-1;

      flex: 1;

      &:not(:last-of-type) {
        margin-right: 2rem;
      }

      .icon {
        font-size: 3rem;
        color: $grey-dark-2;
      }

      .label {
        margin-top: 0.5rem;
        font-size: 1.2rem;
        color: $grey-dark-2;
      }
    }

    .disabled {
      pointer-events: none;
      cursor: not-allowed;
      .icon {
        color: $grey-light-2;
      }
      .label {
        color: $grey-light-2;
      }
    }
    .disabledIcon {
      font-size: 3rem;
      opacity: 0.2;
    }
    .disabledLabel {
      color: $grey-light-2;
      font-size: 1.2rem;
    }
  }

  .description {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    .title {
      color: $grey-dark-2;
      font-size: 2.2rem;
      font-weight: 600;
    }
    .row {
      border-bottom: 1px solid $grey-light-2;
      padding: 1.25rem 0;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: $grey-dark-2;
    }

    .tag {
      margin-top: 0.5rem;
      border-top: 1px solid $grey-light-2;
      display: flex;
      flex: 1;
      font-size: 1.5rem;
      text-transform: capitalize;

      .active {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $green-light;
        color: $green-dark;
        border-radius: $radius-sm;
        height: 3rem;
      }

      .inactive {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-color: $orange-light;
        color: $orange-dark;
        border-radius: $radius-sm;
        height: 3rem;
      }
    }

    .dates {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      color: $grey-dark-2;

      .lastChange {
        // &:after {
        //   content: "|";
        //   padding: 0 1rem;
        //   color: $grey-light-2;
        // }
      }

      .date {
        border-left: 1.5px $grey-light-2 solid;
        padding-left: 1rem;
        margin-left: 1rem;
      }
      .onMarket {
        flex: 1;
        font-size: 1.5rem;
        font-weight: 500;
      }
    }

    .info {
      .icon {
        margin-right: 1rem;
        width: 1.3rem;
        height: 1.3rem;
        color: $grey-dark-2;
      }
    }

    .detail {
      display: flex;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 1rem;
          width: 2.5rem;
          height: 2.5rem;
          color: $grey-dark-2;
        }

        &:first-of-type {
          width: 50%;
        }
      }
    }
  }

  .dropdowns {
    margin-bottom: 4rem;
  }

  .noData {
    font-size: 1.5rem;
  }

  .listingDiscription {
    font-size: 1.5rem;
    color: $grey-dark-2;
  }
}

.roomdetails {
  display: flex;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: $grey-light-2;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  color: #666666;
  font-size: 1.5rem;

  .bold {
    font-weight: 600;
  }
}

.priceDetails {
  display: flex;
  flex-direction: column;
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: $grey-light-2;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  color: #666666;
  font-size: 1.5rem;

  .bold {
    font-weight: 600;
  }
}

.offer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.5rem;

  & > * {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.comparables {
  display: flex;

  flex-direction: column;
}
