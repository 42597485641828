@import "../../scss/index.scss";

.clientTourDetails {
  display: grid;
  grid-column: 2/-1;
  grid-row: 2/-1;
  grid-template-rows: min-content 1fr;
  overflow-y: scroll;
  @include show-scrollbar;
  height: 100%;

  .nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 4rem;
    padding-top: 2rem;
    font-size: 1.5rem;
    color: $grey-dark-1;
    width: 100%;

    span {
      cursor: pointer;
    }

    .icon {
      display: flex;
      align-items: center;
      margin: 0 0.5rem;
      cursor: default;
    }

    .bold {
      color: $grey-dark-2;
      font-weight: 600;
    }
  }

  .details {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
}

.loading {
  display: flex;
  align-items: center;
  margin-top: 8rem;
}
