@import "../../../../../scss/app.scss";

.tourcard {
  margin: 1rem;

  .row {
    border-bottom: 0.1rem solid $grey-light-2;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .firstRow {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 1rem;

    .index {
      padding-right: 1rem;
      height: max-content;
      display: flex;
      align-self: center;
    }

    .address {
      border-left: 0.1rem solid $grey-light-2;
      height: max-content;
      padding: 0 0 0 1.5rem;
      font-size: 1.8rem;
      color: $grey-dark-2;
      font-weight: 600;

      .postalCode {
        font-size: 1.5rem;
        color: $grey-dark-1;
        font-weight: 400;
      }
    }
  }

  .details {
    font-size: 1.5rem;
    color: $grey-dark-2;

    .bold {
      font-weight: 600;
      text-align: end;
    }
  }

  .detailsBtn {
    margin: 1rem 0;
    --border-color: #7158ad;
    --border-width: 0.1rem;
    --border-style: solid;
    --background: #e9e3f2;
    --background-activated: #7158ad;
    --color: #7158ad;
    --color-activated: #e9e3f2;
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 0.5rem;
    }
  }

  .directionBtn {
    margin: 1rem 0;
    --border-color: #007b94;
    --border-width: 0.1rem;
    --border-style: solid;
    --background: #e0faff;
    --background-activated: #007b94;
    --color: #007b94;
    --color-activated: #e0faff;
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 0.5rem;
    }
  }
}
