@import "../../../../scss/index.scss";

.inputs {
  margin: 4rem 2rem;

  .container {
    position: relative;
    margin-bottom: 2rem;
  }

  .textarea {
    margin: 0 0 2rem;
    border: 1px solid $grey-light-2;
    width: 100%;
    height: 24rem;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    resize: none;
  }

  .note {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    color: $grey-dark-1;
    font-size: 1.2rem;
  }

  .statusDropdown {
    div {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;

      &:hover {
        background-color: $purple-light-2;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .remove {
    display: flex;
    justify-content: center;
    margin: 2rem -2rem 0;
    padding-top: 2rem;
    border-top-style: solid;
    border-top-width: 0.1rem;
    border-top-color: $purple-light-2;

    .button {
      text-transform: initial;
      color: $orange-dark;
      font-size: 1.5rem;
      font-weight: 600;

      ion-icon {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }
}
